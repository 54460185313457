/* Feature - INspection List to View

Created by - Mona R
Updated by - Janaki  */

//importing dependencies and packages
import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faClose,
  faAngleRight,
  faTrash,
  faPenToSquare,
  faUserPlus,
  faX,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../components/loader/loader";
import ReactTooltip from "react-tooltip";
import InsAssign from "./insAssign";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useLocation } from 'react-router-dom';
import moment from "moment";

// function to create inspection assign feature
function InspectionList(props) {
  // Bulk upload feature to show in modal (Bulk upload feature of schedule).
  const [modalShow, setModalShow] = useState(false);
  const [key, setKey] = useState("asset");
  const [basedOn, setBasedon] = useState("");
  const location = useLocation();
  const accessOnDemand = useSelector((state) => state.access.onDemandInspection);
  const selectedSite = useSelector((state) => state.site.selectedSiteList);


  // console.log(key);

  const [ID, setID] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // to fetch data from redux
  const inspectionData = useSelector(
    (state) => state.inspection.inspectionData
  );
  // console.log(inspectionData);
  const isLoading = useSelector((state) => state.inspection.isLoading);
  const userid = useSelector((state) => state.auth.userid);

  const [searchInput, setSearchInput] = useState("");

  // Add state variables for current page and items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [stop, setStop] = useState(false);

  const role = useSelector((state) => state.auth.role);
  const accessIn = useSelector((state) => state.access.assetInspection);
  const accessAssign = useSelector(
    (state) => state.access.assetInspectionAssign
  );

  const handleDelete = (_id) => {
    dispatch({
      type: "INSPECTION_DELETE_LIST",
      // payload: _id,
      payload: [key, currentPage, itemsPerPage, searchInput, _id],
    });
  };

  // Calculate the total number of pages
  let totalPages = 0;
  var inspList = [];
  if (inspectionData.result !== undefined) {
    inspList = inspectionData.result;
  }
  if (inspectionData?.total_count !== undefined) {
    totalPages = Math.ceil(inspectionData.total_count / itemsPerPage);
  }

  // console.log(inspList);

  // Function to handle next button
  const handleNext = () => {
    setCurrentPage((currentPage) => currentPage + 1);
    // dispatch({
    //   type: "INSPECTION_GET_LIST",
    //   payload: [currentPage, itemsPerPage],
    // });
  };

  // Function to handle previous button
  const handlePrev = () => {
    setCurrentPage((currentPage) => currentPage - 1);
    // dispatch({
    //   type: "INSPECTION_GET_LIST",
    //   payload: [currentPage, itemsPerPage],
    // });
  };

  //Function to handle FirstPage Button
  const handleFirstPage = () => {
    setCurrentPage(1);
    // dispatch({
    //   type: "INSPECTION_GET_LIST",
    //   payload: [currentPage, itemsPerPage],
    // });
  };

  //Function to handle LastPage Button
  const handleLastpage = () => {
    setCurrentPage(totalPages);
    // dispatch({
    //   type: "INSPECTION_GET_LIST",
    //   payload: [currentPage, itemsPerPage],
    // });
  };

  // Function to handle items per page change
  const handleItemsPerPageChange = (items) => {
    setItemsPerPage(items);
    setCurrentPage(1);
  };

  // Function to handle search input change
  const handleSearchInputChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  //  Handle Tab change key
  const handleKey = (k) => {
    setKey(k)
    setCurrentPage(1);
  }

  //Function to handel clear
  const handleClearButton = () => {
    setSearchInput("");
  };


  // dispatch to stop the schedule
  const handleStop = (_id) => {
    // console.log('_id', _id);
    let inspectionAssignData = {
      // frequency: {
      //   frequency_start: "",
      // },
      // assignee: "",
      // schedule_status: "yet_to_be_assigned",
      modified_by: userid,
    };
    // console.log(inspectionAssignData);
    const data = [key, currentPage, itemsPerPage, searchInput]
    dispatch({
      type: "INSPECTION_STOP",
      payload: [inspectionAssignData, _id, data],
      // payload: _id,
    });
    setStop(true);
  };

  useEffect(() => {
    const roleCode = role[0];
    // console.log(roleCode);
    if ((accessIn === null || accessAssign === null) && roleCode !== null) {
      dispatch({
        type: "ACCESS_GET_LIST",
        payload: roleCode,
      });
    }
  }, [role, accessIn, accessAssign]);

  useEffect(() => {
    if (currentPage > 0 || stop === true || searchInput.length > 0) {
      dispatch({
        type: "INSPECTION_GET_LIST",
        payload: [key, currentPage, itemsPerPage, searchInput],
      });
    }
  }, [currentPage, itemsPerPage, key, searchInput,selectedSite]);

  // console.log(key);
  useEffect(() => {
    if (location.state !== undefined && location.state !== null) {
      // console.log(location.state.key);
      setKey(location.state.key)
    }
    if (location?.state?.key === undefined) {
      setKey('asset')
      setCurrentPage(1)
    }
  }, [location.state])

  useEffect(() => {
    // console.log(accessOnDemand)
  }, [accessOnDemand])

  return (
    <div>
      {isLoading && <Loader />} {/* to Show the Loading data */}
      <div className="pt-5">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => handleKey(k)}
          className="m-3 headings"
        >
          <Tab eventKey="asset" title="Asset"></Tab> &nbsp;
          <Tab eventKey="device" title="Device"></Tab> &nbsp;
          <Tab eventKey="facility" title="Facility"></Tab>
        </Tabs>
      </div>
      <div className="col-md-12">
        <div
          // className="listHeadings"
          style={{
            fontSize: "20px",
            paddingLeft: "100px",
            paddingTop: "5px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {key.charAt(0).toUpperCase() + key.slice(1)} - Inspection List
          <div style={{ float: "right" }}>
            <form
              className="mb-4"
            >
              <input
                type="text"
                placeholder="Search"
                value={searchInput}
                onChange={handleSearchInputChange}
                style={{
                  border: "1px solid steelblue",
                  fontSize: "15px",
                  padding: "4px",
                }}
              />
              <span
                type="reset"
                onClick={handleClearButton}
                style={{ paddingLeft: "8px", marginRight: "25px" }}
              >
                <FontAwesomeIcon icon={faClose} size="lg" className="pt-3" />
              </span>
            </form>
          </div>
          <div>Inspection Edit, Delete & Assign</div>
        </div>
        {/* Table to view inspection list */}
        <div className="mb-6">
          <table className="table-bordered" width="100%">
            <thead>
              {/* Table heading to display list of customers */}
              <tr
                style={{
                  textAlign: "center",
                  backgroundColor: "steelblue",
                  padding: "8px",
                  color: "#fff",
                }}
              >
                <th
                  style={{
                    padding: "8px",
                  }}
                >
                  S. No
                </th>
                <th
                  scope="col"
                  style={{ textAlign: "left", paddingLeft: "5px"  }}
                >
                  Site Name
                </th>
                <th scope="col">
                  {key.charAt(0).toUpperCase() + key.slice(1)} ID
                </th>
                <th
                  scope="col"
                  style={{ textAlign: "left", paddingLeft: "5px" }}
                >
                  Inspection Name
                </th>
                <th scope="col">Priority</th>
                <th scope="col">Status</th>
                {(accessOnDemand?.r === true) ?
                  (<th scope="col">Based on</th>) : null}
                <th scope="col" >Assigned</th>
                <th scope="col" >Approver</th>
                <th scope="col">Start Date & Time</th>
                <th
                  scope="col"
                  colSpan="4"
                  className="actionColumn"
                  style={{ textAlign: "center" }}
                >
                  Action
                </th>
              </tr>
            </thead>
            {/* inspection details are mapped to read */}
            {inspList !== null && inspList.length > 0 ? (
              inspList.map((eachData, index) => {
                // console.log(eachData);
                return (
                  <tbody key={eachData._id} style={{ padding: "15px" }}>
                    <tr style={{ padding: "20px" }}>
                      <td style={{ textAlign: "center", padding: "8px" }}>
                        {(currentPage - 1) * itemsPerPage + (index + 1)}
                      </td>
                      <td style={{ textAlign: "left",  paddingLeft: "5px", paddingRight: "5px" }}>
                        {eachData?.site_name?eachData?.site_name:'-'}
                      </td>
                      <td style={{ textAlign: "center", paddingLeft: "5px", paddingRight: "5px" }}>
                        {eachData.parent_id}
                      </td>
                      <td style={{ textAlign: "left", paddingLeft: "5px" }}>
                        {eachData.schedule_name}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {eachData.priority.charAt(0).toUpperCase() +
                          eachData.priority.slice(1)}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {(eachData.schedule_status && eachData.based_on === 'calendar')
                          ? `${eachData.schedule_status &&
                          eachData.schedule_status.charAt(0).toUpperCase() +
                          eachData.schedule_status
                            .slice(1)
                            .replace(/_/g, " ")
                          }`
                          : "-"}
                      </td>
                      {(accessOnDemand !== null && accessOnDemand.r === true) ? (
                        <td style={{ textAlign: "center" }}>
                          {eachData.based_on ? `${eachData.based_on
                            .replace(/_/g, " ")
                            .replace(/\b\w/g, (match) => match.toUpperCase())}` : "-"}
                        </td>
                      ) : null}
                      <td style={{ textAlign: "center", maxWidth: '20rem' }}>
                        {/* {console.log(eachData.assignee)} */}
                        {eachData.assignee ?
                          ((eachData.assignee).join(', '))
                          // (eachData.assignee)
                          : "-"}
                      </td>
                      <td style={{ textAlign: "center", maxWidth: '20rem' }}>
                        {/* {console.log(eachData.assignee)}
                        {eachData.approver ?
                          ((eachData.approver).join(', '))
                          // (eachData.assignee)
                          : "-"} */}
                        {eachData.is_auto_approve ?
                          'Auto Approve'
                          : eachData.approver ?
                            ((eachData.approver).join(', '))
                            // (eachData.assignee)
                            : "-"}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {eachData.frequency_start ? (
                          // <input
                          //   disabled
                          //   style={{
                          //     border: "none",
                          //     paddingLeft: "8px",
                          //   }}
                          //   type="text"
                          //   // defaultValue={(new Date(eachData.frequency_start)).toLocaleString()}
                          //   defaultValue={new Intl.DateTimeFormat("en-US", {
                          //     year: "numeric",
                          //     month: "short",
                          //     day: "numeric",
                          //     hour: "numeric",
                          //     minute: "numeric",
                          //     second: "numeric",
                          //     timeZone: "UTC",
                          //   }).format(new Date(eachData.frequency_start))}
                          // />
                          moment(eachData.frequency_start).local().format("MMMM Do YYYY | hh:mm A")
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        <button
                          onClick={() => {
                            navigate(`/inspection/view/${eachData._id}?type=${key}`);
                          }}
                          style={{
                            color: "#2D7DC1",
                            border: "none",
                            background: "none",
                            display: "block",
                            margin: "auto",
                            alignItems: "center",
                          }}
                          data-tip
                          data-for="viewTip"
                        >
                          <FontAwesomeIcon icon={faEye} size="lg" />
                          <ReactTooltip
                            id="viewTip"
                            place="bottom"
                            effect="solid"
                          >
                            View Inspection
                          </ReactTooltip>
                        </button>
                      </td>
                      {accessAssign !== null &&
                        accessAssign.u &&
                        accessIn !== null &&
                        accessIn.u ? (
                        <td style={{ textAlign: "center" }}>
                          {eachData.assignee ? (
                            <button
                              disabled
                              style={{
                                color: "#6fa8dc",
                                border: "none",
                                background: "none",
                              }}
                            >
                              <FontAwesomeIcon icon={faPenToSquare} size="lg" />
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                navigate(`/inspection/update/${eachData._id}?type=${key}`);
                              }}
                              style={{
                                color: "#2D7DC1",
                                border: "none",
                                background: "none",
                              }}
                              data-tip
                              data-for="toolTip"
                            >
                              <FontAwesomeIcon icon={faPenToSquare} size="lg" />
                              <ReactTooltip
                                id="toolTip"
                                place="bottom"
                                effect="solid"
                              >
                                Edit
                              </ReactTooltip>
                            </button>
                          )}
                        </td>
                      ) : null}
                      {accessAssign !== null && accessAssign.c ? (
                        <td style={{ textAlign: "center" }}>
                          {((eachData.based_on === 'calendar') || (eachData.based_on === 'on_demand')) ?
                            eachData.schedule_status === "yet_to_be_assigned" ? (
                              <>
                                <button
                                  onClick={() => {
                                    setModalShow(true);
                                    setID(eachData._id);
                                    setBasedon(eachData.based_on)
                                  }}
                                  style={{
                                    color: "#2D7DC1",
                                    border: "none",
                                    background: "none",
                                  }}
                                  data-tip
                                  data-for="assignTip1"
                                >
                                  <FontAwesomeIcon icon={faUserPlus} size="lg" />

                                </button>
                                <ReactTooltip
                                  className="solid"
                                  id="assignTip1"
                                  place="bottom"
                                  effect="solid"
                                >
                                  Assign User
                                </ReactTooltip></>
                            ) : (

                              <>
                                <button
                                  onClick={() => {
                                    window.confirm(
                                      "Are you sure you wish to stop this item?"
                                    ) && handleStop(eachData._id);
                                  }}
                                  style={{
                                    color: "#2D7DC1",
                                    border: "none",
                                    background: "none",
                                  }}
                                  data-tip
                                  data-for="assignTip2"
                                >
                                  <FontAwesomeIcon icon={faX} size="lg" />

                                </button>
                                <ReactTooltip
                                  className="solid"
                                  id="assignTip2"
                                  place="bottom"
                                  effect="solid"
                                >
                                  Stop
                                </ReactTooltip></>
                            )
                            : '-'}
                        </td>
                      ) : null}
                      {accessIn !== null && accessIn.d ? (
                        <td style={{ textAlign: "center" }}>
                          {eachData.assignee ? (
                            <button
                              disabled
                              style={{
                                color: "#6fa8dc",
                                border: "none",
                                background: "none",
                              }}
                              data-tip
                              data-for="deleteTip"
                            >
                              <FontAwesomeIcon icon={faTrash} size="lg" />
                              <ReactTooltip
                                id="deleteTip"
                                place="bottom"
                                effect="solid"
                              >
                                Delete
                              </ReactTooltip>
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                window.confirm(
                                  "Are you sure you want to delete this item?"
                                ) && handleDelete(eachData._id);
                              }}
                              style={{
                                color: "#2D7DC1",
                                border: "none",
                                background: "none",
                              }}
                              data-tip
                              data-for="deleteTip"
                            >
                              <FontAwesomeIcon icon={faTrash} size="lg" />
                              <ReactTooltip
                                id="deleteTip"
                                place="bottom"
                                effect="solid"
                              >
                                Delete
                              </ReactTooltip>
                            </button>
                          )}
                        </td>
                      ) : null}
                    </tr>
                  </tbody>
                );
              })
            ) : (
              <tbody>
                <tr style={{ textAlign: "center", height: "75px" }}>
                  <td colSpan="11">No Data Available</td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
        {/* pagination */}
        {inspList !== null && inspList.length > 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10px",
              padding: "10px",
            }}
          >
            <div className="mb-6">
              <label>Items per page:</label> &nbsp;
              <select
                onChange={(e) => handleItemsPerPageChange(e.target.value)}
                style={{ borderRadius: "8px", cursor: "pointer" }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={50}>50</option>
                {/* <option value={100}>100</option>
              <option value={200}>200</option>
              <option value={300}>300</option>
              <option value={400}>400</option>
              <option value={500}>500</option> */}
              </select>
            </div>

            {/* //pagination */}
            <div style={{ paddingRight: "25px" }} className="pagination">
              <button
                style={{
                  padding: "5px",
                  cursor: "pointer",
                  border: "none",
                  backgroundColor: "#fff",
                }}
                disabled={currentPage === 1}
                onClick={handleFirstPage}
              >
                {currentPage === 1 ? (
                  <FontAwesomeIcon icon={faAngleDoubleLeft} color="#ccc" />
                ) : (<FontAwesomeIcon icon={faAngleDoubleLeft} color="steelblue" />)}
              </button>
              <button
                style={{
                  padding: "5px",
                  cursor: "pointer",
                  border: "none",
                  backgroundColor: "#fff",
                }}
                disabled={currentPage === 1}
                onClick={handlePrev}
              >
                {/* Prev */}
                {currentPage === 1 ? (
                  <FontAwesomeIcon icon={faAngleLeft} color="#ccc" />
                ) : (<FontAwesomeIcon icon={faAngleLeft} color="steelblue" />)}
              </button>
              <span style={{ padding: "10px" }}>
                Showing {currentPage} of {totalPages} pages
              </span>
              {(totalPages === 0 || currentPage === totalPages) ? (
                <>
                  <button
                    style={{
                      padding: "5px",
                      cursor: "pointer",
                      backgroundColor: "#fff",
                      border: "none",
                    }}
                    disabled={true}
                  >
                    <FontAwesomeIcon icon={faAngleRight} color="#ccc" />
                  </button>
                  <button
                    style={{
                      padding: "5px",
                      cursor: "pointer",
                      backgroundColor: "#fff",
                      border: "none",
                    }}
                    disabled={true}
                  >
                    <FontAwesomeIcon icon={faAngleDoubleRight} color="#ccc" />
                  </button>
                </>
              ) : <>
                <button
                  style={{
                    padding: "5px",
                    cursor: "pointer",
                    backgroundColor: "#fff",
                    border: "none",
                  }}
                  disabled={currentPage === totalPages}
                  onClick={handleNext}
                >
                  <FontAwesomeIcon icon={faAngleRight} color="steelblue" />
                </button>
                <button
                  style={{
                    padding: "5px",
                    cursor: "pointer",
                    backgroundColor: "#fff",
                    border: "none",
                  }}
                  disabled={currentPage === totalPages}
                  onClick={handleLastpage}
                >
                  <FontAwesomeIcon icon={faAngleDoubleRight} color="steelblue" />
                </button>
              </>}

            </div>
          </div>
        ) : null}
      </div>
      {modalShow && (
        <InsAssign
          show={modalShow}
          onHide={() => setModalShow(false)}
          id={ID}
          pagenodata={[key, currentPage, itemsPerPage, searchInput]}
          basedon={basedOn}
        />
      )}
    </div>
  );
}

export default InspectionList;
