import {
  INSPECTION_GET_LIST,
  INSPECTION_GET_SUCCESS,
  INSPECTION_GET_FAILED,
  INSPECTION_DELETE_LIST,
  INSPECTION_DELETE_SUCCESS,
  INSPECTION_DELETE_FAILED,
  INSPECTION_ASSIGN_LIST,
  INSPECTION_ASSIGN_SUCCESS,
  INSPECTION_ASSIGN_FAILED,
  INSPECTION_ADD,
  INSPECTION_ADD_SUCCESS,
  INSPECTION_ADD_FAILED,
  INSPECTION_GET_SINGLE,
  INSPECTION_GET_SINGLE_SUCCESS,
  INSPECTION_GET_SINGLE_FAILED,
  INSPECTION_UPDATE,
  INSPECTION_UPDATE_SUCCESS,
  INSPECTION_UPDATE_FAILED,
  INSPECTION_REPORT,
  INSPECTION_REPORT_SUCCESS,
  INSPECTION_REPORT_FAILED,
  INSPECTION_STATUS,
  INSPECTION_GET_STATUS_SUCCESS,
  INSPECTION_GET_STATUS_FAILED,
} from "../actions/inspectionAction";

const inistialInspectionState = {
  inspectionData: { result: [] },
  assignData: [],
  addInspectionData: {},
  inspUpdateData: {},
  inspGetSingle: null,
  isLoading: false,
  isSubmitted: false,
  insReportData: null,
  inspectionStatus: {},
};

export default function InspectionReducer(
  state = inistialInspectionState,
  action
) {
  switch (action.type) {
    case INSPECTION_ADD:
      return {
        ...state,
        isLoading: true,
      };
    case INSPECTION_ADD_SUCCESS:
      return {
        ...state,
        addInspectionData: action.payload,
        isLoading: false,
        isSubmitted: true,
      };
    case INSPECTION_ADD_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case INSPECTION_GET_LIST:
      // console.log(state);
      return {
        ...state,
        isLoading: true,
      };
    case INSPECTION_GET_SUCCESS:
      // console.log(action.payload.data);
      return {
        ...state,
        inspectionData: action.payload.data,
        isLoading: false,
        isSubmitted: false,
      };
    case INSPECTION_GET_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case INSPECTION_DELETE_LIST:
      return {
        ...state,
      };
    case INSPECTION_DELETE_SUCCESS:
      return {
        ...state,
      };
    case INSPECTION_DELETE_FAILED:
      return {
        ...state,
      };
    case INSPECTION_GET_SINGLE:
      return {
        ...state,
        isLoading: true,
      };
    case INSPECTION_GET_SINGLE_SUCCESS:
      return {
        ...state,
        inspGetSingle: action.payload,
        isLoading: false,
      };
    case INSPECTION_GET_SINGLE_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case INSPECTION_UPDATE:
      return {
        ...state,
        isLoading: true,
      };
    case INSPECTION_UPDATE_SUCCESS:
      return {
        ...state,
        inspUpdateData: action.payload,
      };
    case INSPECTION_UPDATE_FAILED:
      return {
        ...state,
        isLoading: true,
      };
    case INSPECTION_ASSIGN_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case INSPECTION_ASSIGN_SUCCESS:
      return {
        ...state,
        assignData: action.payload,
        isLoading: false,
      };
    case INSPECTION_ASSIGN_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case INSPECTION_REPORT:
      return {
        ...state,
        isLoading: true,
      };
    case INSPECTION_REPORT_SUCCESS:
      return {
        ...state,
        insReportData: action.payload.data,
        isLoading: false,
      };
    case INSPECTION_REPORT_FAILED:
      return {
        ...state,
        insReportData: null,
        isLoading: false,
      };
    case INSPECTION_STATUS:
      return {
        ...state,
        isLoading: true,
      };
    case INSPECTION_GET_STATUS_SUCCESS:
      // console.log("insp Status",action.payload);
      return {
        ...state,
        inspectionStatus: action.payload.data,
        isLoading: false,
        isSubmitted: false,
      };
    case INSPECTION_GET_STATUS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case "CLEAR_INSPECTION_REDUCER":
      return {
        ...state,
        inspectionData: {},
        assignData: [],
        addInspectionData: {},
        inspUpdateData: {},
        inspGetSingle: null,
        insReportData: null,
        inspectionStatus: {},
      };
    default:
      return state;
  }
}
