import { React, useEffect, useState } from 'react'
import { Fragment } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';
import Commonheader from '../../layouts/common/commonHeader';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faElevator, faEye, faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import { accessService } from "../../services/service.index";
import CreatableSelect from 'react-select'
import UserroleModel from './userRoleModel'
import UserRoleheader from '../../layouts/userRole/userRoleHeader';

function Userrole() {

  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [featureAccess, setFeatureAccess] = useState([]);
  const dispatch = useDispatch();

  // // fetch data from redux
  const roleListData = useSelector((state) => state.access.roleListData)
  // const selectedSite = useSelector((state) => state.site.selectedSiteList);

  // const role = useSelector((state) => state.auth.role)

  // console.log(roleListData);

  const getFeatureAccess = async (rolecode) => {
    // console.log(rolecode);
    setLoading(true);
    await accessService
      .getAllAccessByRoleCode(rolecode)
      .then(function (res) {
        setFeatureAccess(res.data[0].feature_control);
        setLoading(false);
      })
      .catch(function (error) {
        // console.log(error.response);
        setLoading(false);
      });
  };

  useEffect(() => {
    // console.log('useEffect FeatureAccess', featureAccess);
  }, [featureAccess])

  ////////////////////////////////

  useEffect(() => {
    // console.log(roleListData);
    if (roleListData === null || roleListData === undefined) {
      dispatch ({
        type: 'ROLE_GET_LIST'
      })
    }
  }, [roleListData]);



  return (
    <div>
      <UserRoleheader />
      <div>
        <div
          className="listHeadings"
          style={{
            fontSize: "20px",
            textAlign: 'center',
            paddingTop: '60px',
            fontWeight: 'bolder'
          }}
        >
          User Roles List
        </div>
        <div style={{
          textAlign: "center",
          fontWeight: 'bolder',
          paddingBottom: '10px'
        }}>View Access Control Based on Role</div>
        <div className="mb-6" style={{ display: 'flex', justifyContent: 'center' }}>
          <table className="table-bordered" width="100%">
            <thead className="table">
              <tr
                style={{
                  textAlign: "center",
                  backgroundColor: "steelblue",
                  padding: "8px",
                  color: "#fff",
                }}>
                <th style={{ padding: "18px", width: '85px' }}>S. No</th>
                <th style={{ padding: "18px", textAlign: "left" }}>Role</th>
                <th style={{ padding: "18px" }}>Status</th>
                <th style={{ padding: "18px" }}>Action</th>
              </tr>
            </thead>
            {roleListData?.length > 0 ?
              (roleListData?.map((eachData, index) => {
                // console.log(eachData);
                return (
                  <tbody key={eachData._id} style={{ padding: "15px" }}>
                    <tr style={{ padding: "20px" }}>
                      <td style={{ textAlign: "center", padding: "8px" }}>
                        {index + 1}
                      </td>
                      <td style={{ textAlign: "left", paddingLeft: "18px" }}>
                        {eachData.role_name
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (match) => match.toUpperCase())}
                      </td>
                      {/* {console.log((eachData.role_name).replace(/_/g, " ").replace(/\b\w/g, (match) => match.toUpperCase()))}                       */}
                      <td>
                        <div data-tip data-for="activeTip">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                            defaultChecked={eachData.role_status}
                            disabled
                            style={{
                              display: "block",
                              margin: "auto",
                              alignItems: "center",
                            }}
                          />
                          <ReactTooltip
                            id="activeTip"
                            place="bottom"
                            effect="solid"
                          >
                            Active
                          </ReactTooltip>
                        </div>
                      </td>
                      {/* view access button */}
                      <td>
                        <button
                          // onClick={() =>setModalShow(true)}
                          // onClick={(e) => {getFeatureAccess(eachData.role_code)}}
                          onClick={() => {
                            getFeatureAccess(eachData.role_code);
                            setModalShow(true);
                          }}
                          style={{
                            color: "#2D7DC1",
                            border: "none",
                            background: "none",
                            display: "block",
                            margin: "auto",
                            alignItems: "center",
                          }}
                          data-tip
                          data-for="updateTip"
                        >
                          <FontAwesomeIcon icon={faEye} size="lg" />
                          <ReactTooltip
                            id="updateTip"
                            place="bottom"
                            effect="solid"
                          >
                            View Access
                          </ReactTooltip>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                );
              })
              )
              : (
                <tbody>
                  <tr style={{ textAlign: "center", height: "75px" }}>
                    <td colSpan="4">No Data Available</td>
                  </tr>
                </tbody>
              )}
          </table>
        </div>
      </div>
      <div>
        {/* model of view access */}
        {modalShow &&
          <UserroleModel
            show={modalShow}
            onHide={() => setModalShow(false)}
            featureaccess={featureAccess}
            loading={loading}
            name={'View Feature Access'}
          />
        }
      </div >
    </div >
  );
}

export default Userrole
