import { ResponsivePie } from '@nivo/pie'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { faServices } from '../../services/service.index'
import { useState } from 'react'
const AccountingStat = ({ capitalData }) => {
    const [data, setData] = useState([])
    const dispatch = useDispatch()
    // const [capitalData, setCapitalData] = useState()
    const color = [
        "rgba(100, 255, 226, 1)", // criticality 1
        "rgba(0, 99, 185, 1)", // criticality 2
        // "rgba(133, 250, 231, 0.8)" // criticality 3
    ];
    // let data = [
    //     {
    //         "id": "Special Tools and Moulds ",
    //         "label": "Special Tools and Moulds ",
    //         "value": 8,
    //         "color": "hsl(35, 70%, 50%)"
    //     },
    //     // {
    //     //     "id": "erlang",
    //     //     "label": "erlang",
    //     //     "value": 161,
    //     //     "color": "hsl(336, 70%, 50%)"
    //     // },
    //     // {
    //     //     "id": "hack",
    //     //     "label": "hack",
    //     //     "value": 105,
    //     //     "color": "hsl(136, 70%, 50%)"
    //     // },
    //     // {
    //     //     "id": "elixir",
    //     //     "label": "elixir",
    //     //     "value": 195,
    //     //     "color": "hsl(16, 70%, 50%)"
    //     // },
    //     // {
    //     //     "id": "java",
    //     //     "label": "java",
    //     //     "value": 29,
    //     //     "color": "hsl(202, 70%, 50%)"
    //     // }
    // ]
    const getData = async () => {
        await faServices
            .getDashboardData()
            .then(function (res) {
                setData(res.data);
                // console.log("2135468", res.data);
            })
            .catch(function (error) {
                // console.log(error.response);
            });
    };
    useEffect(() => {
        getData()
    }, [])
    // let data1 = [
    //     {
    //         "id": "Office Equipments",
    //         "label": "Office Equipments",
    //         "value": 17,
    //         "color": "hsl(35, 70%, 50%)"
    //     },
    //     {
    //         "id": "Building",
    //         "label": "Building",
    //         "value": 8,
    //         "color": "hsl(336, 70%, 50%)"
    //     },
    //     {
    //         "id": "Canteen Equipment",
    //         "label": "hack",
    //         "value": 1,
    //         "color": "hsl(136, 70%, 50%)"
    //     },
    //     {
    //         "id": "Furniture",
    //         "label": "Furniture",
    //         "value": 1,
    //         "color": "hsl(16, 70%, 50%)"
    //     },
    //     // {
    //     //     "id": "java",
    //     //     "label": "java",
    //     //     "value": 29,
    //     //     "color": "hsl(202, 70%, 50%)"
    //     // }
    // ]

    useEffect(() => {
        dispatch({
            type: "GET_BU_CODE_LIST",
        })
    }, [])


    return (
        data.length > 0 &&
        <>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div style={{ marginBottom: "2rem" }} >
                    <div style={{ width: "500px", height: "500px", }}>

                        <ResponsivePie
                            data={data[0]}
                            colors={color}
                            margin={{ top: 40, right: 120, bottom: 80, left: 120 }}
                            innerRadius={0.5}
                            padAngle={0.7}
                            cornerRadius={3}
                            activeOuterRadiusOffset={8}
                            borderWidth={1}
                            borderColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        0.2
                                    ]
                                ]
                            }}
                            arcLinkLabelsSkipAngle={10}
                            arcLinkLabelsTextColor="#333333"
                            arcLinkLabelsThickness={2}
                            arcLinkLabelsColor={{ from: 'color' }}
                            arcLabelsSkipAngle={10}
                            arcLabelsTextColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        2
                                    ]
                                ]
                            }}
                            defs={[
                                // {
                                //     id: 'dots',
                                //     type: 'patternDots',
                                //     background: 'inherit',
                                //     color: 'rgba(255, 255, 255, 0.3)',
                                //     size: 4,
                                //     padding: 1,
                                //     stagger: true
                                // },
                                {
                                    id: 'lines',
                                    type: 'patternLines',
                                    background: 'inherit',
                                    color: 'rgba(255, 255, 255, 0.3)',
                                    rotation: -45,
                                    lineWidth: 6,
                                    spacing: 10
                                }
                            ]}
                            // fill={[
                            //     {
                            //         match: {
                            //             id: 'ruby'
                            //         },
                            //         id: 'dots'
                            //     },
                            //     {
                            //         match: {
                            //             id: 'c'
                            //         },
                            //         id: 'dots'
                            //     },
                            //     {
                            //         match: {
                            //             id: 'go'
                            //         },
                            //         id: 'dots'
                            //     },
                            //     {
                            //         match: {
                            //             id: 'python'
                            //         },
                            //         id: 'dots'
                            //     },
                            //     {
                            //         match: {
                            //             id: 'scala'
                            //         },
                            //         id: 'lines'
                            //     },
                            //     {
                            //         match: {
                            //             id: 'lisp'
                            //         },
                            //         id: 'lines'
                            //     },
                            //     {
                            //         match: {
                            //             id: 'elixir'
                            //         },
                            //         id: 'lines'
                            //     },
                            //     {
                            //         match: {
                            //             id: 'javascript'
                            //         },
                            //         id: 'lines'
                            //     }
                            // ]}
                            legends={[
                                {
                                    anchor: 'bottom',
                                    direction: 'row',
                                    justify: false,
                                    translateX: 0,
                                    translateY: 56,
                                    itemsSpacing: 0,
                                    itemWidth: 100,
                                    itemHeight: 18,
                                    itemTextColor: '#999',
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 1,
                                    symbolSize: 18,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: '#000'
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />
                    </div>
                    <div style={{ margin: "auto", display: "flex" }}>
                        <h5 style={{ margin: "auto" }}>

                            SSG - Asset Class
                        </h5>
                    </div>


                </div>
                <div>
                    <div style={{ width: "500px", height: "500px" }}>

                        <ResponsivePie
                            data={data[1]}
                            colors={color}
                            margin={{ top: 40, right: 120, bottom: 80, left: 120 }}
                            innerRadius={0.5}
                            padAngle={0.7}
                            cornerRadius={3}
                            activeOuterRadiusOffset={8}
                            borderWidth={1}
                            borderColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        0.2
                                    ]
                                ]
                            }}
                            arcLinkLabelsSkipAngle={10}
                            arcLinkLabelsTextColor="#333333"
                            arcLinkLabelsThickness={2}
                            arcLinkLabelsColor={{ from: 'color' }}
                            arcLabelsSkipAngle={10}
                            arcLabelsTextColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        2
                                    ]
                                ]
                            }}
                            defs={[
                                // {
                                //     id: 'dots',
                                //     type: 'patternDots',
                                //     background: 'inherit',
                                //     color: 'rgba(255, 255, 255, 0.3)',
                                //     size: 4,
                                //     padding: 1,
                                //     stagger: true
                                // },
                                {
                                    id: 'lines',
                                    type: 'patternLines',
                                    background: 'inherit',
                                    color: 'rgba(255, 255, 255, 0.3)',
                                    rotation: -45,
                                    lineWidth: 6,
                                    spacing: 10
                                }
                            ]}
                            // fill={[
                            //     {
                            //         match: {
                            //             id: 'ruby'
                            //         },
                            //         id: 'dots'
                            //     },
                            //     {
                            //         match: {
                            //             id: 'c'
                            //         },
                            //         id: 'dots'
                            //     },
                            //     {
                            //         match: {
                            //             id: 'go'
                            //         },
                            //         id: 'dots'
                            //     },
                            //     {
                            //         match: {
                            //             id: 'python'
                            //         },
                            //         id: 'dots'
                            //     },
                            //     {
                            //         match: {
                            //             id: 'scala'
                            //         },
                            //         id: 'lines'
                            //     },
                            //     {
                            //         match: {
                            //             id: 'lisp'
                            //         },
                            //         id: 'lines'
                            //     },
                            //     {
                            //         match: {
                            //             id: 'elixir'
                            //         },
                            //         id: 'lines'
                            //     },
                            //     {
                            //         match: {
                            //             id: 'javascript'
                            //         },
                            //         id: 'lines'
                            //     }
                            // ]}
                            legends={[
                                {
                                    anchor: 'bottom',
                                    direction: 'row',
                                    justify: false,
                                    translateX: 0,
                                    translateY: 56,
                                    itemsSpacing: 0,
                                    itemWidth: 100,
                                    itemHeight: 18,
                                    itemTextColor: '#999',
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 1,
                                    symbolSize: 18,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: '#000'
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />
                    </div>
                    <div style={{ margin: "auto", display: "flex" }}>
                        <h5 style={{ margin: "auto" }}>
                            PSG - Asset Class
                        </h5>
                    </div>
                </div>
            </div>
        </>

    )
}

export default AccountingStat