import {
  takeEvery,
  call,
  put,
  all,
  takeLatest,
} from "@redux-saga/core/effects";
import {
  SITE_GET_LIST,
  SITE_GET_FAILED,
  SITE_GET_SUCCESS,
  SITE_USER_LIST,
  SITE_USER_LIST_FAILED,
  SITE_USER_LIST_SUCCESS,
  SITE_SELECTED_LIST,
  SITE_SELECTED_LIST_SUCCESS,
  SITE_DELETE_LIST,
  SITE_DELETE_SUCCESS,
  SITE_DELETE_FAILED,
  SITE_ADD,
  SITE_ADD_SUCCESS,
  SITE_ADD_FAILED,
  SITE_CONTACT_DETAILS,
  SITE_CONTACT_DETAILS_SUCCESS,
  SITE_CONTACT_DETAILS_FAILED,
  SITE_UPDATE,
  SITE_UPDATE_SUCCESS,
  SITE_UPDATE_FAILED,
  SITE_GET_SINGLE,
  SITE_GET_SINGLE_SUCCESS,
  SITE_GET_SINGLE_FAILED,
  SITE_BULK_ADD,
  SITE_BULK_ADD_SUCCESS,
  SITE_BULK_ADD_FAILED,
  SITE_CONTACT_GET,
  SITE_CONTACT_GET_SUCCESS,
  SITE_CONTACT_GET_FAILED,
  SITE_CONTACT_DELETE,
  SITE_CONTACT_DELETE_FAILED,
} from "../actions/siteAction";

import { siteService } from "../../services/service.index";

export function* watchGetSite() {
  yield takeLatest(SITE_GET_LIST, getSiteSaga);
}

export function* watchSiteUserList() {
  yield takeLatest(SITE_USER_LIST, getSiteUserList);
}


export function* watchGetSelectedSite() {
  yield takeLatest(SITE_SELECTED_LIST, setSiteSelectedlist);
}

export function* watchDeleteSite() {
  yield takeLatest(SITE_DELETE_LIST, deleteSiteSaga);
}

export function* watchAddSite() {
  yield takeLatest(SITE_ADD, addSiteSaga);
}

export function* watchSiteContact() {
  yield takeLatest(SITE_CONTACT_DETAILS, addSiteContactsaga);
}

export function* watchSiteGetContact() {
  yield takeLatest(SITE_CONTACT_GET, getSiteContactsaga);
}

export function* watchSiteContactdelete() {
  yield takeLatest(SITE_CONTACT_DELETE, deleteSiteContactSaga);
}

export function* watchUpdateSite() {
  yield takeLatest(SITE_UPDATE, updateSiteSaga);
}

export function* watchSingleSiteget() {
  yield takeLatest(SITE_GET_SINGLE, sitegetSingleSaga);
}

export function* watchBulkAddSite() {
  yield takeLatest(SITE_BULK_ADD, bulkAddSiteSaga);
}

export function* getSiteSaga(action) {
  try {
    const response = yield call(siteService.getSiteData, action.payload);
    if (response.status === 200) {
      yield put({
        type: SITE_GET_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: SITE_GET_FAILED,
      });
    }
  } catch (e) {
    yield put({
      type: SITE_GET_FAILED,
    });
  }
}

export function* getSiteUserList(action) {
  try {
    const response = yield call(siteService.getSiteUserList, action.payload);
    console.log("response",response)
    if (response.status === 200) {
      yield put({
        type: SITE_USER_LIST_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: SITE_USER_LIST_FAILED,
      });
    }
  } catch (e) {
    yield put({
      type: SITE_USER_LIST_FAILED,
    });
  }
}

export function* setSiteSelectedlist(action) {
  try {
    yield put({
      type: SITE_SELECTED_LIST_SUCCESS, // or another type if you want to separate it
      payload: action.payload, // Store the data directly from the action payload
    });
    console.log("success")
  } catch (e) {
    console.error("Failed to store data in Redux:", e);
  }
}

export function* deleteSiteSaga(action) {
  try {
    const response = yield call(siteService.deleteSiteData, action.payload);
    // console.log(response)
    if (response.status === 200) {
      yield put({
        type: SITE_GET_LIST,
      });
      alert("Site Deleted Successfully");
    } else {
      yield put({
        type: SITE_DELETE_FAILED,
      });
    }
  } catch (e) {
    yield put({
      type: SITE_DELETE_FAILED,
    });
  }
}

export function* addSiteSaga(action) {
  try {
    const response = yield call(siteService.addSiteData, action.payload);
    // console.log(response);
    if (response.status === 201) {
      let obj = { subscriberData: action.payload, siteId: response.data._id };
      yield put({
        type: SITE_ADD_SUCCESS,
        payload: obj,
      });
      yield put({
        type: SITE_GET_LIST,
      });
      alert("Site Uploaded Successfully");
    } else {
      yield put({
        type: SITE_ADD_FAILED,
      });
    }
  } catch (e) {
    // console.log(e);
    if (e.response.data.message === "Duplicate site_name entered") {
      alert("Entered site name already exists.");
    }
    yield put({
      type: SITE_ADD_FAILED,
    });
  }
}

export function* updateSiteSaga(action) {
  try {
    const response = yield call(siteService.updateSite, action.payload);
    if (response.status === 200) {
      yield put({
        type: SITE_UPDATE_SUCCESS,
        payload: action.payload,
      });
      yield put({
        type: SITE_GET_LIST,
      });
      alert("Site Updated Successfully");
    } else {
      yield put({
        type: SITE_UPDATE_FAILED,
      });
    }
  } catch (e) {
    // console.log("error", e);
    yield put({
      type: SITE_UPDATE_FAILED,
    });
  }
}

export function* sitegetSingleSaga(action) {
  try {
    const response = yield call(siteService.getSingleSite, action.payload);
    if (response.status === 200) {
      yield put({
        type: SITE_GET_SINGLE_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: SITE_GET_SINGLE_FAILED,
      });
    }
  } catch (e) {
    yield put({
      type: SITE_GET_SINGLE_FAILED,
    });
  }
}

export function* bulkAddSiteSaga(action) {
  try {
    const response = yield call(siteService.addBulkSite, action.payload);
    // console.log("addBulkSite action.payload", action.payload)
    if (response.status === 201) {
      // console.log("addBulkSite response", response)
      alert("Data uploaded Successfully");
      yield put({
        type: SITE_GET_LIST,
        // payload: response,
      });
    } else {
      // console.log("SITE_Bulk_ADD_FAILED Error response", response)
    }
  } catch (e) {
    // console.log("addCustSaga Error response", e)
    // alert('Error!', e.response.data.message, [
    //     { text: 'Okay' }
    // ]);
    if (e.response.data.message === "Duplicate site_name entered") {
      alert("Duplicate Legal Name Entered");
    }
    if (e.response.data.message === "Duplicate website entered") {
      alert("Duplicate Website Entered");
    }
  }
}

export function* addSiteContactsaga(action) {
  try {
    const response = yield call(siteService.addSiteContact, action.payload);
    // console.log(action.payload);
    if (response.status === 201) {
      // console.log(response)
      let obj = {
        contactdetails: action.payload.newObj,
        siteId: action.payload.id,
      };
      // console.log(obj);
      yield put({
        type: SITE_CONTACT_DETAILS_SUCCESS,
        payload: obj,
      });
      alert("Site Contact Uploaded Successfully");
      yield put({
        type: SITE_CONTACT_GET,
        payload: obj.siteId,
      });
    } else {
      yield put({
        type: SITE_CONTACT_DETAILS_FAILED,
      });
    }
  } catch (e) {
    // console.log("e", e);
    yield put({
      type: SITE_CONTACT_DETAILS_FAILED,
    });
  }
}

export function* getSiteContactsaga(action) {
  try {
    const response = yield call(siteService.getSiteContact, action.payload);
    // console.log(action.payload);
    if (response.status === 200) {
      yield put({
        type: SITE_CONTACT_GET_SUCCESS,
        payload: response.data.contacts,
      });
    } else {
      yield put({
        type: SITE_CONTACT_GET_FAILED,
      });
    }
  } catch (e) {
    // console.log("e", e);
    yield put({
      type: SITE_GET_FAILED,
    });
  }
}

export function* deleteSiteContactSaga(action) {
  try {
    const response = yield call(
      siteService.deleteSiteContactData,
      action.payload
    );
    if (response.status === 200) {
      yield put({
        type: SITE_CONTACT_GET,
        payload: action.payload.id,
      });
    } else {
      yield put({
        type: SITE_CONTACT_DELETE_FAILED,
      });
    }
  } catch (e) {
    yield put({
      type: SITE_CONTACT_DELETE_FAILED,
    });
  }
}
