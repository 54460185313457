
// action for Ticket status.
const TICKET_STATUS = "TICKET_STATUS";
const TICKET_STATUS_SUCCESS = "TICKET_STATUS_SUCCESS";
const TICKET_STATUS_FAILED = "TICKET_STATUS_FAILED";

// action for Ticket report.
const TICKET_REPORT = "TICKET_REPORT";
const TICKET_REPORT_SUCCESS = "TICKET_REPORT_SUCCESS";
const TICKET_REPORT_FAILED = "TICKET_REPORT_FAILED";

export {
  TICKET_STATUS,
  TICKET_STATUS_SUCCESS,
  TICKET_STATUS_FAILED,

  TICKET_REPORT,
  TICKET_REPORT_SUCCESS,
  TICKET_REPORT_FAILED,
};
