/* Feature - action list for plant

Created by - Mona R
Updated by - Mona R */

// action to create new asset.
const PLANT_ADD = 'PLANT_ADD';
const PLANT_ADD_SUCCESS = 'PLANT_ADD_SUCCESS';
const PLANT_ADD_FAILED = 'PLANT_ADD_FAILED';

const PLANT_GET_ALL = 'PLANT_GET_ALL';
const PLANT_GET_ALL_SUCCESS = 'PLANT_GET_ALL_SUCCESS';
const PLANT_GET_ALL_FAILED = 'PLANT_GET_ALL_FAILED';

const PLANT_GET_SINGLE = 'PLANT_GET_SINGLE';
const PLANT_GET_SINGLE_SUCCESS = 'PLANT_GET_SINGLE_SUCCESS';
const PLANT_GET_SINGLE_FAILED = 'PLANT_GET_SINGLE_FAILED';

const PLANT_UPDATE = 'PLANT_UPDATE';
const PLANT_UPDATE_SUCCESS = 'PLANT_UPDATE_SUCCESS';
const PLANT_UPDATE_FAILED = 'PLANT_UPDATE_FAILED';

export {
    PLANT_ADD,
    PLANT_ADD_SUCCESS,
    PLANT_ADD_FAILED,
    PLANT_GET_ALL,
    PLANT_GET_ALL_SUCCESS,
    PLANT_GET_ALL_FAILED,
    PLANT_GET_SINGLE,
    PLANT_GET_SINGLE_SUCCESS,
    PLANT_GET_SINGLE_FAILED,
    PLANT_UPDATE,
    PLANT_UPDATE_SUCCESS,
    PLANT_UPDATE_FAILED
}