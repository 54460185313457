import serviceBase from "../../utilities/interceptor/apiInterceptor";
import { CONSTANTS } from "../../constants/URLs/urlConstants";

function addMaintData(data) {
  return serviceBase.post(CONSTANTS.MAINT_CREATE + `?type=${data[0]}`, data[1]);
    
}
function getMaintData(data) {
   return serviceBase.get(
     CONSTANTS.MAINT_GET_ALL +
       `?type=${data[0]}&page=${data[1]?data[1]:1}&limit=${data[2]?data[2]:10}&key=${data[3]?data[3]:''}&site=${data['id']}`
   );
}
function addBulkMainData(data) {
  // console.log(data);
  return serviceBase.post(CONSTANTS.MAITENANCE_BULK + data[0] + `?type=${data[2]}`, data[1]);
}
function maintenanceReport(id) {
  return serviceBase.post(CONSTANTS.MAINTENANCE_REPORT , id);
}

function assignMaintData(data) {
  // console.log(data);
  return serviceBase.patch(CONSTANTS.MAINTENANCE_ASSIGN + data[1], data[0]);
};

function deleteMaintenanceData (id) {
  // console.log("id", id);
  return serviceBase.delete(CONSTANTS.MAINTENANCE_DELETE + id[4]);    
};

function getMaintSingle(id) {
  // console.log("id", id);
  return serviceBase.get(CONSTANTS.MAINT_GET_SINGLE + id);
}
function updateMaintData(data) {
  // console.log("data", data);
  return serviceBase.patch(CONSTANTS.MAINTENANCE_UPDATE + data[1], data[0]);
}
function maintenanceGetallStatus(data) {
  // console.log(data);
  // console.log(CONSTANTS.MAINTENANCE_STATUS + data[4] + `?type=${data[3]}&page=${data[0]}&limit=${data[1]}&status=${data[2]}&key=${data[7]}&maint_start=${data[5]}&maint_end=${data[6]}`);
  return serviceBase.get(
    CONSTANTS.MAINTENANCE_STATUS + data[4] + `?type=${data[3]}&page=${data[0]}&limit=${data[1]}&status=${data[2]}&key=${data[7]}&maint_start=${data[5]}&maint_end=${data[6]}&site=${data['id']}`
  );
}
function stopMaintenanceData(data) {
  // console.log("data", data);
  return serviceBase.patch(CONSTANTS.MAINTENANCE_STOP + data[1], data[0]);
}

function SOPByAssetID(id) {
  // console.log(id);
  return serviceBase.get(CONSTANTS.SOP_GET_BY_ASSET_ID + id);
}

function assignSOPData(data) {
  // console.log("data", data);
  return serviceBase.patch(CONSTANTS.ASSIGN_SOP_MAIN + data[1], data[0]);
}

export const maintenanceDetails = {
  addMaintData,
  getMaintData,
  addBulkMainData,
  maintenanceReport,
  assignMaintData,
  deleteMaintenanceData,
  getMaintSingle,
  updateMaintData,
  maintenanceGetallStatus,
  stopMaintenanceData,
  SOPByAssetID,
  assignSOPData,
};