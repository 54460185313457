import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./SiteFilter.css"
import {
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { SITE_SELECTED_LIST, SITE_USER_LIST } from '../../redux/actions/siteAction';
import ReactTooltip from 'react-tooltip';

function SiteFilter() {
  const dispatch = useDispatch();
  const role = useSelector((state) => state.auth.role);
  const access = useSelector((state) => state.access.site);
  const [showModal, setShowModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const siteData = useSelector((state) => state.site.siteUserList);
  const [siteDataList, setSiteDataList] = useState([]);
  const selectedSite = useSelector((state) => state.site.selectedSiteList);
  const [selectSite, setSelectSite] = useState([]);
  const [selectAll, setSelectAll] = useState(selectedSite?.selectAll);
  const isLoading = useSelector((state) => state.site.isLoading);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100;

  const getSiteDetails = (obj) => {
    dispatch({
      type: SITE_USER_LIST,
      payload: obj,
    });
  };

  let totalPages = Math.ceil(siteData?.total_count / itemsPerPage);

  //useEffects
  useEffect(() => {
    // Combined effect for selectedSite and currentPage to fetch data
    if (siteData.length === 0 || currentPage >= 1) {
      const obj = { page: currentPage, limit: itemsPerPage, search: searchInput };
      getSiteDetails(obj);
    }
  }, [currentPage]);

  useEffect(() => {
    // Combined effect for access and role-based access fetch
    if (access === null && role[0] !== null) {
      dispatch({
        type: "ACCESS_GET_LIST",
        payload: role[0],
      });
    }
    setSelectSite(selectedSite?.selectedSite)
  }, [access, role]);

  useEffect(() => {

    if (siteData?.result && searchInput?.length === 0) {
      setFilteredData([])
      if (siteData?.page_number > 1 && siteDataList?.length > 1) {
        setSiteDataList((prev) => [...(prev || []), ...siteData.result]);
      } else if (siteData?.page_number == 1) {
        setSiteDataList([...siteData.result])
      }
    }

    if (siteData?.result && searchInput?.length > 0) {
      if (currentPage > 1) {
        setFilteredData((prev) => [...(prev || []), ...siteData.result]);
      } else {
        setFilteredData(siteData.result);
      }
    }

    if (selectAll && siteDataList) {
      setSelectSite([...(siteDataList || []), ...(siteData?.result || [])]);
    }

  }, [siteData]);

  // Handle modal 
  const handleModal = (e, val) => {
    setShowModal(!showModal)
    if (val == 'close') {
      setSelectSite(selectedSite?.selectedSite)
      setSelectAll(selectedSite?.selectAll)
    }
  }

  // Handle site selection
  const handleSiteSelection = (site) => {
    setSelectSite((prev) => {
      const exists = prev.some((item) => item._id === site._id);
      if (exists) {
        // if (prev.length === 1) {
        //   // Prevent unselecting the last remaining item
        //   return prev;
        // }
        setSelectAll(false); // Uncheck "Select All" if a site is deselected
        return prev.filter((item) => item._id !== site._id);
      } else {
        // Add the site if it's not already selected
        let data = [...prev, site]
        if (data.length === siteData?.total_count) {
          setSelectAll(true);
        } else {
          setSelectAll(false);
        }
        return [...prev, site];

      }
    });
  };

  // Handle submit 
  const handleSubmit = () => {
    let obj = { selectAll, selectedSite: selectSite }
    setSearchInput('')
    // if (!selectAll && selectSite.length == 0) {
    //   return;
    // }
    dispatch({
      type: SITE_SELECTED_LIST,
      payload: obj,
    });
    if (selectSite?.length == 0) {
      setSelectAll(true)
    }
    // setSearchInput('')
    setFilteredData([])
    setShowModal(!showModal)
  }

  // Handle site search
  const handleSiteSearch = (e) => {
    setSearchInput(e.target.value)
    const obj = { page: 1, limit: itemsPerPage, search: e.target.value };
    setFilteredData([])
    setCurrentPage(1)
    if (e.target.value == '') {
      setSiteDataList([]);
      setFilteredData([]);
      setCurrentPage(1);
      const obj = { page: 1, limit: itemsPerPage, search: '' };
      getSiteDetails(obj);
      return;
    }
    getSiteDetails(obj);

  }

  // Handle select all
  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    setSelectSite(isChecked ? siteDataList : []);
  };

  // Handle scroll
  const handleScroll = (e) => {
    const bottomDistance = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight;
    if (bottomDistance < 1 && currentPage <= totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const dataToDisplay = searchInput.length > 0 ? filteredData : siteDataList;
  const SelectedDatatoDisplay = selectAll ? searchInput.length > 0 ? filteredData : siteDataList : selectSite;


  return (
    <>
      <Modal
        onHide={(e) => handleModal(e, "close")}
        show={showModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Site Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='modal-body-div' >
            <div >
              <label className='select-site-list' >Select Sites</label>
              <input type="text" value={searchInput} placeholder="Search sites..." className='select-site-list-input' onChange={handleSiteSearch} />
              <div className='select-all-div'>
                <p>Select All</p>
                <input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
              </div>
              <ul className='select-site-ul' onScroll={handleScroll} >
                {dataToDisplay?.length > 0 && dataToDisplay.map((site, index) => (
                  <li key={index} >
                    <label >
                      <input
                        type="checkbox"
                        checked={selectAll ? true : selectSite.some((item) => item._id === site._id)}
                        onChange={() => handleSiteSelection(site)}
                      />
                      {site?.site_name}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
            <div className='selected-site-list'>
              <label >Selected Sites</label>
              <ul>
                {SelectedDatatoDisplay?.length > 0 && SelectedDatatoDisplay?.map((site, index) => (
                  <li key={index} >
                    <div>
                      <div>
                        <span >{index + 1}.</span>
                        {site?.site_name}
                      </div>
                      <input type="checkbox" onChange={() => { handleSiteSelection(site) }} checked={true} />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <br />
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleSubmit}
            variant="primary"
            disabled={!selectAll && selectSite?.length === 0}
            style={!selectAll && selectSite?.length === 0 ? { pointerEvents: "none", opacity: 0.5 } : {}}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="icon-container" data-tip data-for="siteTip" style={{ position: 'relative', display: 'inline-block' }}>
        <FontAwesomeIcon
          onClick={(e) => handleModal(e, "save")}
          size="lg"
          className="site-icon"
          icon={faLocationDot}
        />
        <ReactTooltip
          id="siteTip"
          place="bottom"
          effect="solid"
        >Site Filter
        </ReactTooltip>
        {selectSite?.length > 0 && !selectAll && (
          <span class="icon-badge">
            {selectSite?.length}
          </span>
        )}
      </div>
    </>
  )
}

export default SiteFilter
