/* Feature - setting store for redux

Created by - Mona R
Updated by - Mona R */

// importing dependencies and packages
import { legacy_createStore as createStore, applyMiddleware} from 'redux'; //need to migrate to redux toolkit

import createSagaMiddleware from 'redux-saga';
import rootReducer from '../redux/reducers/reducer.index';
import rootSaga from '../redux/sagas/saga.index';
import { persistStore, persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session'
import { encryptTransform } from 'redux-persist-transform-encrypt';
import apiMiddleware from './apiMiddleware';

const persistConfig = {
    key: 'root',
    storage: storageSession,
    // blacklist: ['customer'] // maintain blacklist reduser here. This is give for future usage
    whitelist: ['auth', 'site'],
    transforms: [
      encryptTransform({
        secretKey: 'my-super-secret-key',
        onError: function (error) {
          // Handle the error.
        },
      }),
    ],
  }

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const store = createStore(persistedReducer, applyMiddleware(apiMiddleware,sagaMiddleware));

const Persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
export {Persistor};
export default store;