import { fork, all } from "redux-saga/effects";
import { watchLogin, watchLogout } from "./authSaga";
import {
  watchGetCust,
  watchDeleteCust,
  watchAddCust,
  watchCutomerUpdate,
  watchSingleCustomerget,
  watchCutomerGetContact,
  watchCutomerContact,
  watchBulkAddCust,
  watchCustContactdelete,
} from "./custSaga";

import {
  watchAddUser,
  watchGetUser,
  watchCreatePass,
  watchGetRole,
  watchAddBulkUser,
  watchDeletekUser,
  watchGetSingleUser,
  watchApprovUsers,
  watchGetUserTech,
  watchGetUserApprover
} from "./userSaga";

import {
  watchGetSite,
  watchGetSelectedSite,
  watchDeleteSite,
  watchAddSite,
  watchBulkAddSite,
  watchUpdateSite,
  watchSingleSiteget,
  watchSiteContact,
  watchSiteGetContact,
  watchSiteContactdelete,
  watchSiteUserList,
} from "./siteSaga";
import {
  watchGetAsset,
  watchAddAsset,
  watchDeleteAsset,
  watchBulkAddAsset,
  watchAssetUpdate,
  watchSingleAssetget,
  watchSOPUpload,
} from "./assetSaga";
import {
  watchGetDevice,
  watchDeleteDevice,
  watchAddDevice,
  watchDeviceUpdate,
  watchSingleDeviceget,
} from "./deviceSaga";
import {
  watchGetFacility,
  watchDeleteFacility,
  watchAddFacility,
  watchSingleFacilityget,
  watchFacilityUpdate,
} from "./facilitySaga";

import {
  watchGetInspection,
  watchAssignInspection,
  watchDeleteInspection,
  watchBulkAddInspection,
  watchAddInspection,
  watchInspectionUpdate,
  watchGetSingleInspection,
  watchInspectionReportGet,
  watchGetInspectionStatus,
  watchStopInspectionStatus,
} from "./inspectionSaga";

import {
  watchAddMaint,
  watchGetMaintenance,
  watchBulkAddMaint,
  watchAssignMaintenance,
  watchDeleteMaintenance,
  watchMaintReportGet,
  watchGetSingleMaint,
  watchMaintUpdate,
  watchGetMaintStatus,
  watchStopMaintenanceStatus,
  watchSOPGet,
  watchSOPAssign,
} from "./maintSaga";

import {
  watchGetInsMainStatus,
  watchGetInsMainOfflineStatus,
} from "./dashboardSaga";

import { watchGetAccess, watchGetRoleList } from "./accessSaga";

import { watchGetHistory } from "./histrySaga";

import { watchUserBasedReportStatus,
  watchGetReportBuilderData,
  watchGetReportBuilderQuery, 
  watchGetReportBuilderAttributes ,
  watchGetReportBuilderFieldValues, 
  watchSaveReportBuilderQuery,
  watchGetReportBuilderQueryList,
  watchUpdateReportBuilderQuery,
  watchDeleteReportBuilderQuery,
  watchSaveReport,

} from "./reportSaga";

import { watchGetCWIP, 
  watchAddCapitalise, 
  watchAddExpense, 
  watchGetExpense, 
  watchGetCapital, 
  watchAddLocation, 
  watchGetLocation, 
  watchAddClass, 
  watchGetClass, 
  watchGetCalculation, 
  watchAddFAAsset, 
  watchAddBulkFAAsset, 
  watchUpdateAsset, 
  watchAddCostCenter, 
  watchGetCostCenter, 
  watchBuCode,
  watchAddDeleteAsset,
  watchAddSale, watchGetVerfiedList, watchGetReportData, watchAddSampleList, watchGetSampleData

} from "./faSaga";

import { watchGetTicketStatus, watchTicketReportGet } from './ticketSaga'


function* rootSaga() {
  yield all([
    watchLogin(), //auth
    watchLogout(),
    watchGetCust(),
    watchDeleteCust(),
    watchAddCust(),
    watchCutomerContact(),
    watchCutomerGetContact(),
    watchCutomerUpdate(),
    watchSingleCustomerget(),
    watchBulkAddCust(),
    watchCustContactdelete(),

    // User Watch
    watchAddUser(),
    watchGetUser(),
    watchCreatePass(),
    watchGetRole(),
    watchAddBulkUser(),
    watchDeletekUser(),
    watchGetSingleUser(),
    watchApprovUsers(),
    watchGetUserTech(),
    watchGetUserApprover(),

    // Site watch
    watchGetSite(),
    watchSiteUserList(),
    watchGetSelectedSite(),
    watchDeleteSite(),
    watchAddSite(),
    watchUpdateSite(),
    watchSingleSiteget(),
    watchBulkAddSite(),
    watchSiteGetContact(),
    watchSiteContact(),
    watchSiteContactdelete(),

    // Asset watch
    watchGetAsset(),
    watchAddAsset(),
    watchDeleteAsset(),
    watchBulkAddAsset(),
    watchAssetUpdate(),
    watchSingleAssetget(),
    watchSOPUpload(),

    // Device watch
    watchGetDevice(),
    watchDeleteDevice(),
    watchAddDevice(),
    watchDeviceUpdate(),
    watchSingleDeviceget(),

    // Facility watch
    watchGetFacility(),
    watchDeleteFacility(),
    watchAddFacility(),
    watchSingleFacilityget(),
    watchFacilityUpdate(),

    // Inspection watch
    watchAddInspection(),
    watchGetInspection(),
    watchAssignInspection(),
    watchDeleteInspection(),
    watchBulkAddInspection(),
    watchInspectionUpdate(),
    watchGetSingleInspection(),
    watchInspectionReportGet(),
    watchGetInspectionStatus(),
    watchStopInspectionStatus(),

    //Maintenance watch
    watchAddMaint(),
    watchGetMaintenance(),
    watchBulkAddMaint(),
    watchAssignMaintenance(),
    watchDeleteMaintenance(),
    watchMaintReportGet(),
    watchGetSingleMaint(),
    watchMaintUpdate(),
    watchGetMaintStatus(),
    watchStopMaintenanceStatus(),
    watchSOPGet(),
    watchSOPAssign(),

    // Access watch
    watchGetAccess(),
    watchGetRoleList(),

    // Dashboard watch
    watchGetInsMainStatus(),
    watchGetInsMainOfflineStatus(),


    //History watch
    watchGetHistory(),

    // report watch
    watchUserBasedReportStatus(),

    // FA watch
    watchGetCWIP(),
    watchAddCapitalise(),
    watchAddExpense(),
    watchGetExpense(),
    watchGetCapital(),
    watchAddLocation(),
    watchGetLocation(),
    watchAddClass(),
    watchGetClass(),
    watchGetCalculation(),
    watchAddFAAsset(),
    watchAddBulkFAAsset(),
    watchUpdateAsset(),
    watchAddCostCenter(),
    watchGetCostCenter(),
    watchBuCode(),
    watchAddDeleteAsset(),
    watchAddSale(),
    watchGetVerfiedList(),
    watchGetReportData(),
    watchAddSampleList(),
    watchGetSampleData(),

     //Custom report builder
     watchGetReportBuilderData(),
     watchGetReportBuilderQuery(),
     watchGetReportBuilderAttributes(),
     watchGetReportBuilderFieldValues(),
     watchSaveReportBuilderQuery(),
     watchGetReportBuilderQueryList(),
     watchUpdateReportBuilderQuery(),
     watchDeleteReportBuilderQuery(),
     watchSaveReport(),

    // Ticket watch
    watchGetTicketStatus(),
    watchTicketReportGet()
  ]);
}
export default rootSaga;
