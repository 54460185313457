import React, { useEffect, useState, useRef } from "react";
import ReactToPrint from "react-to-print";
import InspectionReport from "./inspectionReport";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import MaintenanceReport from "./maintenanceReport";
import Commonheader from "../../../layouts/common/commonHeader";



const GenrateMainpdf = () => {

    const componentRef = useRef();
    const [key, setKey] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedItem, setSelectedItem] = useState("Asset");
    const [fileName, setFileName] = useState(""); // To set Custom file name

    const maintReportDataArray = useSelector(
        (state) => state.maintenance.maintReportData
    );
    const [data, setData] = useState({
        currentPage: '',
        itemsPerPage: '',
        startDate: '',
        endDate: '',
        searchInput: '',
        name: '',
    });
    // console.log(insReportData);


    const handlePrint = () => {
        componentRef.current.handlePrint();
    };

    useEffect(() => {
        // console.log(location);
        // console.log(location.state);
        // console.log(location.state[0]);
        if (location.state !== undefined && location.state !== null) {
            setKey(location.state?.[0])
            setSelectedItem(location.state?.[1])
            const dynamicFileName = generateFileName();
            setFileName(dynamicFileName);
            setData({
                currentPage: location.state?.[2],
                itemsPerPage: location.state?.[3],
                startDate: location.state?.[4],
                endDate: location.state?.[5],
                searchInput: location.state?.[6],
                name: location.state?.[7],
            })
        }
    }, [maintReportDataArray])

    // Function to generate the custom file name dynamically
    const generateFileName = () => {
        // Implement your logic here to generate the file name
        // Example: return `inspection_${key}_${selectedItem}.pdf`;
        if (!Array.isArray(maintReportDataArray) || maintReportDataArray.length === 0) {
            return "Report_NoDataAvailable_V1.09.pdf";
        }

        // Iterate over the array and extract required information
        const fileDetails = maintReportDataArray.map((item, index) => {
            const parentId = item?.parent?.parent_id || "";
            const endDate = item?.maintenance?.maint_completed_date
                ? moment(item?.maintenance?.maint_completed_date).format("YYYY-MM-DD")
                : "";
            return `Report-${index + 1}_${parentId}_${endDate}`;
        });

        // Join details with a separator for a comprehensive filename
        const fileName = `Report_${fileDetails.join("_")}_V1.09.pdf`;

        return fileName;
    };


    return (
        <div>
            <Commonheader />
            {/* {console.log('afterImageSrc', afterImageSrc)}
      {console.log('b4', imageSrc)} */}

            <h4 className="listHeadings">Maintenance Report</h4>
            {/* Back Btn */}
            <button
                type="button"
                onClick={() => {
                    navigate("/maintenancestatus", { state: [key, selectedItem?.toLowerCase(), data?.currentPage, data?.itemsPerPage, data?.startDate, data?.endDate, data?.searchInput] })
                }}
                className="backButton my-0 mx-4"
            >
                Back
            </button>
            <ReactToPrint
                trigger={() => (
                    // <button className="btn btn-primary" onClick={handlePrint}>
                    //     Print to PDF!
                    // </button>
                    <button
                        type="button"
                        style={{ float: "right" }}
                        onClick={handlePrint}
                        className="saveButton my-0 mx-4"
                    >
                        Download
                    </button>
                )}
                content={() => componentRef.current}
                documentTitle={fileName} // Set the custom file name
                pageStyle={`
                    @page {
                        size: 'A4 portrait';
                        margin-top: 60px;
                    }
                    @media print {
                        #page-header {
                            position: fixed;
                            top: 0;
                            left: 0;
                            right: 0;
                            height: 50px;
                            background-color: lightgray;
                            padding: 10px;
                        }
                    #content {
                        page-break-before: always;
                    }   
                }`}
            />
            <div ref={componentRef} >
                <div style={{ marginTop: '60px' }}>
                    {/* Add the necessary content here */}
                    <MaintenanceReport />
                </div>
            </div>
        </div>
    );
    // }
}

export default GenrateMainpdf;
