// middleware/apiMiddleware.js
import { actionArray } from './actions/action.index';


const apiMiddleware = (store) => (next) => (action) => {
  // Check if the action type requires `id` injection
  if (actionArray.includes(action.type)) {
    const idArray = store.getState().site.selectedSiteList?.selectedSite?.map((site) => site._id);
    const selectAll = store.getState().site.selectedSiteList?.selectAll;

    // Convert the array to a comma-separated string if it's an array
    const id = Array.isArray(idArray) ? idArray.join(',') : idArray;

    const updatedPayload = {
      ...action.payload,
      id:selectAll?'':id,
    } ;

    // Pass along the modified action with the updated payload

    return next({ ...action, payload: updatedPayload });
  }

  // For all other actions, pass them as is
  return next(action);
};

export default apiMiddleware;
