export const CONSTANTS = {
  AUTH_PATH: "/login",
  LOGOUT_PATH: "/logout",

  // USER_REGISTRATION: "/profile/register",
  REFRESH_TOKEN: "/refresh",
  VIEW_USER: "/register/viewuser",

  USER_ADD: "/user",
  USER_BULK_ADD: "/user/bulk/",
  USER_GET: "/register",
  USER_CREATE_PASSWORD: "/user/pass",
  USER_DELETE: "/user/",
  ROLE_GET: "/roles/",
  USER_GET_TECH: "/user/alltech",
  USER_GET_Approver: "/user/",

  CUST_GET_ALL: "/customers/",
  CUST_DELETE: "/customers/remove/",
  CUST_ADD: "/customers/create", // created by
  CUS_UPDATE: "/customers/update/", // modified by
  CUST_GET_SINGLE: "/customers/",
  CUST_CONTACT_ADD: "/customers/contact/", // created by
  CUST_CONTACT_GET: "/customers/contact/",
  CUST_CONTACT_DELETE: "/customers/contact/",
  CUST_BULK_ADD: "/customers/bulk/", // created by

  SITE_GET_ALL: "/site",
  SITE_BY_CUSTID: "/site/bycust/",
  SITE_DELETE: "/site/remove/",
  SITE_ADD: "/site/create",
  SITE_BULK_ADD: "/site/bulk/",
  SITE_CONTACT_ADD: "/site/contact/",
  SITE_CONTACT_GET: "/site/contact/",
  SITE_CONTACT_DELETE: "/site/contact/",
  SITE_USER_LIST:"/site/all/user-sites",

  SITE_UPDATE: "/site/update/",
  SITE_GET_SINGLE: "/site/",

  ASSET_GET_ALL: "/assets",
  ASSET_GET_SINGLE: "/assets/",
  ASSET_BYSITEID: "/assets/bysite/",
  ASSET_DELETE: "/assets/remove/",
  ASSET_ADD: "/assets/upload",
  ASSET_UPDATE: "/assets/update/",
  ASSET_BULK_ADD: "/assets/",

  PLANT_ADD: "/plants",
  PLANT_GET_ALL: "/plants/",
  PLANT_GET_SINGLE: "/plants/",
  PLANT_UPDATE: "/plants/",
  PLANT_GET_SUMMARY: "/fa/report/listofplants",
  PLANT_GET_HISTORY: "/fa/history/",
  PLANT_RFID: "/plants/rfid/list",
  PLANT_RFID_LOCATION_WISE: 'plants/rfid/report/',
  PLANT_DASH_BOARD: '/plants/bargraph/count',

  DEVICE_GET_ALL: "/device",
  DEVICE_GET_SINGLE: "/device/",
  DEVICE_DELETE: "/device/remove/",
  DEVICE_ADD: "/device/upload",
  DEVICE_UPDATE: "/device/update/",

  FACILITY_GET_ALL: "/facility",
  FACILITY_GET_SINGLE: "/facility/",
  FACILITY_DELETE: "/facility/remove/",
  FACILITY_ADD: "/facility/upload",
  FACILITY_UPDATE: "/facility/update/",

  INSP_CREATE: "/inspection",
  INSPECTION_GET_ALL: "/inspection",
  INSPECTION_GET_SINGLE: "/inspection/",
  INSPECTION_UPDATE: "/inspection/update/",
  INSPECTION_STATUS: "/inspection/trans/all/status/",

  INSPECTION_ASSIGN: "/inspection/assign/",
  INSPECTION_DELETE: "/inspection/",
  INSPECTION_BULK: "/inspection/bulk/",
  INSPECTION_REPORT: "/inspection/trans/report", //report
  INSPECTION_STOP: "/inspection/stopassign/",

  MAINT_CREATE: "/maintenance/",
  MAINT_GET_SINGLE: "/maintenance/",
  MAINT_GET_ALL: "/maintenance",
  MAINTENANCE_REPORT: "/maintenance/trans/report", //report
  MAITENANCE_BULK: "/maintenance/bulk/",
  MAINTENANCE_ASSIGN: "/maintenance/assign/",
  MAINTENANCE_DELETE: "/maintenance/",
  MAINTENANCE_UPDATE: "/maintenance/update/",
  MAINTENANCE_STATUS: "/maintenance/trans/all/status/", //status
  GET_APPROV_USERS: "/user/",
  MAINTENANCE_STOP: "/maintenance/stopassign/",

  ACCESS_GET: "/accesscontrol/", // Get feature access based on role code og LoggedIn.
  ACCESS_ROLE_LIST: "/roles", // Get list of all role available in system.
  ACCESS_GET_ALL: "/accesscontrol/custom/", // Get feature access of all code for viewing access based on selected Role.
  CREATE_CUSTOM_ROLE: "/roles/custom", // To create custom role

  // Dashboard API
  INS_MAIN_STATUS: "/dashboard/count/",
  FILTER_DATA_INS_STATUS: "/dashboard/landingpage/ins",
  FILTER_DATA_MAIN_STATUS: "/dashboard/landingpage/maint",
  ASSET_CRITICALITY: '/dashboard/criticality',
  INS_MAIN_STATUS_OFFLINE: '/dashboard/count/offline/',


  //Asset History - Inspection
  HISTRY_DATA: "/history/",

  // Report - All
  REPORT_BASED_ON_USER: '/dashboard/report',

  // SOP attachment 
  SOP_ATTACHMENT: '/sop/',
  SOP_GET_BY_ASSET_ID: '/sop/',
  ASSIGN_SOP_MAIN: '/maintenance/map-sop/',


  // FA Modules
  GET_CWIP: '/fa/list',
  ADD_CAPITALISE: '/fa/capitalise',
  ADD_EXPENSE: '/fa/expense',
  GET_EXPENSE: '/fa/expense',
  GET_CAPITAL: '/fa/capitalise',
  ADD_LOCATION: '/fa/location',
  GET_LOCATION: '/fa/location',
  ADD_CLASS: '/fa/asset-class',
  GET_CLASS: '/fa/asset-class',
  GET_CALCULATION: '/fa/calculation',
  GET_CALCULATION_WDV: '/fa/calculation-wdv',
  ADD_ASSET: "/fa/create",
  ADD_BULK_ASSET: '/fa/create',
  ADD_EXISTING_ASSET: '/fa/existing',
  FAASSET_UPDATE: '/fa/transfer',
  ADD_COST_CENTER: '/fa/cost-center',
  GET_COST_CENTER: '/fa/cost-center',
  GET_BU_CODE: '/fa/bucode',
  GET_FA_DASHBOARD: '/fa/dashboard',
  GET_VERIFICATION_COUNT: '/fa/verification-count',
  GET_VERIFICATION_DATA: '/fa/classwise-verified-count',
  GET_ADDITION_DELETION: '/fa/addition-deletion',
  GET_DEP_BY_CATEGORY: 'fa/accounting-graph',
  SALE_ASSET: '/FA/retire-sale',
  GET_SAMPLE_DATA: '/fa/sampling',
  GET_VERIFIED_LIST: '/fa/verification-list',
  GET_REPORT_DATA: '/fa/verification-report/',
  ADD_SAMPLE_LIST: "fa/save-sampling",
  GET_SAMPLE_RUN_DETAILS: '/fa/sampling-summary',
  GET_SAMPLES: '/fa/get-samples/',


  // Audit trail
  AUDIT_TRAIL_AMMS: "/audittrail",

   // Custom report builder 
   GET_COLLECTIONS: '/custom-report/collections',
   GET_ATTRIBUTES: '/custom-report/attributes',
   GET_QUERY: '/custom-report/v1/add-condition',
   GET_FIELD_NAME: '/custom-report/field-values',
   GET_TEMPLATE_REPORT:'/custom-report/get-report',
   GET_ASSETS_LIST:'/custom-report/list-of-assets/',
   GET_SINGLE_REPORT:'/custom-report/',
 
   SAVE_QUERY: '/custom-report/save-query',
   UPDATE_QUERY: '/custom-report/update-query/',
   DELETE_QUERY: '/custom-report/delete-query/',
   GET_QUERY_LIST: '/custom-report/get-query',
   GET_REPORT: `/custom-report/get-report`,
   DELETE_REPORT: `/custom-report/delete-report/`,
   SAVE_REPORT:'/custom-report/save-report',

  // ticket API
  TICKET_STATUS: "/ticket/trans/all/status", //status
  TICKET_REPORT: "/ticket/trans/report/", // ticket individual report
};
