/* Feature - Inspection create, update & view 

Created by - Mona R
Created at - 12th Aug 2024

*/
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Loader from "../../../components/loader/loader";
import { INSPECTION_ADD } from "../../../redux/actions/inspectionAction";
import "./inspectStyle.css";
import { INSPECTION_UPDATE } from "../../../redux/actions/inspectionAction";
import S3BucketImage from "../../report/pdfPreview/s3BucketImages";

const UploadInspection = () => {
  // Importing the useSearchParams hook from react-router-dom to work with query parameters in the URL
  const [queryParams] = useSearchParams();

  // Importing the useDispatch hook from react-redux to dispatch actions to the Redux store
  const dispatch = useDispatch();

  // Importing the useNavigate hook from react-router-dom to programmatically navigate between routes
  const navigate = useNavigate();

  // Extracting the 'id' parameter from the URL using the useParams hook from react-router-dom
  const { id } = useParams();

  // Using the useSelector hook from react-redux to access the state from the Redux store
  const isLoading = useSelector((state) => state.inspection.isLoading);
  const userid = useSelector((state) => state.auth.userid);
  const isSubmitted = useSelector((state) => state.inspection.isSubmitted);
  const accessOnDemand = useSelector(
    (state) => state.access.onDemandInspection
  );
  const inspGetSingle = useSelector((state) => state.inspection.inspGetSingle);
  const role = useSelector((state) => state.auth.role);

  // define useState :
  const [operation, setOperation] = useState("upload"); // to handle title and other CRUD Operation (upload, update & view)
  const [showhide, setShowhide] = useState(""); // to handle the view of priority boolean
  const [hrs, setHrs] = useState([]); // to set the hour for repeat hours frequency
  const [daysOfWeek, setDaysOfWeek] = useState([]); // to set the days of week for repeat days frequency
  const [key, setKey] = useState(""); // to manage the type of the inspection - asset, device or facility

  const today = new Date().toISOString().slice(0, 10); // Getting the current date in ISO format (YYYY-MM-DD) and slicing it to keep only the date part (YYYY-MM-DD)

  // console.log('imageName', imageName);
  // console.log('imageArray', imageArray);

  //Array of Hours - its used for repeat hourly frequency check box
  const numOfHours = [
    "00:00",
    "00:30",
    "01:00",
    "01:30",
    "02:00",
    "02:30",
    "03:00",
    "03:30",
    "04:00",
    "04:30",
    "05:00",
    "05:30",
    "06:00",
    "06:30",
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30",
  ];

  //Array of days - its used for repeat days frequency check box
  const numOfDays = [
    { key: 0, value: "Sun" },
    { key: 1, value: "Mon" },
    { key: 2, value: "Tue" },
    { key: 3, value: "Wed" },
    { key: 4, value: "Thu" },
    { key: 5, value: "Fri" },
    { key: 6, value: "Sat" },
  ];

  // Initializing the state with an object that contains various frequency-related properties, all set to empty strings or empty arrays
  const [calendarFreqncy, setCalendarFreqncy] = useState({
    type: "", // Type of calendar frequency (e.g., daily, hourly)
    type_value: "", // Specific value for the frequency type (e.g. 1, 2 etc..)
    mits_of_hrs: "", // Minutes or hours for the frequency
    hrs_of_day: [], // Array to hold specific hours of the day
    days_of_week: [], // Array to hold specific days of the week
    end_type: "", // Type of end condition for the frequency (e.g., never, on a specific date)
    occur_value: "", // Value for occurrences if end type is based on occurrences
    date_value: "", // Specific date value if end type is based on a date
  });

  // Initializing the state with an object that contains various schedule-related properties, all set to empty strings or empty arrays
  const [schedule, setSchedule] = useState({
    parent_mid: id,
    inspection_name: "",
    priority: "",
    based_on: "",
    managed_by: "",
    assignee_type: "",
    assignee: [],
    inspection_duration: "",
    man_power_required: "",
    man_hour_required: "",
    sla: "",
    sla_interval: "",
    frequency: calendarFreqncy,
    checkpoints: [],
  });

  //Radio button function to handle priority
  const handleShow = (e) => {
    const getshow = e.target.value;
    // console.log(getshow);
    setShowhide(getshow);
    setSchedule({ ...schedule, priority: e.target.value });
    setErrors((prevErrors) => ({ ...errors, priority: "" }));
  };

  //function to handle all type frequency. e.g. - daily, hourly,
  const handleFreqcy = (e) => {
    // console.log(e);
    setHrs([]);
    setDaysOfWeek([]);
    setSchedule((prevState) => {
      prevState.frequency.type = e;
      prevState.frequency.mits_of_hrs = [];
      prevState.frequency.hrs_of_day = [];
      prevState.frequency.days_of_week = [];
      return { ...prevState };
    });
    e === ("repeat_minutes" || "repeat_days" || "repeat_hours") &&
      setSchedule((prevState) => {
        prevState.frequency.type_value = "1";
        return { ...prevState };
      });
    setErrors((prevErrors) => ({ ...errors, frequency: "" }));
  };

  //function to handle frequnecy value
  const handleInsptnFreqcy = (e) => {
    setSchedule((prevState) => {
      const newState = { ...prevState };
      newState.frequency.type_value = e;
      return newState;
    });
    setErrors((prevErrors) => ({ ...prevErrors, frequencyValue: "" }));
  };

  // conver ISO time to UTC
  const convertToUTC = (time) => {
    const localTime = new Date("1970-01-01T" + time + ":00");
    const utcTime = localTime.toISOString().substr(11, 5);
    return utcTime;
  };

  // function to handle days of week in any repeat frequency
  const handleDay = (e) => {
    // console.log(e);
    const newDays = [...daysOfWeek];
    const index = newDays.indexOf(e);
    if (index === -1) {
      newDays.push(e);
    } else {
      newDays.splice(index, 1);
    }
    setDaysOfWeek(newDays.sort((a, b) => a - b));
    setSchedule((prevState) => {
      prevState.frequency.days_of_week = newDays.sort((a, b) => a - b);
      prevState.frequency.type_value = "";
      return { ...prevState };
    });
    // Clear the error if there is at least one hour selected
    if (newDays.length > 0) {
      setErrors((prevErrors) => ({ ...prevErrors, days_of_week: "" }));
    }
  };

  // function to handle hours of all the selected days in any repeat frequency
  const handlehr = (localTime) => {
    const utcTime = convertToUTC(localTime);
    const newHrs = [...hrs];
    const hours = newHrs.indexOf(utcTime);
    if (hours === -1) {
      newHrs.push(utcTime);
    } else {
      newHrs.splice(hours, 1);
    }
    // console.log(newHrs.sort((a, b) => new Date('1970-01-01T' + a) - new Date('1970-01-01T' + b)));
    setHrs(
      newHrs.sort(
        (a, b) => new Date("1970-01-01T" + a) - new Date("1970-01-01T" + b)
      )
    );
    setSchedule((prevState) => {
      prevState.frequency.hrs_of_day = newHrs.sort(
        (a, b) => new Date("1970-01-01T" + a) - new Date("1970-01-01T" + b)
      );
      prevState.frequency.type_value = "";
      return { ...prevState };
    });
    // Clear the error if there is at least one hour selected
    if (newHrs.length > 0) {
      setErrors((prevErrors) => ({ ...prevErrors, hrs_of_day: "" }));
    }
  };

  // function to handle frequnecy end type - No end, End After Occurences, End Date
  const handleFreqEnd = (e) => {
    const getshow = e.target.value;
    setSchedule((prevState) => {
      prevState.frequency.end_type = getshow;
      return { ...prevState };
    });
    setErrors((prevErrors) => ({ ...errors, endType: "" }));
  };

  // function handle answer type - dropdown
  const validateDropdownOptions = (checkpoints) => {
    let hasError = false;
    const errors = {};

    checkpoints.forEach((checkpoint, i) => {
      checkpoint.dropdown_array.forEach((option, optionIndex) => {
        if (option.trim() === "") {
          if (!errors[i]) errors[i] = [];
          errors[i][optionIndex] = "Option cannot be empty";
          hasError = true;
        } else if (errors[i]) {
          errors[i][optionIndex] = null;
        }
      });
    });

    return { hasError, errors };
  };

  const handleDropdownOptionChange = (e, i, optionIndex) => {
    const value = e.target.value;
    // console.log(value);
    setSchedule((prevState) => {
      prevState.checkpoints[i].dropdown_array[optionIndex] = value;
      return { ...prevState };
    });
    // Validate after change
    const { errors } = validateDropdownOptions(schedule.checkpoints);
    setErrors(errors); // Update errors state accordingly
    return schedule;
  };

  const addDropdownOption = (i) => {
    // console.log(schedule);
    setSchedule((prevState) => {
      prevState.checkpoints[i].dropdown_array.push("");
      return { ...prevState };
    });
    // Validate after adding new option
    const { errors } = validateDropdownOptions(schedule.checkpoints);
    setErrors(errors); // Update errors state accordingly
    return schedule;
  };

  const removeDropdownOption = (i, optionIndex) => {
    setSchedule((prevState) => {
      prevState.checkpoints[i].dropdown_array.splice(optionIndex, 1);
      return { ...prevState };
    });
    // Validate after removing option
    const { hasError, errors } = validateDropdownOptions(schedule.checkpoints);
    setErrors(errors); // Update errors state accordingly
    return schedule;
  };

  // function to add multiple task/checkpoints card
  const addInspChekPonts = () => {
    let newCpObj = {
      checkpoint: "",
      uom: "",
      standard_requirement: "",
      answer_type: "",
      dropdown_array: [],
      attachment: [],
    };
    let cpList = schedule.checkpoints;
    let updatedList = cpList.push(newCpObj);
    setSchedule({ ...schedule, updatedList });
    setErrors((prevErrors) => ({ ...errors, add_activies: "" }));
  };

  //function to delete Task
  const deleteInspChekPonts = (index) => {
    // Show a confirmation dialog
    if (window.confirm("Are you sure you wish to delete?")) {
      // If the user confirms, proceed with deletion
      let afterDelCp = [...schedule.checkpoints];
      afterDelCp.splice(index, 1);
      setSchedule({ ...schedule, checkpoints: afterDelCp });
    } else {
      // If the user cancels, do nothing
      // console.log("Deletion cancelled");
    }
  };

  // function to store attachments of inspection activities
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  ///////////////////////////////////// form validation  /////////////////////////////////////////////
  const [errors, setErrors] = useState({
    name: "",
    priority: "",
    basedOn: "",
    managedBy: "",
    frequency: "",
    add_activies: "",
    checkpoint: [],
    stdReq: [],
    ansTyp: [],
    dropdownOptions: [],
    hrs_of_day: [],
    days_of_week: [], //["Sun", "Mon", "Tue", ....]
    frequencyValue: "",
    endType: "",
    occur_value: "",
    date_value: "",
  });
  const formValidation = () => {
    const newErrors = {
      name: "",
      priority: "",
      basedOn: "",
      managedBy: "",
      frequency: "",
      add_activies: "",
      checkpoint: new Array(schedule?.checkpoints?.length).fill(""),
      stdReq: new Array(schedule?.checkpoints?.length).fill(""),
      ansTyp: new Array(schedule?.checkpoints?.length).fill(""),
      dropdownOptions: new Array(schedule?.checkpoints?.length).fill(""),
      hrs_of_day: new Array(schedule?.frequency?.hrs_of_day?.length).fill(""),
      days_of_week: new Array(schedule?.frequency?.days_of_week?.length).fill(""), //["Sun", "Mon", "Tue", ....]
      frequencyValue: "",
      endType: "",
    };
    let formIsValid = true;

    if (!schedule.inspection_name) {
      newErrors.name = "Please Enter Inspection Name";
      formIsValid = false;
    }
    if (!schedule.priority) {
      newErrors.priority = "Please Choose Priority";
      formIsValid = false;
    }
    if (!schedule.based_on) {
      newErrors.basedOn = "Please Choose Based on";
      formIsValid = false;
    }
    if (!schedule.managed_by) {
      newErrors.managedBy = "Please Choose Managed by";
      formIsValid = false;
    }
    if (schedule.based_on === "calendar" && !schedule.frequency?.type) {
      newErrors.frequency = "Please Choose Frequency";
      formIsValid = false;
    }
    if (
      schedule.based_on === "calendar" &&
      !schedule.frequency?.type_value &&
      schedule?.frequency?.type !== "repeat_minutes" &&
      schedule.frequency.type !== "repeat_hours" &&
      schedule.frequency.type !== "repeat_days"
    ) {
      newErrors.frequencyValue = "Please Enter Frequency Value";
      formIsValid = false;
    }
    if (schedule.based_on === "calendar" && !schedule.frequency?.end_type) {
      newErrors.endType = "Please Choose Inspection End Type";
      formIsValid = false;
    }
    if (
      schedule.frequency?.end_type === "end_after_occurence" &&
      !schedule.frequency?.occur_value
    ) {
      newErrors.endType = "Please Enter Occurence Value";
      formIsValid = false;
    }
    if (
      schedule.frequency?.end_type === "end_date" &&
      !schedule.frequency?.date_value
    ) {
      newErrors.endType = "Please Enter End Date";
      formIsValid = false;
    }
    if (
      (schedule.frequency.type === "repeat_days" ||
        schedule.frequency.type === "repeat_hours") &&
      schedule.frequency.days_of_week.length === 0
    ) {
      newErrors.days_of_week = "Please Select Atleast One Day of Week";
      formIsValid = false;
    }
    if (
      schedule.frequency.type === "repeat_hours" &&
      schedule.frequency.hrs_of_day.length === 0
    ) {
      newErrors.hrs_of_day = "Please Select Atleast One Hour of Day";
      formIsValid = false;
    }
    if (schedule.checkpoints?.length === 0) {
      newErrors.add_activies = "Please Add Checkpoint";
      formIsValid = false;
    }
    schedule.checkpoints.forEach((cp, i) => {
      if (!cp.checkpoint) {
        newErrors.checkpoint[i] = "Please Enter Checkpoint";
        formIsValid = false;
      }
      if (!cp.standard_requirement) {
        newErrors.stdReq[i] = "Please Enter Standard Requirement";
        formIsValid = false;
      }
      if (!cp.answer_type) {
        newErrors.ansTyp[i] = "Please Choose Answer Type";
        formIsValid = false;
      }
      if (cp.answer_type === "dropdown") {
        if (cp.dropdown_array.length === 0) {
          newErrors.ansTyp[i] = "Please Add At Least One Dropdown Option";
          formIsValid = false;
        }
      }
      // Validate individual dropdown options
      const emptyOptions = cp.dropdown_array.filter(
        (option) => option.trim() === ""
      );
      if (emptyOptions.length > 0) {
        newErrors.dropdownOptions[i] = "Please Fill All Dropdown Options";
        formIsValid = false;
      }
    });

    setErrors(newErrors);
    return formIsValid;
  };
  const submitInspChekPonts = (e) => {
    e.preventDefault();
    // Perform form validation and check if the form is valid
    const isFormValid = formValidation();
    //  function to create the inspection
    // console.log(isFormValid);

    if (isFormValid && operation === "upload") {
      let scheduleData = {
        parent_mid: schedule.parent_mid,
        inspection_name: schedule.inspection_name,
        priority: schedule.priority,
        based_on: schedule.based_on,
        managed_by: schedule.managed_by,
        assignee: schedule.assignee,
        inspection_duration: schedule.inspection_duration?Number(schedule.inspection_duration):null,
        man_power_required: schedule.man_power_required?Number(schedule.man_power_required):null,
        man_hour_required: schedule.man_hour_required?Number(schedule.man_hour_required):null,
        sla: schedule.sla !== "" ? Number(schedule.sla) : null,
        sla_interval: schedule.sla_interval,
        type: schedule.type,
        frequency: {
          frequency_type: schedule.frequency.type,
          frequency_type_value: schedule.frequency.type_value ? Number(schedule.frequency.type_value) : null,
          frequency_minutes_of_hours: schedule.frequency.mits_of_hrs,
          frequency_hours_of_day: schedule.frequency.hrs_of_day,
          frequency_days_of_week: schedule.frequency.days_of_week,
          frequency_end_type: schedule.frequency.end_type,
          frequency_end_occurence_value:
            schedule.frequency.occur_value !== ""
              ? schedule.frequency.occur_value
              : undefined,
          frequency_end_date_value:
            schedule.frequency.date_value !== ""
              ? schedule.frequency.date_value
              : undefined,
        },
        checkpoints: schedule.checkpoints,
        inspection_status: "yet_to_be_assigned",
        created_by: userid,
      };
      // console.log(scheduleData);
      dispatch({
        type: INSPECTION_ADD,
        payload: [queryParams.get("type"), scheduleData],
      });
    }
    // function to update the inspection
    if (isFormValid && operation === "update") {
      let updateInspData = {
        parent_mid: inspGetSingle.parent_mid,
        inspection_name: schedule.inspection_name,
        priority: schedule.priority,
        based_on: schedule.based_on,
        managed_by: schedule.managed_by,
        assignee: schedule.assignee,
        inspection_duration: schedule.inspection_duration?Number(schedule.inspection_duration):null,
        man_power_required: schedule.man_power_required?Number(schedule.man_power_required):null,
        man_hour_required: schedule.man_hour_required?Number(schedule.man_hour_required):null,
        sla: schedule.sla !== "" ? Number(schedule.sla) : null,
        sla_interval: schedule.sla_interval,
        type: schedule.type,
        frequency:
          schedule.based_on === "on_demand"
            ? calendarFreqncy
            : {
              frequency_type: schedule.frequency.type,
              frequency_type_value: schedule.frequency.type_value ? Number(schedule.frequency.type_value) : null,
              frequency_end_type: schedule.frequency.end_type,
              // frequency_end_occurence_value: schedule.frequency.occur_value,
              // frequency_end_date_value: schedule.frequency.date_value,
              frequency_end_occurence_value:
                schedule.frequency.occur_value !== ""
                  ? schedule.frequency.occur_value
                  : undefined,
              frequency_end_date_value:
                schedule.frequency.date_value !== ""
                  ? schedule.frequency.date_value
                  : undefined,
              frequency_days_of_week:
                daysOfWeek.length > 0
                  ? daysOfWeek
                  : inspGetSingle?.frequency?.frequency_days_of_week,
              frequency_hours_of_day: schedule.frequency.hrs_of_day,
              frequency_minutes_of_hours: schedule.frequency.mits_of_hrs,
            },
        checkpoints: schedule.checkpoints,
        inspection_status: "yet_to_be_assigned",
        created_by: userid,
        modified_by: userid,
      };
      // console.log("updateInspData", updateInspData);
      // console.log("type", key);
      dispatch({
        type: INSPECTION_UPDATE,
        payload: [updateInspData, id, key],
      });
    }
  };
  useState(() => {
    const currentURL = window.location.href;
    // console.log(currentURL);
    if (currentURL.includes("upload-inspection")) {
      setOperation("upload");
    }
    if (currentURL.includes("view")) {
      setOperation("view");
    }
    if (currentURL.includes("update")) {
      setOperation("update");
    }
  }, [id]);

  // console.log(operation, "operation");

  useEffect(() => {
    if (inspGetSingle !== null && operation !== "upload") {
      // console.log("inspGetSingle", inspGetSingle);
      setSchedule({
        parent_mid: inspGetSingle.parent_mid,
        inspection_name: inspGetSingle.inspection_name,
        priority: inspGetSingle.priority,
        based_on: inspGetSingle.based_on,
        managed_by: inspGetSingle.managed_by,
        assignee: inspGetSingle.assignee,
        inspection_duration: inspGetSingle.inspection_duration,
        man_power_required: inspGetSingle.man_power_required,
        man_hour_required: inspGetSingle.man_hour_required,
        sla: inspGetSingle.sla,
        sla_interval: inspGetSingle.sla_interval,
        type: inspGetSingle?.type,
        frequency: {
          type: inspGetSingle?.frequency?.frequency_type, //"months",
          type_value: inspGetSingle?.frequency?.frequency_type_value,
          end_type: inspGetSingle?.frequency?.frequency_end_type,
          occur_value: inspGetSingle?.frequency?.frequency_end_occurence_value,
          date_value: inspGetSingle?.frequency?.frequency_end_date_value,
          days_of_week: inspGetSingle?.frequency?.frequency_days_of_week,
          hrs_of_day: inspGetSingle?.frequency?.frequency_hours_of_day,
          mits_of_hrs: inspGetSingle?.frequency?.frequency_minutes_of_hours,
        },
        checkpoints: inspGetSingle.checkpoints,
      });
      setShowhide(inspGetSingle.priority);
      // console.log(inspGetSingle?.frequency?.frequency_days_of_week, "data");
    }
  }, [inspGetSingle, id]);

  //useEffects
  useEffect(() => {
    //fetch inspection Mongo ID
    if (id !== null && (operation === "view" || operation === "update")) {
      dispatch({
        type: "INSPECTION_GET_SINGLE",
        payload: id,
      });
    }
  }, [id]);

  // useEffect
  useEffect(() => {
    if (isSubmitted === true) {
      navigate("/inspectionlist", { state: { key } });
    }
  }, [isSubmitted]);

  useEffect(() => {
    // console.log(queryParams.get("type"));
  }, [queryParams]);

  useEffect(() => {
    const roleCode = role[0];
    if (accessOnDemand === null && roleCode !== null) {
      dispatch({
        type: "ACCESS_GET_LIST",
        payload: roleCode,
      });
    }
  }, [accessOnDemand]);

  useEffect(() => {
    if (queryParams.get("type")) {
      setKey(queryParams.get("type"));
    }
  }, [queryParams.get("type")]);

  // //functions for Character Validation
  // function nameChars(str) {
  //   const specialChars = /^([a-zA-Z0-9-/]+\s)*[a-zA-Z0-9-/]+$/;
  //   return specialChars.test(str);
  // }
  // function timeChars(str) {
  //   if (str === null) {
  //     return true;
  //   }
  //   const integers = /^[1-9][0-9]{0,9}$/;
  //   return integers.test(str);
  // }

  return (
    <div>
      {isLoading && <Loader />}
      <div className="container">
        <div className="row">
          <div className="d-flex">
            <div className="col-md-6">
              <form className="mt-5 border p-4 bg-light shadow rounded">
                {/* Add Schedule form starts */}
                <h3 style={{ textAlign: "center" }}>
                  {operation === "upload"
                    ? "Create Inspection"
                    : operation === "view"
                      ? "View Inspection"
                      : operation === "update"
                        ? "Edit Inspection"
                        : ""}
                </h3>
                <div>
                  <div className="mb-2 col-md-12">
                    <label>
                      Inspection Name
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="inspectionname"
                      className="form-control"
                      placeholder="Inspection Name"
                      value={schedule?.inspection_name}
                      onChange={(e) => {
                        setSchedule({
                          ...schedule,
                          inspection_name: e.target.value,
                        });
                        setErrors((prevErrors) => ({ ...errors, name: "" }));
                      }}
                      disabled={operation === "view" ? true : false}
                    />
                    {errors.name && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {errors.name}
                      </div>
                    )}
                    {/* {nameChars(schedule.inspection_name) ||
                    schedule.inspection_name === ""
                      ? " "
                      : (formValidation(),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Inspection Name
                          </div>
                        ))} */}
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>
                      Priority
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="radioButtons"
                      style={{ marginLeft: "25px" }}
                      type="radio"
                      name="asset"
                      value="low"
                      checked={showhide === "low" ? true : false}
                      onClick={handleShow}
                      disabled={operation === "view" ? true : false}
                    />
                    &nbsp;Low
                    <input
                      className="radioButtons"
                      style={{ marginLeft: "25px" }}
                      type="radio"
                      name="asset"
                      value="medium"
                      checked={showhide === "medium" ? true : false}
                      onClick={handleShow}
                      disabled={operation === "view" ? true : false}
                    />
                    &nbsp; Medium
                    <input
                      className="radioButtons"
                      style={{ marginLeft: "25px" }}
                      type="radio"
                      name="asset"
                      value="high"
                      checked={showhide === "high" ? true : false}
                      onClick={handleShow}
                      disabled={operation === "view" ? true : false}
                    />
                    &nbsp; High
                    {errors.priority && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {errors.priority}
                      </div>
                    )}
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>
                      Based on
                      <span className="text-danger">*</span>
                    </label>
                    {accessOnDemand?.c === true ? (
                      <select
                        name="based_on"
                        className="form-select"
                        value={schedule.based_on}
                        disabled={operation === "view" ? true : false}
                        onChange={(e) => {
                          setSchedule({
                            ...schedule,
                            based_on: e.target.value,
                          });
                          setErrors((prevErrors) => ({
                            ...errors,
                            basedOn: "",
                          }));
                        }}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="calendar">Calendar</option>
                        <option value="on_demand">On Demand</option>
                      </select>
                    ) : (
                      <select
                        name="based_on"
                        className="form-select"
                        value={schedule.based_on}
                        onChange={(e) => {
                          setSchedule({
                            ...schedule,
                            based_on: e.target.value,
                          });
                          setErrors((prevErrors) => ({
                            ...errors,
                            basedOn: "",
                          }));
                        }}
                        disabled={operation === "view" ? true : false}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="calendar">Calendar</option>
                      </select>
                    )}
                    {errors.basedOn && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {errors.basedOn}
                      </div>
                    )}
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>
                      Managed by
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      name="managed_by"
                      className="form-select"
                      value={schedule.managed_by}
                      disabled={operation === "view" ? true : false}
                      onChange={(e) => {
                        setSchedule({
                          ...schedule,
                          managed_by: e.target.value,
                        });
                        setErrors((prevErrors) => ({
                          ...errors,
                          managedBy: "",
                        }));
                      }}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="inhouse">In house</option>
                      <option value="external">External</option>
                    </select>
                    {errors.managedBy && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {errors.managedBy}
                      </div>
                    )}
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>Inspection Duration(Mins)</label>
                    <input
                      type="text"
                      min="0"
                      name="duration"
                      className="form-control"
                      maxLength="100"
                      placeholder="Minutes"
                      value={schedule.inspection_duration}
                      disabled={operation === "view" ? true : false}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value)) {
                          setSchedule({
                            ...schedule,
                            inspection_duration: e.target.value,
                          });
                        }
                      }
                      }
                    />
                    {/* {timeChars(schedule.inspection_duration) ||
                    schedule.inspection_duration === ""
                      ? " "
                      : (formValidation(),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Time
                          </div>
                        ))} */}
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>Total man power required</label>
                    <input
                      min="0"
                      name="man_power"
                      className="form-control"
                      placeholder="Total man power"
                      value={schedule.man_power_required}
                      disabled={operation === "view" ? true : false}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value)) {
                          setSchedule({
                            ...schedule,
                            man_power_required: e.target.value,
                          })
                        }
                      }
                      }
                    />
                    {/* {timeChars(schedule.man_power_required) ||
                    schedule.man_power_required === ""
                      ? " "
                      : (formValidation(),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Required man power
                          </div>
                        ))} */}
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>Total man hour required</label>
                    <input
                      min="0"
                      name="man_hour"
                      maxLength="1000"
                      className="form-control"
                      placeholder="Total man hour"
                      value={schedule.man_hour_required}
                      disabled={operation === "view" ? true : false}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value)) {
                          setSchedule({
                            ...schedule,
                            man_hour_required: e.target.value,
                          })
                        }
                      }}
                    />
                    {/* {timeChars(schedule.man_hour_required) ||
                    schedule.man_hour_required === ""
                      ? " "
                      : (formValidation(),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Required time
                          </div>
                        ))} */}
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>SLA</label>
                    <input
                      // type="text"
                      min={1}
                      name="man_hour"
                      className="form-control"
                      placeholder="Total man hour"
                      value={schedule.sla}
                      disabled={operation === "view" ? true : false}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value)) {
                          setSchedule({
                            ...schedule,
                            sla: Number(e.target.value),
                          })
                        }
                      }}
                    />
                    {/* {timeChars(schedule.sla) || schedule.sla === ""
                      ? " "
                      : (formValidation(),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid SLA
                          </div>
                        ))} */}
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>SLA Interval</label>
                    <select
                      name="sla_interval"
                      className="form-select"
                      value={schedule.sla_interval}
                      disabled={operation === "view" ? true : false}
                      onChange={(e) =>
                        setSchedule({
                          ...schedule,
                          sla_interval: e.target.value,
                        })
                      }
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="hours">Hour</option>
                      <option value="minutes">Minute</option>
                    </select>
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>Type</label>
                    <select
                      name="type"
                      className="form-select"
                      value={schedule.type}
                      disabled={operation === "view" ? true : false}
                      onChange={(e) =>
                        setSchedule({
                          ...schedule,
                          type: e.target.value,
                        })
                      }
                    >
                      <option value="">Select</option>
                      <option value="water">Water Consumption</option>
                      <option value="lpg">LPG Consumption</option>
                      <option value="electric_energy_grid">
                        Electric Energy - Grid
                      </option>
                      <option value="electric_energy_dg">
                        Electric Energy - DG
                      </option>
                      <option value="diesel">Diesel Consumption</option>
                      <option value="steam">Steam Consumption</option>
                      <option value="compressed_air">
                        Compressed Air Consumption
                      </option>
                      <option value="chilled_water">
                        Chilled Water Consumption
                      </option>
                      <option value="other">Other Consumption</option>
                    </select>
                  </div>
                  <button
                    className="backButton m-2"
                    type="button"
                    onClick={() =>
                      navigate(
                        operation === "upload"
                          ? "/assetlist"
                          : "/inspectionlist",
                        { state: { key } }
                      )
                    }
                  >
                    Back
                  </button>
                </div>
              </form>
            </div>

            {/* Calender Based && Inspection*/}
            {schedule.based_on === "calendar" && (
              <div className="mx-2 col-md-12">
                <div className="col-md-12">
                  <form className="mt-5 border p-4 bg-light shadow rounded col-md-6">
                    <div className="mb-2 col-md-12">
                      <h5>Calendar based on </h5>
                      <label>
                        Frequency
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        name="frequency_type"
                        className="form-select"
                        value={schedule.frequency.type}
                        disabled={operation === "view" ? true : false}
                        onChange={(e) => {
                          handleFreqcy(e.target.value);
                        }}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="daily">Daily</option>
                        <option value="hourly">Hourly</option>
                        <option value="minutes">Minutes</option>
                        {/* <option value="repeat_minutes">Repeat Minutes</option> */}
                        <option value="repeat_hours">Repeat Hours</option>
                        <option value="repeat_days">Repeat Days</option>
                      </select>
                      {errors.frequency && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {errors.frequency}
                        </div>
                      )}
                      {/* Frequency type === Daily & Hourly */}
                      {(schedule.frequency.type === "daily" ||
                        schedule.frequency.type === "hourly" ||
                        schedule.frequency.type === "minutes") && (
                          <div className="mt-3">
                            <label>Frequency value</label>
                            <span className="text-danger">*</span>
                            <input
                              type="number"
                              style={{
                                marginTop: 5,
                                height: 35,
                                paddingLeft: "10px",
                                borderRadius: 5,
                                border: "1px solid #ccc",
                              }}
                              min={1}
                              required
                              className="col-md-12"
                              placeholder="Number of days"
                              value={schedule.frequency.type_value}
                              disabled={operation === "view" ? true : false}
                              onChange={(e) =>
                                handleInsptnFreqcy(Number(e.target.value))
                              }
                            />
                            {/* {timeChars(schedule.frequency.type_value) ||
                          schedule.frequency.type_value === ""
                            ? " "
                            : (formValidation(),
                              (
                                <div
                                  style={{
                                    fontSize: "12px",
                                    color: "red",
                                    paddingTop: "2px",
                                  }}
                                >
                                  Invalid Frequency type
                                </div>
                              ))} */}
                            {errors.frequencyValue && (
                              <div style={{ color: "red", fontSize: "12px" }}>
                                {errors.frequencyValue}
                              </div>
                            )}
                          </div>
                        )}
                      {/* Frequency type === Repeat Minutes */}
                      {schedule?.frequency?.type === "repeat_minutes" && (
                        <div className="mt-3">
                          <br></br>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {numOfDays?.map((dayVal, index) => (
                              <div key={dayVal.key}>
                                <input
                                  className="daysAlign"
                                  value={dayVal.value}
                                  type="checkbox"
                                  onChange={(e) => handleDay(dayVal.key)}
                                  disabled={operation === "view" ? true : false}
                                  checked={schedule.frequency.days_of_week.includes(
                                    dayVal.key
                                  )}
                                />
                                <label
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                    marginLeft: "3px",
                                    marginTop: '22px',
                                  }}
                                >
                                  {dayVal.value}
                                </label>
                              </div>
                            ))}
                            <br></br>
                            <br></br>
                            <br></br>
                            <table>
                              <tbody>
                                {numOfHours
                                  .reduce((rows, time, index) => {
                                    if (index % 5 === 0) rows.push([]);
                                    rows[rows.length - 1].push(time);
                                    return rows;
                                  }, [])
                                  ?.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                      {row?.map((time, cellIndex) => (
                                        <>
                                          <td
                                            key={cellIndex}
                                            style={{ verticalAlign: "middle" }}
                                          >
                                            <div>
                                              <input
                                                className="hoursAlign"
                                                value={time}
                                                type="checkbox"
                                                disabled={
                                                  operation === "view"
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) =>
                                                  handlehr(e.target.value)
                                                }
                                                checked={schedule.frequency.mits_of_hrs
                                                  ?.map((utcTime) => {
                                                    const utcDate = new Date(
                                                      `1970-01-01T${utcTime}:00Z`
                                                    );
                                                    const localHours = utcDate
                                                      .getHours()
                                                      .toString()
                                                      .padStart(2, "0");
                                                    const localMinutes = utcDate
                                                      .getMinutes()
                                                      .toString()
                                                      .padStart(2, "0");
                                                    return `${localHours}:${localMinutes}`;
                                                  })
                                                  .includes(time)}
                                              />
                                              <label
                                                style={{
                                                  fontSize: "13px",
                                                  verticalAlign: "middle",
                                                  marginLeft: "3px",
                                                  marginTop: '22px',
                                                }}
                                              >
                                                {time}
                                              </label>
                                            </div>
                                          </td>
                                        </>
                                      ))}
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                      {/* Frequency type === Repeat Days */}
                      {schedule.frequency.type === "repeat_days" && (
                        <div className="mt-3">
                          <br></br>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {numOfDays?.map((dayVal, index) => (
                              <div key={dayVal.key}>
                                <input
                                  className="daysAlign"
                                  value={dayVal.value}
                                  type="checkbox"
                                  onChange={(e) => handleDay(dayVal.key)}
                                  disabled={operation === "view" ? true : false}
                                  checked={schedule.frequency.days_of_week.includes(
                                    dayVal.key
                                  )}
                                />
                                <label
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                    marginLeft: "3px",
                                    marginTop: '22px',
                                  }}
                                >
                                  {dayVal.value}
                                </label>
                              </div>
                            ))}
                          </div>
                          {errors.days_of_week && (
                            <div style={{ color: "red", fontSize: "12px" }}>
                              {errors.days_of_week}
                            </div>
                          )}
                        </div>
                      )}
                      {/* Frequency type === Repeat Hours */}
                      {schedule.frequency.type === "repeat_hours" && (
                        <div className="mt-3">
                          <br></br>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {numOfDays?.map((dayVal, index) => (
                              <div key={dayVal.key}>
                                <input
                                  className="daysAlign"
                                  value={dayVal.value}
                                  type="checkbox"
                                  onChange={(e) => handleDay(dayVal.key)}
                                  disabled={operation === "view" ? true : false}
                                  checked={schedule.frequency.days_of_week.includes(
                                    dayVal.key
                                  )}
                                />
                                <label
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                    marginLeft: "3px",
                                    marginTop: '22px',
                                  }}
                                >
                                  {dayVal.value}
                                </label>
                              </div>
                            ))}
                            {errors.days_of_week && (
                              <div style={{ color: "red", fontSize: "12px" }}>
                                {errors.days_of_week}
                              </div>
                            )}
                            <br></br>
                            <br></br>
                            <br></br>
                            <table>
                              <tbody>
                                {numOfHours
                                  .reduce((rows, time, index) => {
                                    if (index % 5 === 0) rows.push([]);
                                    rows[rows.length - 1].push(time);
                                    return rows;
                                  }, [])
                                  ?.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                      {row?.map((time, cellIndex) => (
                                        <>
                                          <td
                                            key={cellIndex}
                                            style={{ verticalAlign: "middle" }}
                                          >
                                            <div>
                                              <input
                                                className="hoursAlign"
                                                value={time}
                                                type="checkbox"
                                                onChange={(e) =>
                                                  handlehr(e.target.value)
                                                }
                                                checked={schedule.frequency.hrs_of_day
                                                  ?.map((utcTime) => {
                                                    const utcDate = new Date(
                                                      `1970-01-01T${utcTime}:00Z`
                                                    );
                                                    const localHours = utcDate
                                                      .getHours()
                                                      .toString()
                                                      .padStart(2, "0");
                                                    const localMinutes = utcDate
                                                      .getMinutes()
                                                      .toString()
                                                      .padStart(2, "0");
                                                    return `${localHours}:${localMinutes}`;
                                                  })
                                                  .includes(time)}
                                                disabled={
                                                  operation === "view"
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label
                                                style={{
                                                  fontSize: "13px",
                                                  verticalAlign: "middle",
                                                  marginLeft: "3px",
                                                  marginTop: '22px',
                                                }}
                                              >
                                                {time}
                                              </label>
                                            </div>
                                          </td>
                                        </>
                                      ))}
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                          {errors.hrs_of_day && (
                            <div style={{ color: "red", fontSize: "12px" }}>
                              {errors.hrs_of_day}
                            </div>
                          )}
                        </div>
                      )}

                      {/* Schedule End types */}
                      <div className="mt-4 md-6">
                        <label>Inspection End Type</label>
                        <span className="text-danger">*</span>
                        <div>
                          <input
                            className="radioButtons"
                            style={{ marginLeft: "25px", margin: "8px" }}
                            type="radio"
                            value="no_end"
                            name="endType"
                            checked={
                              schedule.frequency.end_type === "no_end"
                                ? true
                                : false
                            }
                            onClick={handleFreqEnd}
                            disabled={operation === "view" ? true : false}
                          />
                          No End<br></br>
                          <input
                            className="radioButtons"
                            style={{ marginLeft: "25px", margin: "8px" }}
                            type="radio"
                            value="end_after_occurence"
                            checked={
                              schedule.frequency.end_type ===
                                "end_after_occurence"
                                ? true
                                : false
                            }
                            name="endType"
                            onClick={handleFreqEnd}
                            disabled={operation === "view" ? true : false}
                          />
                          End After Occurences
                          {schedule.frequency.end_type ===
                            "end_after_occurence" && (
                              <input
                                type="number"
                                style={{
                                  marginLeft: 10,
                                  border: "1px solid",
                                  borderRadius: 5,
                                  margin: "8px",
                                }}
                                min={1}
                                placeholder="Occurences"
                                disabled={operation === "view" ? true : false}
                                value={schedule.frequency.occur_value}
                                onChange={(e) => {
                                  setSchedule((prevState) => {
                                    prevState.frequency.occur_value =
                                      e.target.value;
                                    return { ...prevState };
                                  });
                                  setSchedule((prevState) => {
                                    prevState.frequency.date_value = "";
                                    return { ...prevState };
                                  });
                                }}
                              />
                            )}
                          {errors.occur_value && (
                            <div style={{ color: "red", fontSize: "12px" }}>
                              {errors.occur_value}
                            </div>
                          )}
                          <br></br>
                          <input
                            className="radioButtons"
                            style={{ marginLeft: "25px", margin: "8px" }}
                            type="radio"
                            value="end_date"
                            name="endType"
                            checked={
                              schedule.frequency.end_type === "end_date"
                                ? true
                                : false
                            }
                            onClick={handleFreqEnd}
                            disabled={operation === "view" ? true : false}
                          />
                          End Date
                          {schedule.frequency.end_type === "end_date" && (
                            <input
                              style={{
                                marginLeft: 10,
                                width: 190,
                                border: "1px solid",
                                borderRadius: 5,
                                height: 30,
                              }}
                              value={
                                schedule.frequency.date_value
                                  ? new Date(schedule.frequency.date_value)
                                    .toISOString()
                                    .split("T")[0]
                                  : ""
                              }
                              type="date"
                              placeholdertext={"Please Select a Date"}
                              disabled={operation === "view" ? true : false}
                              onChange={(e) => {
                                setSchedule((prevState) => {
                                  prevState.frequency.date_value =
                                    e.target.value;
                                  return { ...prevState };
                                });
                                setSchedule((prevState) => {
                                  prevState.frequency.occur_value = "";
                                  return { ...prevState };
                                });
                              }}
                              min={today}
                            />
                          )}
                          {errors.date_value && (
                            <div style={{ color: "red", fontSize: "12px" }}>
                              {errors.date_value}
                            </div>
                          )}
                          {errors.endType && (
                            <div style={{ color: "red", fontSize: "12px" }}>
                              {errors.endType}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>

          {/* Tasks */}
          {schedule?.checkpoints?.map((cards, i) => (
            <div key={i} className="mt-4 col-md-8 mb-2">
              <div className="mt-4 p-4 bg-light shadow rounded">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* Inspection Checkpoints */}
                  <h5>Task number : {i + 1}</h5>
                  <button className="delIcons float-end" type="button">
                    <FontAwesomeIcon
                      size="lg"
                      icon={faTrash}
                      onClick={() => deleteInspChekPonts(i)}
                    />
                  </button>
                </div>
                <div className="card-body">
                  <div className="col-md-12 p-2">
                    <label>
                      Checkpoint
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="checkpoint"
                      className="form-control"
                      maxLength="200"
                      placeholder="Enter Checkpoint"
                      value={cards.checkpoint}
                      disabled={operation === "view" ? true : false}
                      onChange={(e) => {
                        setSchedule((prevState) => {
                          prevState.checkpoints[i].checkpoint = e.target.value;
                          return { ...prevState };
                        });
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          checkpoint: prevErrors.checkpoint?.map((error, idx) =>
                            idx === i ? "" : error
                          ),
                        }));
                      }}
                    />
                    {errors.checkpoint && errors.checkpoint[i] && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {errors.checkpoint[i]}
                      </div>
                    )}
                  </div>
                  <div className="col-md-12 p-2">
                    <label>UoM</label>
                    <input
                      type="text"
                      name="uom"
                      className="form-control"
                      maxLength="30"
                      placeholder="Enter UoM"
                      value={cards.uom}
                      disabled={operation === "view" ? true : false}
                      onChange={(e) => {
                        setSchedule((prevState) => {
                          prevState.checkpoints[i].uom = e.target.value;
                          return { ...prevState };
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-12 p-2">
                    <label>
                      Standard Requirement
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="std_desc"
                      className="form-control"
                      maxLength="100"
                      required
                      placeholder="Enter Standard Requirement"
                      value={cards.standard_requirement}
                      disabled={operation === "view" ? true : false}
                      onChange={(e) => {
                        setSchedule((prevState) => {
                          prevState.checkpoints[i].standard_requirement =
                            e.target.value;
                          return { ...prevState };
                        });
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          stdReq: prevErrors.stdReq?.map((error, idx) =>
                            idx === i ? "" : error
                          ),
                        }));
                      }}
                    />
                    {errors.stdReq && errors.stdReq[i] && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {errors.stdReq[i]}
                      </div>
                    )}
                  </div>
                  <div className="col-md-12 p-2">
                    <label>
                      Answer Type
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      required
                      name="ans_type"
                      className="form-select"
                      value={cards.answer_type}
                      disabled={operation === "view" ? true : false}
                      onChange={(e) => {
                        const newAnswerType = e.target.value;
                        setSchedule((prevState) => {
                          // Create a copy of the previous state
                          const newState = { ...prevState };
                          // Update the answer type
                          newState.checkpoints[i].answer_type = newAnswerType;
                          // Clear the dropdown array if the new answer type is not dropdown
                          if (newAnswerType !== "dropdown") {
                            newState.checkpoints[i].dropdown_array = [];
                          }
                          return newState;
                        });
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          ansTyp: prevErrors?.ansTyp?.map((error, idx) =>
                            idx === i ? "" : error
                          ),
                          dropdownOptions: prevErrors?.dropdownOptions?.map(
                            (error, idx) => (idx === i ? "" : error)
                          ),
                        }));
                      }}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="yes_no_na">
                        Yes / No / Not Applicable
                      </option>
                      <option value="value">Value</option>
                      <option value="dropdown">Dropdown</option>
                    </select>
                    {cards?.answer_type === "dropdown" && (
                      <div>
                        <br />
                        <label>Dropdown Options:</label>{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <button
                          className="delIcons"
                          onClick={() => addDropdownOption(i)}
                        >
                          <FontAwesomeIcon size="lg" icon={faPlus} />
                        </button>
                        {cards?.dropdown_array?.map((option, optionIndex) => (
                          <div
                            key={optionIndex}
                            className="option-container"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              gap: "10px",
                              marginTop: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {optionIndex + 1}.{" "}
                            </div>
                            <input
                              type="text"
                              name="checkpoint"
                              className="form-control"
                              maxLength="200"
                              placeholder="Enter Option"
                              value={option}
                              disabled={operation === "view" ? true : false}
                              onChange={(e) =>
                                handleDropdownOptionChange(e, i, optionIndex)
                              }
                            />
                            <button
                              className="delIcons float-end"
                              onClick={() =>
                                removeDropdownOption(i, optionIndex)
                              }
                            >
                              <FontAwesomeIcon size="lg" icon={faTrash} />
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                    {errors.ansTyp && errors.ansTyp[i] && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {errors.ansTyp[i]}
                      </div>
                    )}
                    {errors.dropdownOptions && errors.dropdownOptions[i] && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {errors.dropdownOptions[i]}
                      </div>
                    )}
                  </div>
                  <div className="col-md-12 p-2 mb-4">
                    <label>Attachment</label>
                    <input
                      className="form-control"
                      label="attachments"
                      type="file"
                      // value={cards.attachments}
                      multiple
                      disabled={operation === "view" ? true : false}
                      onChange={async (e) => {
                        const files = e.target.files;
                        const convertedImages = [];
                        // Iterate through the selected files
                        for (let i = 0; i < files.length; i++) {
                          const base64String = await convertToBase64(files[i]);
                          convertedImages.push(base64String);
                        }
                        // console.log(convertedImages);
                        setSchedule((prevState) => {
                          prevState.checkpoints[i].attachment = convertedImages;
                          return { ...prevState };
                        });
                      }}
                      accept=".jpeg,.jpg,.png"
                    ></input>
                    <div>
                      *Only one file of type .jpeg, .jpg, or .png is allowed.
                    </div>
                    <br />
                    {cards?.attachment?.length !== 0 ? (
                      <>
                        <p>Uploaded Image</p>
                        <S3BucketImage
                          imageUrl={cards?.attachment[0]}
                          widthSize={"auto"}
                        />
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ))}

          {/* Add Tasks */}
          <div className="mt-10 col-md-12 p-2">
            <button
              type="button"
              className="addBtn"
              onClick={addInspChekPonts}
              style={{
                borderRadius: "8px",
                padding: "8px",
                fontWeight: "bold",
                float: "right",
              }}
            >
              Add Checkpoints
            </button>
            {errors.add_activies && (
              <div
                style={{ color: "red", fontSize: "12px", marginTop: "10px", float: "right" }}
              >
                {errors.add_activies}
              </div>
            )}
          </div>
          {/* Submit Tasks */}
          <div className="mt-10 col-md-12 p-2">
            <button
              type="button"
              className="saveButton float-end"
              onClick={(e) => submitInspChekPonts(e)}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadInspection;
