//Feature - Form to upload/onboard Facility

//Created by - Mona R
//Updated by - Mona R

//importing dependencies and packages
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FACILITY_ADD } from "../../redux/actions/facilityAction";
import Loader from "../../components/loader/loader";
import { Link } from "react-router-dom";

//function to upload facility
function FacilityUpload() {
  const { id } = useParams();

  // to fetch data from redux
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSubmitted = useSelector((state) => state.facility.isSubmitted);
  const userid = useSelector((state) => state.auth.userid);
  const isLoading = useSelector((state) => state.facility.isLoading);

  const [data, setData] = useState({
    site_id: id,
    facilityID: "",
    category: "",
    facility_name: "",
    code_used: "",
    criticality: "",
    area: "",
    location: "",
    building: "",
    blocktower: "",
    floor: "",
    wing: "",
    dept: "",
    accessbilty: "",
    facilityimg: "",
    remark: "",
    url: "",
  });

  //Functions to handle form inputs
  const handleFacilityID = (val) => {
    setData({
      ...data,
      facilityID: val,
    });
  };
  const handleCategory = (val) => {
    setData({
      ...data,
      category: val,
    });
  };
  const handleName = (val) => {
    setData({
      ...data,
      facility_name: val,
    });
  };
  const handleQR = (val) => {
    setData({
      ...data,
      code_used: val,
    });
  };
  const handleCriticality = (val) => {
    setData({
      ...data,
      criticality: val,
    });
  };
  const handleArea = (val) => {
    setData({
      ...data,
      area: val,
    });
  };
  const handleLocation = (val) => {
    setData({
      ...data,
      location: val,
    });
  };
  const handleBuilding = (val) => {
    setData({
      ...data,
      building: val,
    });
  };
  const handleBlocktower = (val) => {
    setData({
      ...data,
      blocktower: val,
    });
  };
  const handleFloor = (val) => {
    setData({
      ...data,
      floor: val,
    });
  };
  const handleWing = (val) => {
    setData({
      ...data,
      wing: val,
    });
  };
  const handleDept = (val) => {
    setData({
      ...data,
      dept: val,
    });
  };
  const handleAcesibility = (val) => {
    setData({
      ...data,
      accessbilty: val,
    });
  };
  const handleFacilityImg = (input) => {
    // console.log(input);
    let file = input.target.files[0];
    // console.log(file);
    // console.log('fileread', file instanceof Blob);
    if (file && !file.type.match(/(jpeg|png|jpg)/)) {
      alert("Only JPEG, PNG, JPG files are allowed");
      input.target.value = "";
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      var base64String = reader.result;
      // console.log('Base64 String - ', base64String);
      // Simply Print the Base64 Encoded String,
      // without additional data: Attributes.
      // console.log('Base64 String without Tags- ',
      //   base64String.substr(base64String.indexOf(', ') + 1));
      setData({
        ...data,
        facilityimg: base64String,
      });
    };
  };
  const handleRemark = (val) => {
    setData({
      ...data,
      remark: val,
    });
  };
  const handleURL = (val) => {
    setData({
      ...data,
      url: val,
    });
  };

  //client-side validation
  const [facilityIDErr, setFacilityIDErr] = useState("");
  const [categoryErr, setCategoryErr] = useState("");
  const [facility_nameErr, setNameErr] = useState("");
  // const [code_usedErr, setCodeErr] = useState("");
  // const [criticalityErr, setCriticErr] = useState("");
  // const [areaErr, setAreaErr] = useState("");
  const [locationErr, setLocationErr] = useState("");
  // const [buildingErr, setBuildingErr] = useState("");
  // const [blocktowerErr, setBlockErr] = useState("");
  // const [floorErr, setFloorErr] = useState("");
  // const [wingErr, setWingErr] = useState("");
  const [deptErr, setDeptErr] = useState("");
  const [accessbiltyErr, setAccessErr] = useState("");
  // const [facilityimgErr, setImgErr] = useState("");
  // const [remarkErr, setRemarkErr] = useState("");

  let isValid = true;
  const formValidation = () => {
    const facilityIDErr = {};
    const categoryErr = {};
    const facility_nameErr = {};
    // const areaErr = {};
    // const code_usedErr = {};
    // const criticalityErr = {};
    const locationErr = {};
    // const buildingErr = {};
    // const blocktowerErr = {};
    // const floorErr = {};
    // const wingErr = {};
    const deptErr = {};
    const accessbiltyErr = {};
    // const facilityimgErr = {};
    // const remarkErr = {};

    if (data.facilityID.trim().length < 1) {
      facilityIDErr.facidShort = "Please Enter Facility ID";
      isValid = false;
    }
    setFacilityIDErr(facilityIDErr);
    if (data.category.trim().length < 1) {
      categoryErr.categoryShort = "Please Enter Category";
      isValid = false;
    }
    setCategoryErr(categoryErr);
    if (data.facility_name.trim().length < 1) {
      facility_nameErr.nameShort = "Please Enter Name";
      isValid = false;
    }
    setNameErr(facility_nameErr);
    // if (data.code_used.trim().length < 1) {
    //   code_usedErr.codeShort = "Please Choose Code";
    //   isValid = false;
    // }
    // setCodeErr(code_usedErr);
    // if (data.criticality.trim().length < 1) {
    //   criticalityErr.assetidShort = "Please Choose Criticality";
    //   isValid = false;
    // }
    // setCriticErr(criticalityErr);
    // if (data.area.trim().length < 1) {
    //   areaErr.areaShort = "Please Enter Area";
    //   isValid = false;
    // }
    if (data.location.trim().length < 1) {
      locationErr.locationShort = "Please Enter Location";
      isValid = false;
    }
    setLocationErr(locationErr);
    // if (data.building.trim().length < 1) {
    //   buildingErr.buildingShort = "Please Enter Building";
    //   isValid = false;
    // }
    // if (data.blocktower.trim().length < 1) {
    //   blocktowerErr.blockShort = "Please Enter Block/Tower";
    //   isValid = false;
    // }
    // if (data.floor.trim().length < 1) {
    //   floorErr.floorShort = "Please Enter Floor";
    //   isValid = false;
    // }
    // if (data.wing.trim().length < 1) {
    //   wingErr.wingShort = "Please Enter Wing";
    //   isValid = false;
    // }
    if (data.dept.trim().length < 1) {
      deptErr.deptShort = "Please Enter Department";
      isValid = false;
    }
    setDeptErr(deptErr);
    if (data.accessbilty.trim().length < 1) {
      accessbiltyErr.accessShort = "Choose Accessbilty";
      isValid = false;
    }
    setAccessErr(accessbiltyErr);

    return isValid;
  };
  const validation = () => {
    const isValid = formValidation();
    return isValid;
  };

  //Function to upload facility details
  const submitFacilitydetails = (e) => {
    e.preventDefault();
    let facilityData = {
      site_id: data.site_id,
      facility_id: data.facilityID,
      facility_name: data.facility_name,
      // code_used: data.code_used,
      category: data.category,
      criticality: data.criticality?Number(data.criticality):null,
      area: data.area,
      location: data.location,
      building: data.building,
      block_tower: data.blocktower,
      floor: data.floor,
      wing: data.wing,
      department: data.dept,
      accessibility: data.accessbilty,
      facility_image: data.facilityimg,
      remarks: data.remark,
      url: data.url,
      created_by: userid,
      ticket_bearer:[]
    };
    validation();
    // console.log(isValid);
    if (isValid) {
      dispatch({
        type: FACILITY_ADD,
        payload: facilityData,
      });
    }
  };

  //useEffect
  useEffect(() => {
    if (isSubmitted) {
      navigate("/facilitylist");
    }
  }, [isSubmitted]);

  //functions for validation
  function idChars(str) {
    const idspecialChars = /^([a-zA-Z0-9-/])*[a-zA-Z0-9-/]+$/;
    return idspecialChars.test(str);
  }
  function fcategoryChars(str) {
    const categoryChars = /^([a-zA-Z0-9-/@]+\s)*[a-zA-Z0-9-/@]+$/;
    return categoryChars.test(str);
  }

  //---------------------------------------HTML-------------------------------------------//
  return (
    <div>
      {isLoading && <Loader />} {/* to show Loading effect */}
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3" style={{ paddingTop: "45px" }}>
            <form action="" className="mt-5 border p-4 bg-light shadow rounded">
              <div className="formHeadings mb-2">Create Facility</div>
              <div className="row">
                <div className="mb-2 col-md-4">
                  <label>
                    Facility ID
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="assetID"
                    className="form-control"
                    value={data.assetID}
                    onChange={(e) => handleFacilityID(e.target.value)}
                    required
                  />
                  {Object.keys(facilityIDErr).map((key) => {
                    if (!data.facilityID) {
                      return (
                        <div
                          style={{
                            color: "red",
                            fontSize: "12px",
                            paddingTop: "2px",
                          }}
                        >
                          {facilityIDErr[key]}
                        </div>
                      );
                    } else {
                      <p></p>;
                    }
                  })}
                  {idChars(data.facilityID) || data.facilityID === ""
                    ? " "
                    : ((isValid = false),
                      (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Facility Id
                        </div>
                      ))}
                </div>
                <div className="mb-2 col-md-4">
                  <label>
                    Category
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="make"
                    maxLength="200"
                    className="form-control"
                    onChange={(e) => handleCategory(e.target.value)}
                    required
                  />
                  {Object.keys(categoryErr).map((key) => {
                    if (!data.category) {
                      return (
                        <div
                          style={{
                            color: "red",
                            fontSize: "12px",
                            paddingTop: "2px",
                          }}
                        >
                          {categoryErr[key]}
                        </div>
                      );
                    } else {
                      <p></p>;
                    }
                  })}
                  {fcategoryChars(data.category) || data.category === ""
                    ? " "
                    : ((isValid = false),
                      (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Category
                        </div>
                      ))}
                </div>
                <div className="mb-2 col-md-4">
                  <label>
                    Facility Name
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="assetname"
                    maxLength="200"
                    className="form-control"
                    onChange={(e) => handleName(e.target.value)}
                    required
                  />
                  {Object.keys(facility_nameErr).map((key) => {
                    if (!data.facility_name) {
                      return (
                        <div
                          style={{
                            color: "red",
                            fontSize: "12px",
                            paddingTop: "2px",
                          }}
                        >
                          {facility_nameErr[key]}
                        </div>
                      );
                    } else {
                      <p></p>;
                    }
                  })}
                  {fcategoryChars(data.facility_name) ||
                    data.facility_name === ""
                    ? " "
                    : ((isValid = false),
                      (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Facility name
                        </div>
                      ))}
                </div>

                <div className="mb-2 col-md-4">
                  <label>Criticality</label>
                  <select
                    name="criticality"
                    id="criticality"
                    className="form-select"
                    onChange={(e) => {
                      handleCriticality(e.target.value);
                    }}
                  >
                    <option value="0">Select</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
                <div className="mb-2 col-md-4">
                  <label>Area</label>
                  <input
                    type="text"
                    name="area"
                    maxLength="200"
                    className="form-control"
                    onChange={(e) => handleArea(e.target.value)}
                    required
                  />

                  {fcategoryChars(data.area) || data.area === ""
                    ? " "
                    : ((isValid = false),
                      (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Area
                        </div>
                      ))}
                </div>
                <div className="mb-2 col-md-4">
                  <label>
                    Location<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="location"
                    maxLength="200"
                    className="form-control"
                    onChange={(e) => handleLocation(e.target.value)}
                  />
                  {Object.keys(locationErr).map((key) => {
                    if (!data.location) {
                      return (
                        <div
                          style={{
                            color: "red",
                            fontSize: "12px",
                            paddingTop: "2px",
                          }}
                        >
                          {locationErr[key]}
                        </div>
                      );
                    } else {
                      <p></p>;
                    }
                  })}
                  {fcategoryChars(data.location) || data.location === ""
                    ? " "
                    : ((isValid = false),
                      (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Location
                        </div>
                      ))}
                </div>
                <div className="mb-2 col-md-4">
                  <label>Building</label>
                  <input
                    type="text"
                    name="building"
                    maxLength="200"
                    className="form-control"
                    onChange={(e) => handleBuilding(e.target.value)}
                  />

                  {fcategoryChars(data.building) || data.building === ""
                    ? " "
                    : ((isValid = false),
                      (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Building data
                        </div>
                      ))}
                </div>
                <div className="mb-2 col-md-4">
                  <label>Block/Tower</label>
                  <input
                    type="text"
                    name="blocktower"
                    className="form-control"
                    onChange={(e) => handleBlocktower(e.target.value)}
                  />

                  {fcategoryChars(data.blocktower) || data.blocktower === ""
                    ? " "
                    : ((isValid = false),
                      (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Data
                        </div>
                      ))}
                </div>
                <div className="mb-2 col-md-4">
                  <label>Floor</label>
                  <input
                    type="text"
                    name="floor"
                    maxLength="200"
                    className="form-control"
                    onChange={(e) => handleFloor(e.target.value)}
                  />

                  {fcategoryChars(data.floor) || data.floor === ""
                    ? " "
                    : ((isValid = false),
                      (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Floor data
                        </div>
                      ))}
                </div>
                <div className="mb-2 col-md-4">
                  <label>Wing</label>
                  <input
                    type="text"
                    name="wing"
                    maxLength="200"
                    className="form-control"
                    onChange={(e) => handleWing(e.target.value)}
                  />

                  {fcategoryChars(data.wing) || data.wing === ""
                    ? " "
                    : ((isValid = false),
                      (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Wing data
                        </div>
                      ))}
                </div>
                <div className="mb-2 col-md-8">
                  <label>
                    Department
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="dept"
                    maxLength="200"
                    className="form-control"
                    onChange={(e) => handleDept(e.target.value)}
                    required
                  />
                  {Object.keys(deptErr).map((key) => {
                    if (!data.dept) {
                      return (
                        <div
                          style={{
                            color: "red",
                            fontSize: "12px",
                            paddingTop: "2px",
                          }}
                        >
                          {deptErr[key]}
                        </div>
                      );
                    } else {
                      <p></p>;
                    }
                  })}
                  {fcategoryChars(data.dept) || data.dept === ""
                    ? " "
                    : ((isValid = false),
                      (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Department
                        </div>
                      ))}
                </div>
                <div className="mb-2 col-md-12">
                  <label>
                    Network Accessibility<span className="text-danger">*</span>
                  </label>
                  <select
                    name="accessbilty"
                    className="form-select"
                    onChange={(e) => {
                      handleAcesibility(e.target.value);
                    }}
                  >
                    <option value="select">Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                  {Object.keys(accessbiltyErr).map((key) => {
                    if (!data.accessbilty) {
                      return (
                        <div
                          style={{
                            color: "red",
                            fontSize: "12px",
                            paddingTop: "2px",
                          }}
                        >
                          {accessbiltyErr[key]}
                        </div>
                      );
                    } else {
                      <p></p>;
                    }
                  })}
                </div>
                <div className="mb-2 col-md-8">
                  <label>Facility Image</label>
                  <input
                    className="form-control"
                    name="assetimg"
                    type="file"
                    id="formFile"
                    onChange={handleFacilityImg}
                    accept=".jpeg,.jpg,.png"
                  ></input>
                </div>
                <div className="mb-2 col-md-4">
                  <label>Remarks</label>
                  <input
                    type="text"
                    maxLength="200"
                    name="remark"
                    className="form-control"
                    onChange={(e) => handleRemark(e.target.value)}
                  />
                </div>
                <div className="mb-2 col-md-4">
                  <label>URL</label>
                  <input
                    type="text"
                    maxLength="200"
                    name="url"
                    className="form-control"
                    onChange={(e) => handleURL(e.target.value)}
                  />
                </div>
                <div className="mb-2 col-md-12">
                  <button
                    type="submit"
                    className="float-end saveButton"
                    onClick={(e) => submitFacilitydetails(e)}
                  >
                    Save
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    onClick={() => navigate("/select/asset")}
                    className="backButton"
                  >
                    Back
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

//exporting component
export default FacilityUpload;
