/* Feature - Form to update/modify asset details

Created by - Mona R
Updated by - Janaki J */

//importing dependencies and packages
import { useEffect, useState, React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { ASSET_UPDATE } from "../../redux/actions/assetAction";
import Loader from "../../components/loader/loader";
import { Link } from "react-router-dom";
// import moment from "moment";

//Function to update asset
const AssetUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const options = ["1", "2", "3"];
  // To fetch details from redux
  const dispatch = useDispatch();
  const assetGetSingle = useSelector((state) => state.asset.assetGetSingle);
  const isLoading = useSelector((state) => state.asset.isLoading);
  const access = useSelector((state) => state.access.asset);
  const userid = useSelector((state) => state.auth.userid);

  const [critic, setCritic] = useState("");
  const [data, setData] = useState({
    id: "",
    assetId: "",
    name: "",
    make: "",
    model: "",
    serial: "",
    rating: "",
    uom: "",
    qr: "",
    manufactureYear: '',
    installationYear: '',
    life: '',
    criticality: '',
    area: "",
    location: "",
    building: "",
    block: "",
    floor: "",
    wing: "",
    dept: "",
    accessibility: "",
    photo: "",
    remark: "",
    url: "",
    ticket_bearer:[],
  });
  //client-side validation
  const [assetidErr, setAssetidErr] = useState("");
  const [assetnameErr, setAssetnameErr] = useState("");
  const [makeErr, setMakeErr] = useState("");
  const [modelErr, setModelErr] = useState("");
  const [rcErr, setRcErr] = useState("");
  const [uomErr, setUomErr] = useState("");
  const [criticErr, setCriticErr] = useState("");
  const [areaErr, setAreaErr] = useState("");
  const [locationErr, setLocationErr] = useState("");
  const [deptErr, setDeptErr] = useState("");


  let isValid = true;

  // function for client side validation.
  const formValidation = () => {
    const assetidErr = {};
    const assetnameErr = {};
    const makeErr = {};
    const modelErr = {};
    const rcErr = {};
    const uomErr = {};
    const criticErr = {};
    const areaErr = {};
    const locationErr = {};
    const deptErr = {};

    if (data.assetId.trim().length < 1) {
      assetidErr.assetidShort = "Please Enter Asset ID";
      isValid = false;
    }
    setAssetidErr(assetidErr);
    if (data.name.trim().length < 1) {
      assetnameErr.assetidShort = "Please Enter Asset name";
      isValid = false;
    }
    setAssetnameErr(assetnameErr);
    if (data.make.trim().length < 1) {
      makeErr.assetidShort = "Please Enter Make";
      isValid = false;
    }
    setMakeErr(makeErr);
    if (data.model.trim().length < 1) {
      modelErr.assetidShort = "Please Enter Model";
      isValid = false;
    }
    setModelErr(modelErr);

    if (data.rating.trim().length < 1) {
      rcErr.assetidShort = "Please Enter Rating";
      isValid = false;
    }
    setRcErr(rcErr);
    if (data.uom.trim().length < 1) {
      uomErr.assetidShort = "Please Enter UOM";
      isValid = false;
    }
    setUomErr(uomErr);

    if (data.criticality.trim().length < 1) {
      criticErr.critiShort = " Choose Criticality";
      isValid = false;
    }
    setCriticErr(criticErr);
    if (data.area.trim().length < 1) {
      areaErr.assetidShort = "Please Enter Area";
      isValid = false;
    }
    setAreaErr(areaErr);
    if (data.location.trim().length < 1) {
      locationErr.locationShort = "Please Enter Location";
      isValid = false;
    }
    setLocationErr(locationErr);
    if (data.dept.trim().length < 1) {
      deptErr.assetidShort = "Please Enter Department";
      isValid = false;
    }
    setDeptErr(deptErr);

    return isValid;
  };

  const validation = () => {
    const isValid = formValidation();
    return isValid;
  };
  //Functions to handle form inputs
  const handleAssetID = (val) => {
    if (val.length < 1) {
      setData({
        ...data,
        assetId: val,
        isValidAssetID: true,
      });
    } else {
      setData({
        ...data,
        assetId: val,
        isValidAssetID: false,
      });
    }
  };
  const handleName = (val) => {
    setData({
      ...data,
      name: val,
    });
  };
  const handleMake = (val) => {
    setData({
      ...data,
      make: val,
    });
  };
  const handleModel = (val) => {
    setData({
      ...data,
      model: val,
    });
  };
  const handleSerialno = (val) => {
    setData({
      ...data,
      serial: val,
    });
  };
  const handleRating = (val) => {
    setData({
      ...data,
      rating: val,
    });
  };
  const handleUom = (val) => {
    setData({
      ...data,
      uom: val,
    });
  };
  const handleQr = (val) => {
    setData({
      ...data,
      qr: val,
    });
  };
  const handleInstallyear = (val) => {
    setData({
      ...data,
      installationYear: val,
    });
  };
  const handleManufactureyr = (val) => {
    setData({
      ...data,
      manufactureYear: val,
    });
  };
  const handleExplife = (val) => {
    setData({
      ...data,
      life: val,
    });
  };
  const handleCriticality = (val) => {
    setData({
      ...data,
      criticality: val,
    });
  };
  const handleArea = (val) => {
    setData({
      ...data,
      area: val,
    });
  };
  const handleLocation = (val) => {
    setData({
      ...data,
      location: val,
    });
  };
  const handleBuilding = (val) => {
    setData({
      ...data,
      building: val,
    });
  };
  const handleBlock = (val) => {
    setData({
      ...data,
      block: val,
    });
  };
  const handleFloor = (val) => {
    setData({
      ...data,
      floor: val,
    });
  };
  const handleWing = (val) => {
    setData({
      ...data,
      wing: val,
    });
  };
  const handleDept = (val) => {
    setData({
      ...data,
      dept: val,
    });
  };
  const handleAccesbility = (val) => {
    setData({
      ...data,
      accessibility: val,
    });
  };
  const handleAssetimg = (val) => {
    setData({
      ...data,
      photo: val,
    });
  };
  const handleRemark = (val) => {
    setData({
      ...data,
      remark: val,
    });
  };
  const handleURL = (val) => {
    setData({
      ...data,
      url: val,
    });
  };

  //Function to update asset details
  const updateAssetDetails = () => {
    let assetData = {
      asset_id: data.assetId,
      asset_name: data.name,
      make: data.make,
      model: data.model,
      serial_number: data.serial,
      rating_capacity: data.rating,
      uom: data.uom,
      code_used: data.qr,
      year_of_manufacture: data.manufactureYear ? Number(data.manufactureYear) : null,
      year_of_installation: data.installationYear ? Number(data.installationYear) : null,
      expected_life_in_month: data.life ? Number(data.life) : null,
      criticality: data.criticality ? Number(data.criticality) : null,
      area: data.area,
      location: data.location,
      building: data.building,
      block_tower: data.block,
      floor: data.floor,
      wing: data.wing,
      department: data.dept,
      accessibility: data.accessibility,
      asset_image: data.photo,
      remarks: data.remark,
      url: data.url,
      modified_by: userid,
      ticket_bearer:[]
    };
    validation();
    if (isValid) {
      // console.log("assetData", assetData);
      dispatch({
        type: ASSET_UPDATE,
        payload: [id, assetData],
      });
    }
    navigate("/assetlist");
  };

  // fuction to fetch asset ID from params
  const sendID = () => {
    if (id !== null) {
      dispatch({
        type: "ASSET_GET_SINGLE",
        payload: id,
      });
    }
  };

  //functions for validation
  function idChars(str) {
    const idspecialChars =
      /^([a-z.A-Z.0-9-/@]+[0-9.\-\)\(\(\)]+\s)*[a-z.A-Z.0-9-/@]+$/;
    return idspecialChars.test(str);
  }
  function numChars(str) {
    const categoryChars = /^([0-9]+\s)*[0-9]+$/;
    return categoryChars.test(str);
  }
  function rncChars(str) {
    const idspecialChars = /^([a-zA-Z0.-9-/&]+\s)*[a-zA-Z.,0,.-9-/&]+$/;
    return idspecialChars.test(str);
  }
  function areaChars(str) {
    const idspecialChars =
      /^([(a-zA-Z)0-9-/@|#]+\s)*[a-zA-Z0-9-/@|#]+$/;
    return idspecialChars.test(str);
  }
  function makeChars(str) {
    const makeChars = /^([(a-zA-Z)0-9-/@|#]+\s)*[(a-zA-Z)0-9-/@|#]+$/;
    return makeChars.test(str);
  }

  function deptChars(str) {
    const idspecialChars = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
    return idspecialChars.test(str);
  }
  function uomChars(str) {
    const idspecialChars = /^([a-zA-Z0-9-/]+\s)*[a-zA-Z0-9-/]+$/;
    return idspecialChars.test(str);
  }
  // useEffects
  useEffect(() => {
    sendID();
  }, [id]);

  // console.log("assetGetSingle", assetGetSingle);

  useEffect(() => {
    if (assetGetSingle != undefined && assetGetSingle != 0) {
      setData({
        assetId: assetGetSingle.asset_id,
        name: assetGetSingle.asset_name,
        make: assetGetSingle.make,
        model: assetGetSingle.model,
        serial: assetGetSingle.serial_number,
        rating: assetGetSingle.rating_capacity,
        uom: assetGetSingle.uom,
        qr: assetGetSingle.code_used,
        manufactureYear: assetGetSingle.year_of_manufacture,
        installationYear: assetGetSingle.year_of_installation,
        life: assetGetSingle.expected_life_in_month,
        criticality: assetGetSingle.criticality,
        area: assetGetSingle.area,
        location: assetGetSingle.location,
        building: assetGetSingle.building,
        block: assetGetSingle.block_tower,
        floor: assetGetSingle.floor,
        wing: assetGetSingle.wing,
        dept: assetGetSingle.department,
        accessibility: assetGetSingle.accessibility,
        asset_image: assetGetSingle.asset_image,
        photo: assetGetSingle.asset_image,
        remark: assetGetSingle.remarks,
        url: assetGetSingle?.url
      });
    }
  }, [assetGetSingle]);


  return (
    <div>
      {isLoading && <Loader />} {/* {to show the Loading effect} */}
      <div className="container">
        <div className="row">
          <div className="col-md-12 offset-md-12">
            <div className="AssetUpdate" style={{ paddingTop: "25px" }}>
              {access !== null && access.u ? (
                <form
                  action=""
                  className="mt-5 border p-4 bg-light shadow rounded"
                  onSubmit={(e) => {
                    e.preventDefault(); // Prevent the page from refreshing
                    // Handle the form submission logic here
                  }}
                >
                  <div className="formHeadings mb-2">Edit Asset</div>
                  <div className="row">
                    <div className="mb-2 col-md-3">
                      <label>
                        Asset ID<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="assetId"
                        className="form-control form-rounded"
                        maxLength="200"
                        onChange={(e) => {
                          handleAssetID(e.target.value);
                        }}
                        defaultValue={data.assetId}
                      />
                      {Object.keys(assetidErr).map((key) => {
                        if (!data.assetId) {
                          return (
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              {assetidErr[key]}
                            </div>
                          );
                        } else {
                          <p></p>;
                        }
                      })}
                      {idChars(data.assetId) || data.assetId === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          {/* Invalid Asset ID */}
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Asset Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        maxLength="200"
                        onChange={(e) => {
                          handleName(e.target.value);
                        }}
                        defaultValue={data.name}
                      />
                      {idChars(data.assetname) || data.assetname === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Asset name
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Make<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="make"
                        className="form-control"
                        maxLength="200"
                        onChange={(e) => {
                          handleMake(e.target.value);
                        }}
                        defaultValue={data.make}
                      />
                      {makeChars(data.make) || data.make === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Make
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Model<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="model"
                        className="form-control"
                        maxLength="200"
                        onChange={(e) => {
                          handleModel(e.target.value);
                        }}
                        defaultValue={data.model}
                      />
                      {areaChars(data.model) || data.model === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid model
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Serial Number</label>
                      <input
                        type="text" // Use text to avoid browser's built-in number handling that allows non-numeric characters like 'e' and '-'.
                        name="serial"
                        className="form-control"
                        maxLength="4"
                        onChange={(e) => {
                          handleSerialno(e.target.value);
                        }}
                        value={data.serial}
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Rating/Capacity<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="rating"
                        className="form-control"
                        maxLength="200"
                        onChange={(e) => {
                          handleRating(e.target.value);
                        }}
                        defaultValue={data.rating}
                      />
                      {rncChars(data.rating) || data.rating === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Data
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        UoM of Rating/Capacity
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="uom"
                        className="form-control"
                        maxLength="100"
                        onChange={(e) => {
                          handleUom(e.target.value);
                        }}
                        defaultValue={data.uom}
                      />
                      {uomChars(data.unitofmeasurement) ||
                        data.unitofmeasurement === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid UOM
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Year of Manufacture</label>
                      <input
                        type="text" // Use text to avoid browser's built-in number handling that allows non-numeric characters like 'e' and '-'.
                        name="life"
                        className="form-control"
                        maxLength="4"
                        onChange={(e) => {
                          const value = e.target.value;
                          // Allow only numeric characters
                          if (/^\d*$/.test(value)) {
                            handleManufactureyr(e.target.value);
                          }
                        }}
                        value={data.manufactureYear}
                      />
                      {numChars(data.manufactureYear) ||
                        data.manufactureYear === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          {/* Invalid Manufacture Year */}
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Year of Installation</label>
                      <input
                        type="text" // Use text to avoid browser's built-in number handling that allows non-numeric characters like 'e' and '-'.
                        name="installationYear"
                        className="form-control"
                        maxLength="4"
                        onChange={(e) => {
                          const value = e.target.value;
                          // Allow only numeric characters
                          if (/^\d*$/.test(value)) {
                            handleInstallyear(e.target.value);
                          }
                        }}
                        value={data.installationYear}
                      />
                      {numChars(data.installationYear) ||
                        data.installationYear === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          {/* Invalid Installation Year */}
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Expected Life in Months</label>
                      <input
                        type="text" // Use text to avoid browser's built-in number handling that allows non-numeric characters like 'e' and '-'.
                        name="life"
                        className="form-control"
                        maxLength="4"
                        onChange={(e) => {
                          const value = e.target.value;
                          // Allow only numeric characters
                          if (/^\d*$/.test(value)) {
                            handleExplife(value);
                          }
                        }}
                        value={data.life}
                      />
                      {numChars(data.life) || data.life === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          {/* Invalid Manufacture Year */}
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Criticality<span className="text-danger">*</span>
                      </label>
                      <select
                        name="criticality"
                        className="form-select"
                        onChange={(e) => {
                          handleCriticality(e.target.value);
                        }}
                        value={data.criticality}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Area<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="area"
                        className="form-control"
                        maxLength="200"
                        onChange={(e) => {
                          handleArea(e.target.value);
                        }}
                        defaultValue={data.area}
                      />
                      {areaChars(data.area) || data.area === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Area
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Location
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="location"
                        className="form-control"
                        maxLength="200"
                        onChange={(e) => {
                          handleLocation(e.target.value);
                        }}
                        defaultValue={data.location}
                      />
                      {areaChars(data.location) || data.location === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Location
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Building</label>
                      <input
                        type="text"
                        name="building"
                        className="form-control"
                        maxLength="200"
                        onChange={(e) => {
                          handleBuilding(e.target.value);
                        }}
                        defaultValue={data.building}
                      />
                      {idChars(data.building) || data.building === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Building
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Block/Tower</label>
                      <input
                        type="text"
                        name="block"
                        className="form-control"
                        maxLength="200"
                        onChange={(e) => {
                          handleBlock(e.target.value);
                        }}
                        defaultValue={data.block}
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Floor</label>
                      <input
                        type="text"
                        name="floor"
                        className="form-control"
                        maxLength="200"
                        onChange={(e) => {
                          handleFloor(e.target.value);
                        }}
                        defaultValue={data.floor}
                      />
                      {areaChars(data.floor) || data.floor === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Floor
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Wing</label>
                      <input
                        type="text"
                        name="wing"
                        className="form-control"
                        maxLength="200"
                        onChange={(e) => {
                          handleWing(e.target.value);
                        }}
                        defaultValue={data.wing}
                      />
                      {idChars(data.wing) || data.wing === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Wing
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Department <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="dept"
                        className="form-control"
                        maxLength="200"
                        onChange={(e) => {
                          handleDept(e.target.value);
                        }}
                        defaultValue={data.dept}
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Network Accessibility</label>
                      <select
                        name="accessibility"
                        className="form-select"
                        onChange={(e) => {
                          handleAccesbility(e.target.value);
                        }}
                        value={data.accessibility}
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Asset Image</label>
                      <input
                        type="file"
                        name="photo"
                        className="form-control"
                        onChange={(e) => {
                          handleAssetimg(e.target.value);
                        }}
                        defaultValue={data.photo}
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Remarks</label>
                      <input
                        type="text"
                        name="remark"
                        className="form-control"
                        onChange={(e) => {
                          handleRemark(e.target.value);
                        }}
                        defaultValue={data.remark}
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>URL</label>
                      <input
                        type="text"
                        name="url"
                        className="form-control"
                        onChange={(e) => {
                          handleURL(e.target.value);
                        }}
                        defaultValue={data.url}
                      />
                    </div>
                    <div className="mb-2 col-md-12">
                      <button
                        type="submit"
                        onClick={() => updateAssetDetails()}
                        className="float-end saveButton"
                      >
                        Update
                      </button>{" "}
                      &nbsp;&nbsp;&nbsp;
                      <button
                        onClick={() => navigate("/assetlist")}
                        className="backButton"
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </form>
              ) : (
                null
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetUpdate;
