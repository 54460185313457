export const auditAttr = {
    auditAMM: [
        { heading: 'S. No', alignment: 'center' },
        { heading: 'Event Name', alignment: 'left' },
        { heading: 'Site Name', alignment: 'left' },
        { heading: 'Event Description', alignment: 'left' },
        { heading: 'Time Stamp', alignment: 'center' },
        { heading: 'Executed By', alignment: 'left' },
    ],
    auditFA: [
        { heading: 'S. No', alignment: 'center' },
        { heading: 'Event Name', alignment: 'left' },
        { heading: 'Event Details', alignment: 'left' },
        { heading: 'Event Date & Time', alignment: 'left' },
        { heading: 'Event Occured By', alignment: 'left' },
    ]
}


