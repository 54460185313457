import {
    ACCESS_GET_LIST,
    ACCESS_GET_SUCCESS,
    ACCESS_GET_FAILED,
    ROLE_GET_LIST,
    ROLE_GET_SUCCESS,
    ROLE_GET_FAILED
} from "./accessAction"
import {
    ASSET_GET_LIST,
    ASSET_GET_SUCCESS,
    ASSET_GET_FAILED,

    ASSET_DELETE_LIST,
    ASSET_DELETE_SUCCESS,
    ASSET_DELETE_FAILED,

    ASSET_ADD,
    ASSET_ADD_SUCCESS,
    ASSET_ADD_FAILED,

    ASSET_BULK_ADD,
    ASSET_BULK_ADD_SUCCESS,
    ASSET_BULK_ADD_FAILED,

    ASSET_UPDATE,
    ASSET_UPDATE_SUCCESS,
    ASSET_UPDATE_FAILED,

    ASSET_GET_SINGLE,
    ASSET_GET_SINGLE_SUCCESS,
    ASSET_GET_SINGLE_FAILED,

    SOP_UPLOAD,
    SOP_UPLOAD_SUCCESS,
    SOP_UPLOAD_FAILED
} from "./assetAction"
import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAILED,

    LOGOUT,
    LOGOUT_SUCCESS,
    LOGOUT_FAILED,

    REFRESH_TOKEN,
    REFRESH_TOKEN_SUCCESS,
} from "./authActions"
import {
    CUST_GET_LIST,
    CUST_GET_SUCCESS,
    CUST_GET_FAILED,

    CUST_DELETE_LIST,
    CUST_DELETE_SUCCESS,
    CUST_DELETE_FAILED,

    CUST_ADD,
    CUST_ADD_SUCCESS,
    CUST_ADD_FAILED,

    CUST_UPDATE,
    CUST_UPDATE_SUCCESS,
    CUST_UPDATE_FAILED,

    CUST_GET_SINGLE,
    CUST_GET_SINGLE_SUCCESS,
    CUST_GET_SINGLE_FAILED,

    CUST_CONTACT_DETAILS,
    CUST_CONTACT_DETAILS_SUCCESS,
    CUST_CONTACT_DETAILS_FAILED,

    CUST_CONTACT_GET,
    CUST_CONTACT_GET_SUCCESS,
    CUST_CONTACT_GET_FAILED,

    CUST_BULK_ADD,
    CUST_BULK_ADD_SUCCESS,
    CUST_BULK_ADD_FAILED,

    CUST_CONTACT_DELETE,
    CUST_CONTACT_DELETE_SUCCESS,
    CUST_CONTACT_DELETE_FAILED,
} from "./custAction"
import {
    INS_MAIN_GET_STATUS,
    INS_MAIN_GET_STATUS_SUCCESS,
    INS_MAIN_GET_STATUS_FAILED,

    FILTERED_INS_DATA,
    FILTERED_INS_DATA_SUCCESS,
    FILTERED_INS_DATA_FAILED,

    FILTERED_MAIN_DATA,
    FILTERED_MAIN_DATA_SUCCESS,
    FILTERED_MAIN_DATA_FAILED,

    INS_MAIN_GET_STATUS_OFFLINE,
    INS_MAIN_GET_STATUS_OFFLINE_SUCCESS,
    INS_MAIN_GET_STATUS_OFFLINE_FAILED,
} from "./dashAction"
import {
    DEVICE_GET_LIST,
    DEVICE_GET_SUCCESS,
    DEVICE_GET_FAILED,

    DEVICE_DELETE_LIST,
    DEVICE_DELETE_SUCCESS,
    DEVICE_DELETE_FAILED,

    DEVICE_ADD,
    DEVICE_ADD_SUCCESS,
    DEVICE_ADD_FAILED,

    DEVICE_UPDATE,
    DEVICE_UPDATE_SUCCESS,
    DEVICE_UPDATE_FAILED,

    DEVICE_GET_SINGLE,
    DEVICE_GET_SINGLE_SUCCESS,
    DEVICE_GET_SINGLE_FAILED
} from "./deviceAction"
import {
    CWIP_GET_LIST,
    CWIP_GET_SUCCESS,
    CWIP_GET_FAILED,
    ADD_CAPITALISE,
    ADD_CAPITALISE_SUCCESS,
    ADD_CAPITALISE_FAILED,
    ADD_EXPENSE,
    ADD_EXPENSE_SUCCESS,
    ADD_EXPENSE_FAILED,
    EXPENSE_GET_LIST,
    EXPENSE_GET_SUCCESS,
    EXPENSE_GET_FAILED,
    CAPITAL_GET_LIST,
    CAPITAL_GET_SUCCESS,
    CAPITAL_GET_FAILED,
    ADD_LOCATION,
    ADD_LOCATION_SUCCESS,
    ADD_LOCATION_FAILED,
    GET_LOCATION_LIST,
    GET_LOCATION_SUCCESS,
    GET_LOCATION_FAILED,
    ADD_CLASS,
    ADD_CLASS_SUCCESS,
    ADD_CLASS_FAILED,
    GET_CLASS_LIST,
    GET_CLASS_SUCCESS,
    GET_CLASS_FAILED,
    GET_CALCULATION,
    GET_CALCULATION_SUCCESS,
    GET_CALCULATION_FAILED,
    CREATE_FAASSET,
    CREATE_FAASSET_SUCCESS,
    CREATE_FAASSET_FAILED,
    ADD_BULK_FAASSET,
    ADD_BULK_FAASSET_SUCCESS,
    ADD_BULK_FAASSET_FAILED,
    ADD_DISPOSE,
    ADD_DISPOSE_SUCCESS,
    ADD_DISPOSE_FAILED,
    FAASSET_UPDATE,
    FAASSET_UPDATE_SUCCESS,
    FAASSET_UPDATE_FAILED,
    ADD_COST_CENTER,
    ADD_COST_CENTER_SUCCESS,
    ADD_COST_CENTER_FAILED,
    GET_COST_CENTER_LIST,
    GET_COST_CENTER_SUCCESS,
    GET_COST_CENTER_FAILED,
    GET_BU_CODE_LIST,
    GET_BU_CODE_SUCCESS,
    GET_BU_CODE_FAILED,
    GET_ADDITION_DELETION,
    GET_ADDITION_DELETION_SUCCESS,
    GET_ADDITION_DELETION_FAILED,
    SALE_ASSET,
    SALE_ASSET_SUCCESS,
    SALE_ASSET_FAILED,
    GET_VERIFIED_LIST,
    GET_VERIFIED_LIST_SUCCESS,
    GET_VERIFIED_LIST_FAILED,
    GET_REPORT_DATA,
    GET_REPORT_DATA_SUCCESS,
    GET_REPORT_DATA_FAILED,
    ADD_SAMPLE_LIST,
    ADD_SAMPLE_LIST_SUCCESS,
    ADD_SAMPLE_LIST_FAILED,
    GET_SAMPLE_LIST,
    GET_SAMPLE_LIST_SUCCESS,
    GET_SAMPLE_LIST_FAILED
} from "./faAction"
import {
    FACILITY_GET_LIST,
    FACILITY_GET_SUCCESS,
    FACILITY_GET_FAILED,

    FACILITY_DELETE_LIST,
    FACILITY_DELETE_SUCCESS,
    FACILITY_DELETE_FAILED,

    FACILITY_ADD,
    FACILITY_ADD_SUCCESS,
    FACILITY_ADD_FAILED,

    FACILITY_UPDATE,
    FACILITY_UPDATE_SUCCESS,
    FACILITY_UPDATE_FAILED,


    FACILITY_GET_SINGLE,
    FACILITY_GET_SINGLE_SUCCESS,
    FACILITY_GET_SINGLE_FAILED,
} from "./facilityAction"
import {
    HISTRY_GET_LIST,
    HISTRY_GET_SUCCESS,
    HISTRY_GET_FAILED
} from "./historyAction"
import {
    INSPECTION_ADD,
    INSPECTION_ADD_SUCCESS,
    INSPECTION_ADD_FAILED,

    INSPECTION_GET_LIST,
    INSPECTION_GET_SUCCESS,
    INSPECTION_GET_FAILED,

    INSPECTION_GET_SINGLE,
    INSPECTION_GET_SINGLE_SUCCESS,
    INSPECTION_GET_SINGLE_FAILED,

    INSPECTION_UPDATE,
    INSPECTION_UPDATE_SUCCESS,
    INSPECTION_UPDATE_FAILED,

    INSPECTION_ASSIGN_LIST,
    INSPECTION_ASSIGN_SUCCESS,
    INSPECTION_ASSIGN_FAILED,

    INSPECTION_DELETE_LIST,
    INSPECTION_DELETE_SUCCESS,
    INSPECTION_DELETE_FAILED,

    INSPECTION_BULK_ADD,
    INSPECTION_BULK_ADD_SUCCESS,
    INSPECTION_BULK_ADD_FAILED,

    INSPECTION_REPORT,
    INSPECTION_REPORT_SUCCESS,
    INSPECTION_REPORT_FAILED,

    INSPECTION_STATUS,
    INSPECTION_GET_STATUS_SUCCESS,
    INSPECTION_GET_STATUS_FAILED,

    INSPECTION_STOP,
    INSPECTION_STOP_SUCCESS,
    INSPECTION_STOP_FAILED,
} from "./inspectionAction"
import {
    MAINT_ADD,
    MAINT_ADD_SUCCESS,
    MAINT_ADD_FAILED,

    MAINT_GET_LIST,
    MAINT_GET_SUCCESS,
    MAINT_GET_FAILED,

    MAINT_DELETE_LIST,
    MAINT_DELETE_SUCCESS,
    MAINT_DELETE_FAILED,

    MAINTENANCE_BULK_ADD,
    MAINTENANCE_BULK_ADD_SUCCESS,
    MAINTENANCE_BULK_ADD_FAILED,

    MAINTENANCE_REPORT,
    MAINTENANCE_REPORT_SUCCESS,
    MAINTENANCE_REPORT_FAILED,

    MAINTENANCE_ASSIGN_LIST,
    MAINTENANCE_ASSIGN_SUCCESS,
    MAINTENANCE_ASSIGN_FAILED,

    MAINT_GET_SINGLE,
    MAINT_GET_SINGLE_SUCCESS,
    MAINT_GET_SINGLE_FAILED,

    MAINTENANCE_UPDATE,
    MAINT_UPDATE_SUCCESS,
    MAINT_UPDATE_FAILED,

    MAINTENANCE_STATUS,
    MAINTENANCE_STATUS_SUCCESS,
    MAINTENANCE_STATUS_FAILED,

    MAINTENANCE_STOP,
    MAINTENANCE_STOP_SUCCESS,
    MAINTENANCE_STOP_FAILED,

    SOP_GET,
    SOP_GET_SUCCESS,
    SOP_GET_FAILED,

    ASSIGN_SOP_MAIN,
    ASSIGN_SOP_MAIN_SUCCESS,
    ASSIGN_SOP_MAIN_FAILED
} from "./maintAction"
import {
    PLANT_ADD,
    PLANT_ADD_SUCCESS,
    PLANT_ADD_FAILED,
    PLANT_GET_ALL,
    PLANT_GET_ALL_SUCCESS,
    PLANT_GET_ALL_FAILED,
    PLANT_GET_SINGLE,
    PLANT_GET_SINGLE_SUCCESS,
    PLANT_GET_SINGLE_FAILED,
    PLANT_UPDATE,
    PLANT_UPDATE_SUCCESS,
    PLANT_UPDATE_FAILED
} from "./plantActions"
import {
    REP_BASEDON_USER_STATUS,
    REP_BASEDON_USER_STATUS_SUCCESS,
    REP_BASEDON_USER_STATUS_FAILED,
    GET_COLLECTIONS,
    GET_COLLECTIONS_SUCCESS,
    GET_COLLECTIONS_FAILED,
    GET_QUERY,
    GET_QUERY_SUCCESS,
    GET_QUERY_FAILED,
    GET_ATTRIBUTES,
    GET_ATTRIBUTES_SUCCESS,
    GET_ATTRIBUTES_FAILED,
    GET_FIELD_NAME,
    GET_FIELD_NAME_SUCCESS,
    GET_FIELD_NAME_FAILED,
    GET_REPORT,
    GET_REPORT_SUCCESS,
    GET_REPORT_FAILED,
    SAVE_QUERY,
    SAVE_QUERY_SUCCESS,
    SAVE_QUERY_FAILED,
    GET_QUERY_LIST,
    GET_QUERY_LIST_SUCCESS,
    GET_QUERY_LIST_FAILED,
    UPDATE_QUERY,
    UPDATE_QUERY_SUCCESS,
    UPDATE_QUERY_FAILED,
    DELETE_QUERY,
    DELETE_QUERY_SUCCESS,
    DELETE_QUERY_FAILED,
    SAVE_REPORT,
    SAVE_REPORT_FAILED,
    SAVE_REPORT_SUCCESS,
} from "./reportAction"
import {
    SITE_GET_LIST,
    SITE_GET_SUCCESS,
    SITE_GET_FAILED,

    SITE_DELETE_LIST,
    SITE_DELETE_SUCCESS,
    SITE_DELETE_FAILED,

    SITE_ADD,
    SITE_ADD_SUCCESS,
    SITE_ADD_FAILED,

    SITE_CONTACT_DETAILS,
    SITE_CONTACT_DETAILS_SUCCESS,
    SITE_CONTACT_DETAILS_FAILED,

    SITE_CONTACT_GET,
    SITE_CONTACT_GET_SUCCESS,
    SITE_CONTACT_GET_FAILED,

    SITE_BULK_ADD,
    SITE_BULK_ADD_SUCCESS,
    SITE_BULK_ADD_FAILED,

    SITE_GET_SINGLE,
    SITE_GET_SINGLE_SUCCESS,
    SITE_GET_SINGLE_FAILED,

    SITE_UPDATE,
    SITE_UPDATE_SUCCESS,
    SITE_UPDATE_FAILED,

    SITE_CONTACT_DELETE_FAILED,
    SITE_CONTACT_DELETE_SUCCESS,
    SITE_CONTACT_DELETE,
} from "./siteAction"
import {
    TICKET_STATUS,
    TICKET_STATUS_SUCCESS,
    TICKET_STATUS_FAILED,

    TICKET_REPORT,
    TICKET_REPORT_SUCCESS,
    TICKET_REPORT_FAILED,
} from "./ticketAction"
import {
    USER_ADD,
    USER_ADD_SUCCESS,
    USER_ADD_FAILED,
    USER_GET_LIST,
    USER_GET_SUCCESS,
    USER_GET_FAILED,
    USER_SET_PASSWORD,
    USER_SET_PASSWORD_SUCCESS,
    USER_SET_PASSWORD_FAILED,
    // ROLE_GET_LIST,
    // ROLE_GET_SUCCESS,
    // ROLE_GET_FAILED,
    USER_BULK_ADD,
    USER_BULK_ADD_SUCCESS,
    USER_BULK_ADD_FAILED,
    USER_DELETE_LIST,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAILED,
    USER_GET_SINGLE,
    USER_GET_SINGLE_SUCCESS,
    USER_GET_SINGLE_FAILED,
    GET_APPROV_USERS,
    GET_APPROV_USERS_SUCCESS,
    GET_APPROV_USERS_FAILED,
    USER_GET_TECH,
    USER_GET_TECH_SUCESS,
    USER_GET_TECH_FAILED,
    USER_GET_APPROVER,
    USER_GET_APPROVER_SUCESS,
    USER_GET_APPROVER_FAILED
} from "./userAction"

export const actionArray = [
   
    ASSET_GET_LIST,
    ASSET_GET_SUCCESS,
    ASSET_GET_FAILED,

    // CUST_UPDATE,
    // CUST_UPDATE_SUCCESS,
    // CUST_UPDATE_FAILED,

    // CUST_GET_SINGLE,
    // CUST_GET_SINGLE_SUCCESS,
    // CUST_GET_SINGLE_FAILED,

    // CUST_CONTACT_DETAILS,
    // CUST_CONTACT_DETAILS_SUCCESS,
    // CUST_CONTACT_DETAILS_FAILED,

    // CUST_CONTACT_GET,
    // CUST_CONTACT_GET_SUCCESS,
    // CUST_CONTACT_GET_FAILED,

    // CUST_BULK_ADD,
    // CUST_BULK_ADD_SUCCESS,
    // CUST_BULK_ADD_FAILED,

    // CUST_CONTACT_DELETE,
    // CUST_CONTACT_DELETE_SUCCESS,
    // CUST_CONTACT_DELETE_FAILED,

    INS_MAIN_GET_STATUS,
    INS_MAIN_GET_STATUS_SUCCESS,
    INS_MAIN_GET_STATUS_FAILED,

    FILTERED_INS_DATA,
    FILTERED_INS_DATA_SUCCESS,
    FILTERED_INS_DATA_FAILED,

    FILTERED_MAIN_DATA,
    FILTERED_MAIN_DATA_SUCCESS,
    FILTERED_MAIN_DATA_FAILED,

    INS_MAIN_GET_STATUS_OFFLINE,
    INS_MAIN_GET_STATUS_OFFLINE_SUCCESS,
    INS_MAIN_GET_STATUS_OFFLINE_FAILED,
    
    DEVICE_GET_LIST,
    DEVICE_GET_SUCCESS,
    DEVICE_GET_FAILED,

    // CWIP_GET_LIST,
    // CWIP_GET_SUCCESS,
    // CWIP_GET_FAILED,
    // ADD_CAPITALISE,
    // ADD_CAPITALISE_SUCCESS,
    // ADD_CAPITALISE_FAILED,
    // ADD_EXPENSE,
    // ADD_EXPENSE_SUCCESS,
    // ADD_EXPENSE_FAILED,
    // EXPENSE_GET_LIST,
    // EXPENSE_GET_SUCCESS,
    // EXPENSE_GET_FAILED,
    // CAPITAL_GET_LIST,
    // CAPITAL_GET_SUCCESS,
    // CAPITAL_GET_FAILED,
    // ADD_LOCATION,
    // ADD_LOCATION_SUCCESS,
    // ADD_LOCATION_FAILED,
    // GET_LOCATION_LIST,
    // GET_LOCATION_SUCCESS,
    // GET_LOCATION_FAILED,
    // ADD_CLASS,
    // ADD_CLASS_SUCCESS,
    // ADD_CLASS_FAILED,
    // GET_CLASS_LIST,
    // GET_CLASS_SUCCESS,
    // GET_CLASS_FAILED,
    // GET_CALCULATION,
    // GET_CALCULATION_SUCCESS,
    // GET_CALCULATION_FAILED,
    // CREATE_FAASSET,
    // CREATE_FAASSET_SUCCESS,
    // CREATE_FAASSET_FAILED,
    // ADD_BULK_FAASSET,
    // ADD_BULK_FAASSET_SUCCESS,
    // ADD_BULK_FAASSET_FAILED,
    // ADD_DISPOSE,
    // ADD_DISPOSE_SUCCESS,
    // ADD_DISPOSE_FAILED,
    // FAASSET_UPDATE,
    // FAASSET_UPDATE_SUCCESS,
    // FAASSET_UPDATE_FAILED,
    // ADD_COST_CENTER,
    // ADD_COST_CENTER_SUCCESS,
    // ADD_COST_CENTER_FAILED,
    // GET_COST_CENTER_LIST,
    // GET_COST_CENTER_SUCCESS,
    // GET_COST_CENTER_FAILED,
    // GET_BU_CODE_LIST,
    // GET_BU_CODE_SUCCESS,
    // GET_BU_CODE_FAILED,
    // GET_ADDITION_DELETION,
    // GET_ADDITION_DELETION_SUCCESS,
    // GET_ADDITION_DELETION_FAILED,
    // SALE_ASSET,
    // SALE_ASSET_SUCCESS,
    // SALE_ASSET_FAILED,
    // GET_VERIFIED_LIST,
    // GET_VERIFIED_LIST_SUCCESS,
    // GET_VERIFIED_LIST_FAILED,
    // GET_REPORT_DATA,
    // GET_REPORT_DATA_SUCCESS,
    // GET_REPORT_DATA_FAILED,
    // ADD_SAMPLE_LIST,
    // ADD_SAMPLE_LIST_SUCCESS,
    // ADD_SAMPLE_LIST_FAILED,
    // GET_SAMPLE_LIST,
    // GET_SAMPLE_LIST_SUCCESS,
    // GET_SAMPLE_LIST_FAILED,
    
    FACILITY_GET_LIST,
    FACILITY_GET_SUCCESS,
    FACILITY_GET_FAILED,
    
    HISTRY_GET_LIST,
    HISTRY_GET_SUCCESS,
    HISTRY_GET_FAILED,
    // INSPECTION_ADD,
    // INSPECTION_ADD_SUCCESS,
    // INSPECTION_ADD_FAILED,

    INSPECTION_GET_LIST,
    INSPECTION_GET_SUCCESS,
    INSPECTION_GET_FAILED,

    INSPECTION_STATUS,
    INSPECTION_GET_STATUS_SUCCESS,
    INSPECTION_GET_STATUS_FAILED,

    MAINT_GET_LIST,
    MAINT_GET_SUCCESS,
    MAINT_GET_FAILED,

    MAINTENANCE_STATUS,
    MAINTENANCE_STATUS_SUCCESS,
    MAINTENANCE_STATUS_FAILED,

    // SOP_GET,
    // SOP_GET_SUCCESS,
    // SOP_GET_FAILED,

    // ASSIGN_SOP_MAIN,
    // ASSIGN_SOP_MAIN_SUCCESS,
    // ASSIGN_SOP_MAIN_FAILED,

    // PLANT_ADD,
    // PLANT_ADD_SUCCESS,
    // PLANT_ADD_FAILED,
    PLANT_GET_ALL,
    PLANT_GET_ALL_SUCCESS,
    PLANT_GET_ALL_FAILED,

    // REP_BASEDON_USER_STATUS,
    // REP_BASEDON_USER_STATUS_SUCCESS,
    // REP_BASEDON_USER_STATUS_FAILED,

    // GET_COLLECTIONS,
    // GET_COLLECTIONS_SUCCESS,
    // GET_COLLECTIONS_FAILED,
    // GET_QUERY,
    // GET_QUERY_SUCCESS,
    // GET_QUERY_FAILED,
    // GET_ATTRIBUTES,
    // GET_ATTRIBUTES_SUCCESS,
    // GET_ATTRIBUTES_FAILED,
    // GET_FIELD_NAME,
    // GET_FIELD_NAME_SUCCESS,
    // GET_FIELD_NAME_FAILED,
    // GET_REPORT,
    // GET_REPORT_SUCCESS,
    // GET_REPORT_FAILED,
    // SAVE_QUERY,
    // SAVE_QUERY_SUCCESS,
    // SAVE_QUERY_FAILED,
    // GET_QUERY_LIST,
    // GET_QUERY_LIST_SUCCESS,
    // GET_QUERY_LIST_FAILED,
    // UPDATE_QUERY,
    // UPDATE_QUERY_SUCCESS,
    // UPDATE_QUERY_FAILED,
    // DELETE_QUERY,
    // DELETE_QUERY_SUCCESS,
    // DELETE_QUERY_FAILED,
    // SAVE_REPORT,
    // SAVE_REPORT_FAILED,
    // SAVE_REPORT_SUCCESS,
    TICKET_STATUS,
    TICKET_STATUS_SUCCESS,
    TICKET_STATUS_FAILED,

    // TICKET_REPORT,
    // TICKET_REPORT_SUCCESS,
    // TICKET_REPORT_FAILED,
    // USER_ADD,
    // USER_ADD_SUCCESS,
    // USER_ADD_FAILED,
    USER_GET_LIST,
    USER_GET_SUCCESS,
    USER_GET_FAILED,
    USER_SET_PASSWORD,
    USER_SET_PASSWORD_SUCCESS,
    USER_SET_PASSWORD_FAILED,
    // ROLE_GET_LIST,
    // ROLE_GET_SUCCESS,
    // ROLE_GET_FAILED,
    // USER_BULK_ADD,
    // USER_BULK_ADD_SUCCESS,
    // USER_BULK_ADD_FAILED,
    // USER_DELETE_LIST,
    // USER_DELETE_SUCCESS,
    // USER_DELETE_FAILED,
    // USER_GET_SINGLE,
    // USER_GET_SINGLE_SUCCESS,
    // USER_GET_SINGLE_FAILED,
    // GET_APPROV_USERS,
    // GET_APPROV_USERS_SUCCESS,
    // GET_APPROV_USERS_FAILED,
    // USER_GET_TECH,
    // USER_GET_TECH_SUCESS,
    // USER_GET_TECH_FAILED,
    // USER_GET_APPROVER,
    // USER_GET_APPROVER_SUCESS,
    // USER_GET_APPROVER_FAILED
]



