import React, { useEffect, useState, useRef } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleLeft,
    faClose,
    faAngleRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faFilePdf,
    faClockRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import { useLocation } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import moment from "moment";
import { generateExcel } from './ExcelDownload';
import { Button } from 'react-bootstrap';
import ReactToPrint from "react-to-print";
import { useSelector, useDispatch } from "react-redux";
import HistoryModel from './historyModel';
import { each } from 'highcharts';
import S3BucketImage from '../report/pdfPreview/s3BucketImages';
import IMAGENAME from '../../medias/media.index';
import Loader from '../../components/loader/loader';
import BackButton from '../../components/BackButton/BackButton';
const { plantService } = require('../../services/service.index');

function PlantVerification() {
    const [key, setKey] = useState("verified");
    const [searchInput, setSearchInput] = useState("");
    const [custLogo, setCustLogo] = useState("");
    const [siteData, setSiteData] = useState("");
    const [data, setData] = useState([]);
    const [dataListAll, setDataListAll] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [singlePlantData, setSinglePlantData] = useState([]);
    const [historyData, setHistoryData] = useState([]);
    const transferDispose = useSelector((state) => state.access.transfer_dispose);


    // Add state variables for current page and items per page
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    //add toggle Button

    const isLoading = useSelector((state) => state.plant?.isLoading);
    const handleTabKey = (val) => {
        // console.log(val, 'key values');
        setKey(val)
    }

    let totalPages = 0;
    var dataList = [];
    if (data.result !== undefined) {
        dataList = data.result;
    }
    if (data?.total_count !== undefined) {
        totalPages = Math.ceil(data.total_count / itemsPerPage);
    }
    // console.log('assetList', assetList);
    // Function to handle next button
    const handleNext = () => {
        setCurrentPage((currentPage) => currentPage + 1);
    };

    // Function to handle previous button
    const handlePrev = () => {
        setCurrentPage((currentPage) => currentPage - 1);
    };

    //Function to handle FirstPage Button
    const handleFirstPage = () => {
        setCurrentPage(1);
    };

    //Function to handle LastPage Button
    const handleLastpage = () => {
        setCurrentPage(totalPages);
    };

    // Function to handle items per page change
    const handleItemsPerPageChange = (items) => {
        setItemsPerPage(items);
        setCurrentPage(1);
    };

    // Function to handle search input change
    // let id ;
    const handleSearchInputChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
    };


    //Changes for Pagination and Search
    const handleClearButton = () => {
        setSearchInput("");
    };

    const componentRef = useRef();
    const [fileName, setFileName] = useState(""); // To set Custom file name

    const handlePrint = () => {
        componentRef.current.handlePrint();
    };



    // Function to generate the custom file name dynamically
    const generateFileName = () => {
        // Implement your logic here to generate the file name
        // Example: return `inspection_${key}_${selectedItem}.pdf`;
        return `Asset_${key.replace(/_/g, ' ').replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())}_Report_${moment().format("LLLL")}_V1.0.pdf`;
    };



    const getData = async () => {
        // console.log(currentPage, itemsPerPage, searchInput, key);
        await plantService.getPlantSummary([currentPage, itemsPerPage, searchInput, key])
            .then(function (res) {
                // console.log(res, 'getData');
                setData(res.data);
                setSiteData(res.data.site)
            })
            .catch(function (error) {
            });
    };

    const getHistoryData = async (id) => {
        // console.log(id);
        await plantService.getPlantHistory(id)
            .then(function (res) {
                // console.log(res);
                setHistoryData(res.data);
            })
            .catch(function (error) {
            });
    };

    const getAllData = async (data) => {
        // console.log(data);
        await plantService.getPlantSummary(data)
            .then(function (res) {
                // console.log(res, 'getAllData');
                setDataListAll(res.data.result);
                setCustLogo(res.data.customer.cust_logo ? res.data.customer.cust_logo : null);
            })
            .catch(function (error) {
            });
    }

    useEffect(() => {
        if (data?.total_count > 0) {
            var queryData = [1, data?.total_count, "", key]
            getAllData(queryData)
            const dynamicFileName = generateFileName();
            setFileName(dynamicFileName);
        }
    }, [data]);

    useEffect(() => {
        getData();
        setData([]);
        setDataListAll([]);
        setSinglePlantData([]);
        setHistoryData([]);
    }, [key, currentPage, itemsPerPage, searchInput])


    {/* Table to view Plant List */ }
    function PlantTable(props) {
        const [data, setData] = useState([]);
        const [key, setKey] = useState("verified");
        // console.log(props);
        useEffect(() => {
            if (props !== null) {
                setData(props?.datalist)
                setKey(props?.getkey)
            }
        }, [props]);

        return (
            <div className="mb-6">
                <table className="table-bordered" width="100%">
                    <thead>
                        {/* Table heading to display list of customers */}
                        <tr
                            style={{
                                textAlign: "center",
                                backgroundColor: "steelblue",
                                padding: "8px",
                                color: "#fff",
                            }}
                        >
                            <th style={{ padding: "8px", width: "5%" }}>S. No</th>
                            <th
                                scope="col"
                                style={{ wordBreak: "break-word", width: "10%", textAlign: "left", padding: "8px" }}
                            >
                                Asset ID
                            </th>
                            <th
                                scope="col"
                                style={{ padding: "8px", textAlign: "left", width: "10%" }}
                            >
                                Asset Name
                            </th>
                            <th scope="col" style={{ width: "10%", textAlign: 'left', padding: '8px' }}>
                                Location
                            </th>
                            <th scope="col" style={{ width: "10%", textAlign: 'center', padding: '8px' }}>
                                Date of Installation
                            </th>
                            {/* <th scope="col" style={{ width: "10%", textAlign: 'left', padding: '8px' }}>
                                Origin
                            </th> */}
                            <th scope="col" style={{ width: "5%" }}>
                                Actual Quantity
                            </th>
                            {(key === 'verified' || key === 'mortality') &&
                                <>
                                    <th scope="col" style={{ width: "10%" }}>

                                        {key === 'verified' ? "Last Verified Quantity" : "Reported Quantity"}
                                    </th>
                                    <th scope="col" style={{ width: "10%" }}>

                                        {key === 'verified' ? "Last Verified Date & Time" : "Reported Date & Time"}
                                    </th>
                                    <th scope="col" style={{ width: "10%", textAlign: 'left', padding: '8px' }}>

                                        {key === 'verified' ? "Last Verified By" : "Reported By"}
                                    </th>
                                    <th scope="col" style={{ width: "15%", textAlign: 'left', padding: '8px' }}>
                                        Comment
                                    </th>
                                    <th
                                        scope="col"
                                        className="actionColumn"
                                        style={{ textAlign: "center" }}
                                        id='history'
                                    >
                                        History
                                    </th>
                                </>}
                        </tr>
                    </thead>
                    {/* Maintenance details based on status are mapped to read */}
                    {data !== null && data?.length > 0 ? (
                        data?.map((eachData, index) => {
                            return (
                                <tbody key={index} style={{ padding: "15px" }}>
                                    <tr style={{ padding: "20px", wordBreak: 'break-word' }}>
                                        <td style={{ textAlign: "center", padding: "8px" }}>
                                            {/* {(index + 1)} */}

                                            {data.length > itemsPerPage ? (index + 1) : (currentPage - 1) * itemsPerPage + (index + 1)}
                                            {/* {(currentPage - 1) * itemsPerPage + (index + 1)} */}
                                        </td>
                                        <td style={{ textAlign: "left", paddingLeft: "5px" }}>{eachData.plant_id}</td>
                                        <td style={{ textAlign: "left", paddingLeft: "5px" }}>{eachData.plant_name}</td>
                                        <td style={{ textAlign: "left", paddingLeft: "5px" }}>{eachData.location}</td>
                                        <td style={{ textAlign: "center", paddingLeft: "5px" }}>{eachData.date_of_plantation ? moment(eachData.date_of_plantation).local().format("Do MMM YYYY") : " - "}</td>
                                        {/* <td style={{ textAlign: "left", paddingLeft: "5px" }}>{eachData.origin}</td> */}
                                        <td style={{ textAlign: "center", paddingLeft: "5px" }}>{eachData.quantity || eachData.actual_quantity}</td>
                                        {(key === 'verified' || key === 'mortality') &&
                                            <>
                                                <td style={{ textAlign: "center", paddingLeft: "5px" }}>
                                                    {eachData?.mortality ? eachData?.verification_record?.quantity : eachData?.verified_quantity}
                                                </td>
                                                <td style={{ textAlign: "center", paddingLeft: "5px" }}>
                                                    {moment(eachData?.verified_date).local().format("Do MMM YYYY | hh:mm A")}
                                                </td>
                                                <td style={{ textAlign: "left", paddingLeft: "5px" }}>{eachData?.verified_by}</td>
                                                <td style={{ textAlign: "left", paddingLeft: "5px", wordBreak: 'break-word' }}>{eachData?.comments}</td>
                                                {/* <td style={{ textAlign: "center", paddingLeft: "5px" }}>{eachData?.history}</td> */}
                                            </>}
                                        {/* Preview Report button */}
                                        {(key === 'verified' || key === 'mortality') &&
                                            (<td
                                                id='history'
                                                style={{
                                                    textAlign: "center",
                                                }}>
                                                {/* {eachData?._id && */}
                                                <button
                                                    type="button"
                                                    data-tip
                                                    data-for="viewTip"
                                                    onClick={() => {
                                                        getHistoryData(eachData?._id);
                                                        setModalShow(true);
                                                        setSinglePlantData(data.filter((item) => item._id === eachData._id));
                                                    }

                                                    }
                                                    style={{ border: 'none', borderRadius: '10px', backgroundColor: '#E8F5F3' }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faClockRotateLeft}
                                                        size="lg"
                                                        className="icons"
                                                    />
                                                    <ReactTooltip
                                                        id="viewTip"
                                                        place="bottom"
                                                        effect="solid"
                                                    >
                                                        View History
                                                    </ReactTooltip>
                                                </button>
                                                {/* } */}
                                            </td>)
                                        }

                                    </tr>
                                </tbody>
                            );
                        })
                    ) : (
                        <tbody>
                            <tr style={{ textAlign: "center", height: "75px" }}>
                                <td colSpan="12">No Data Available</td>
                            </tr>
                        </tbody>
                    )}
                </table>
            </div >
        );
    }

    return (
        <div>
            {isLoading && <Loader />}
            <div>
                <BackButton />
                <div className="pt-5"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingLeft: '60px',
                        // border: '10px solid black',
                    }}>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => handleTabKey(k)}
                        className="m-3 headings"
                    >
                        <Tab eventKey="verified" title="Verified"></Tab>
                        &nbsp;
                        <Tab eventKey="not_verified" title="Not Verified"></Tab>
                        &nbsp;
                        {(transferDispose && (transferDispose.r === true)) &&
                            <Tab eventKey="mortality" title="Disposed"></Tab>
                        }
                    </Tabs>
                </div>
                <div className="col-md-12">
                    <div
                        style={{
                            fontSize: "20px",
                            // paddingLeft: "100px",
                            // paddingTop: "-55px",
                            textAlign: "center",
                            fontWeight: "bold",
                        }}
                    >
                        {(key === 'verified') &&
                            <>
                                List of Verified Assets
                            </>
                        }
                        {(key === 'not_verified') &&
                            <>
                                List of Not Verified Assets
                            </>
                        }
                        {(key === 'mortality') &&
                            <>
                                Disposed List
                            </>
                        }
                        <div style={{ float: "right" }}>
                            <form
                                className="mb-4"
                            >
                                <input
                                    type="text"
                                    placeholder="Search"
                                    value={searchInput}
                                    onChange={handleSearchInputChange}
                                    style={{
                                        border: "1px solid steelblue",
                                        fontSize: "15px",
                                        padding: "4px",
                                    }}
                                />
                                <span
                                    type="reset"
                                    onClick={handleClearButton}
                                    style={{ paddingLeft: "8px", marginRight: "25px" }}
                                >
                                    <FontAwesomeIcon icon={faClose} size="lg" className="pt-3" />
                                </span>
                            </form>
                        </div>
                    </div>
                    <div style={{ display: "inline-block" }}>
                        <div ref={componentRef} >
                            <div id="site-name" style={{ display: 'none' }}>
                                <div style={{
                                    border: '1px solid',
                                    // height: '50px',
                                    height: 'auto',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '1rem 10px 1rem 10px',
                                }}>
                                    <div>
                                        <img
                                            src={IMAGENAME.logo}
                                            alt="ZongoVita logo"
                                            style={{
                                                width: "150px",
                                                alignItems: 'center'
                                            }}
                                        />
                                    </div>
                                    <div style={{ textAlign: 'center', verticalAlign: 'middle', fontWeight: 'normal' }}>
                                        {`${siteData?.site_name} - ${siteData?.city}, ${siteData?.state}`}
                                        <br />
                                        <span style={{
                                            fontWeight: 'bold',
                                            fontSize: '16px'
                                        }}>
                                            {`Asset List : ${key.replace(/_/g, ' ').replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())}`}
                                        </span>
                                    </div>
                                    <div>
                                        {custLogo !== null &&
                                            <S3BucketImage
                                                imageUrl={custLogo}
                                                widthSize={'auto'}
                                            />
                                        }
                                    </div>
                                </div>
                                <PlantTable
                                    datalist={dataListAll}
                                    getkey={key}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <PlantTable
                            datalist={dataList}
                            getkey={key}
                        />
                    </div>
                    {/* pagination */}
                    {dataList !== null && dataList.length > 0 ? (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: "10px",
                                padding: "10px",
                                // border: "1px solid"
                            }}
                        >
                            <div className="mb-6">
                                <label>Items per page:</label> &nbsp;
                                <select
                                    onChange={(e) => handleItemsPerPageChange(e.target.value)}
                                    style={{ borderRadius: "8px", cursor: "pointer" }}
                                >
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={30}>30</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                            <div style={{ paddingRight: "25px" }} className="pagination">
                                <button
                                    style={{
                                        padding: "5px",
                                        cursor: "pointer",
                                        border: "none",
                                        backgroundColor: "#fff",
                                    }}
                                    disabled={currentPage === 1}
                                    onClick={handleFirstPage}
                                >
                                    {currentPage === 1 ? (
                                        <FontAwesomeIcon icon={faAngleDoubleLeft} color="#ccc" />
                                    ) : (<FontAwesomeIcon icon={faAngleDoubleLeft} color="steelblue" />)}
                                </button>
                                <button
                                    style={{
                                        padding: "5px",
                                        cursor: "pointer",
                                        border: "none",
                                        backgroundColor: "#fff",
                                    }}
                                    disabled={currentPage === 1}
                                    onClick={handlePrev}
                                >
                                    {/* Prev */}
                                    {currentPage === 1 ? (
                                        <FontAwesomeIcon icon={faAngleLeft} color="#ccc" />
                                    ) : (<FontAwesomeIcon icon={faAngleLeft} color="steelblue" />)}
                                </button>
                                <span style={{ padding: "10px" }}>
                                    Showing {currentPage} of {totalPages} pages
                                </span>
                                {(totalPages === 0 || currentPage === totalPages) ? (
                                    <>
                                        <button
                                            style={{
                                                padding: "5px",
                                                cursor: "pointer",
                                                backgroundColor: "#fff",
                                                border: "none",
                                            }}
                                            disabled={true}
                                        >
                                            <FontAwesomeIcon icon={faAngleRight} color="#ccc" />
                                        </button>
                                        <button
                                            style={{
                                                padding: "5px",
                                                cursor: "pointer",
                                                backgroundColor: "#fff",
                                                border: "none",
                                            }}
                                            disabled={true}
                                        >
                                            <FontAwesomeIcon icon={faAngleDoubleRight} color="#ccc" />
                                        </button>
                                    </>
                                ) : <>
                                    <button
                                        style={{
                                            padding: "5px",
                                            cursor: "pointer",
                                            backgroundColor: "#fff",
                                            border: "none",
                                        }}
                                        disabled={currentPage === totalPages}
                                        onClick={handleNext}
                                    >
                                        <FontAwesomeIcon icon={faAngleRight} color="steelblue" />
                                    </button>
                                    <button
                                        style={{
                                            padding: "5px",
                                            cursor: "pointer",
                                            backgroundColor: "#fff",
                                            border: "none",
                                        }}
                                        disabled={currentPage === totalPages}
                                        onClick={handleLastpage}
                                    >
                                        <FontAwesomeIcon icon={faAngleDoubleRight} color="steelblue" />
                                    </button>
                                </>}
                            </div>
                        </div>
                    ) : null}
                    {/* dataListAll.length > 0 */}
                    <ReactToPrint
                        trigger={() => (
                            <button
                                type="button"
                                style={{ float: "left", border: "0.5px solid grey", padding: "0.5rem" }}
                                className="saveButton my-0 mx-4"
                            >
                                Download
                            </button>
                        )}
                        content={() => componentRef.current}
                        documentTitle={fileName} // Set the custom file name
                        pageStyle={`@page {margin-top: 60px;}
                    @media print 
                    {#page-header 
                        {position: fixed;
                            top: 0;left: 0;
                            right: 0;height: 50px;
                            background-color: lightgray;padding: 10px;} 
                            #site-name {display: block !important;}
                            #history {display: none !important;}
                        }`}
                    />
                    {modalShow && (
                        <HistoryModel
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            basicdata={singlePlantData}
                            historydata={historyData}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default PlantVerification;
