import {
    SITE_GET_LIST,
    SITE_GET_SUCCESS,
    SITE_GET_FAILED,

    SITE_USER_LIST,
    SITE_USER_LIST_FAILED,
    SITE_USER_LIST_SUCCESS,

    SITE_SELECTED_LIST,

    SITE_DELETE_LIST,
    SITE_DELETE_SUCCESS,
    SITE_DELETE_FAILED,

    SITE_ADD,
    SITE_ADD_SUCCESS,
    SITE_ADD_FAILED,

    SITE_CONTACT_DETAILS,
    SITE_CONTACT_DETAILS_SUCCESS,
    SITE_CONTACT_DETAILS_FAILED,

    SITE_CONTACT_GET,
    SITE_CONTACT_GET_SUCCESS,
    SITE_CONTACT_GET_FAILED,


    SITE_GET_SINGLE,
    SITE_GET_SINGLE_SUCCESS,
    SITE_GET_SINGLE_FAILED,

    SITE_UPDATE,
    SITE_UPDATE_SUCCESS,
    SITE_UPDATE_FAILED,
    SITE_SELECTED_LIST_SUCCESS,

} from '../actions/siteAction';

const inistialSiteState = {
    siteData: [],
    siteAddData: {},
    siteUpdateData: {},
    id: "",
    contactId: "",
    siteContactGet: [],
    siteContactDetails: null,
    siteUserList:[],
    selectedSiteList:{selectAll:true,selectedSite:[]},
    siteGetSingle: {},
    isLoading: false,
    isSubmitted: false,
};

export default function SiteReducer(state = inistialSiteState, action) {
    // console.log("action2",action)
    switch (action.type) {
        case SITE_GET_LIST:
            return {
                ...state,
                isLoading: true,
            };
        case SITE_GET_SUCCESS:
            return {
                ...state,
                siteData: action.payload.data,
                isLoading: false,
            };
        case SITE_GET_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case SITE_USER_LIST:
            return {
                ...state,
                isLoading: true,
            };
        case SITE_USER_LIST_SUCCESS:
            return {
                ...state,
                siteUserList: action.payload.data?.data,
                isLoading: false,
            };
        case SITE_USER_LIST_FAILED:
            return {
                ...state,
                isLoading: false,
            };    
        case SITE_SELECTED_LIST_SUCCESS:
            return {
                ...state,
                selectedSiteList: action.payload,
                isLoading: false,
            };
        case SITE_DELETE_LIST:
            return {
                ...state,
            };
        case SITE_DELETE_SUCCESS:

            return {
                ...state,
            };
        case SITE_DELETE_FAILED:
            return {
                ...state,
            };
        case SITE_ADD:
            return {
                ...state,
                isLoading: true,
            }
        case SITE_ADD_SUCCESS:
            return {
                ...state,
                siteAddData: action.payload.subscriberData,
                contactId: action.payload.siteId,
                isLoading: false,
            }
        case SITE_ADD_FAILED:
            return {
                ...state,
                isLoading: false,
            }
        case SITE_CONTACT_GET:
            return {
                ...state,
                isLoading: true,
            };
        case SITE_CONTACT_GET_SUCCESS:
            return {
                ...state,
                siteContactGet: action.payload,
                contactId: null,
                isLoading: false,
            };
        case SITE_CONTACT_GET_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case SITE_CONTACT_DETAILS:
            return {
                ...state,
                isLoading: true,
                isSubmitted: true,
            };
        case SITE_CONTACT_DETAILS_SUCCESS:
            return {
                ...state,
                siteContactDetails: action.payload.contactdetails,
                isLoading: false,
            }

        case SITE_CONTACT_DETAILS_FAILED:
            return {
                ...state,
                isLoading: false,
            }
        case SITE_GET_SINGLE:
            return {
                ...state,
                isLoading: true,
            };
        case SITE_GET_SINGLE_SUCCESS:
            return {
                ...state,
                siteGetSingle: action.payload,
                isLoading: false,
            };
        case SITE_GET_SINGLE_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case SITE_UPDATE:
            return {
                ...state,
                isLoading: true,
            }
        case SITE_UPDATE_SUCCESS:
            return {
                ...state,
                siteUpdateData: action.payload,
                isLoading: false,
            }
        case SITE_UPDATE_FAILED:
            return {
                ...state,
                isLoading: false,
            }
        case 'CLEAR_SITE_REDUCER':
            return {
                ...state,
                siteData: [],
                siteAddData: {},
                siteUpdateData: {},
                id: "",
                contactId: "",
                siteContactGet: [],
                siteContactDetails: null,
                siteGetSingle: {},
            }
        default:
            return state;
    }
};