import serviceBase from "../../utilities/interceptor/apiInterceptor";
import { CONSTANTS } from "../../constants/URLs/urlConstants";

// adding user through UI
function addUserData(data) {
    return serviceBase.post(CONSTANTS.USER_ADD, data);
}

function getUserData(data) {
  return serviceBase.get(CONSTANTS.USER_GET);
}

// post API for sending verification code
function setPassword(data) {
  // console.log(data);
  return serviceBase.post(CONSTANTS.USER_CREATE_PASSWORD, data);
}

function getSingleUser(data) {
  // console.log("data", data);
  return serviceBase.post(CONSTANTS.VIEW_USER, data);
}
// to get role code / role details, whoch can be assigned during user creation.
function getRoleCodeData(data) {
  return serviceBase.get(CONSTANTS.ROLE_GET);
}

// adding user Bulk upload
function addBulkUserData(data) {
  // console.log(data[0]);
  // console.log(data[1]);
  // console.log(data);
  return serviceBase.post(CONSTANTS.USER_BULK_ADD + data[0], data[1]);
}

function deleteUserData (id) {
  // console.log("id", id);
  return serviceBase.delete(CONSTANTS.USER_DELETE + id);    
};

function getApprovUsers() {
  return serviceBase.get(CONSTANTS.GET_APPROV_USERS);
}

// to get only Techncian user details
function getTechData(data) {
  return serviceBase.get(CONSTANTS.USER_GET_TECH + `?feature=${data}`);
}

// to get only Approver user details
function getApproverData(data) {
  return serviceBase.get(CONSTANTS.USER_GET_Approver +`?feature=${data}` );
}

export const userDetails = {
  addUserData,
  getUserData,
  setPassword,
  getSingleUser,
  getRoleCodeData,
  addBulkUserData,
  deleteUserData,
  getApprovUsers,
  getTechData,
  getApproverData
};