/* Feature - saga for asset

Created by - Mona R
Updated by - Mona R */

import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
    ASSET_GET_LIST,
    ASSET_GET_SUCCESS,
    ASSET_GET_FAILED,
    
    ASSET_DELETE_LIST,
    ASSET_DELETE_FAILED,

    ASSET_ADD,
    ASSET_ADD_SUCCESS,
    ASSET_ADD_FAILED,  

    ASSET_BULK_ADD,

    ASSET_UPDATE,
    ASSET_UPDATE_SUCCESS,
    ASSET_UPDATE_FAILED,

    ASSET_GET_SINGLE,
    ASSET_GET_SINGLE_SUCCESS,
    ASSET_GET_SINGLE_FAILED,

    SOP_UPLOAD,
    SOP_UPLOAD_SUCCESS,
    SOP_UPLOAD_FAILED

} from "../actions/assetAction";
import { assetService } from "../../services/service.index"


// export function for saga activity
export function* watchGetAsset() {
    yield takeLatest(ASSET_GET_LIST, getAssetSaga);
}

export function* watchDeleteAsset() {
    yield takeLatest(ASSET_DELETE_LIST, deleteAssetSaga);
}

export function* watchAddAsset() {
    yield takeLatest(ASSET_ADD, addAssetSaga);
}

export function* watchBulkAddAsset() {
    yield takeLatest(ASSET_BULK_ADD, bulkAddAssetSaga);
}

export function* watchAssetUpdate() {
    yield takeLatest(ASSET_UPDATE, assetUpdatesaga);
}

export function* watchSingleAssetget() {
    yield takeLatest(ASSET_GET_SINGLE, assetGetSinglesaga);
}

export function* watchSOPUpload() {
    yield takeLatest(SOP_UPLOAD, sopuploadsaga);
}

export function* getAssetSaga(action) {
    try {
        // console.log("viewUserSaga action", action)
        const response = yield call(assetService.getAssetData, action.payload);
        if (response.status === 200) {
            // console.log("getAssetSaga response", response.data)
            yield put({
                type: ASSET_GET_SUCCESS,
                payload: response,
            });

        }
        else {
            // console.log("getAssetSaga Error response", response)
            yield put({
                type: ASSET_GET_FAILED,
            });
        }
    } catch (e) {
        // console.log("getAssetSaga Error response", e)
        yield put({
            type: ASSET_GET_FAILED,
        });        
    }
}

export function* deleteAssetSaga(action) {
    try {
        // console.log("deleteUserSaga action", action.payload)
        const response = yield call(assetService.deleteAssetData, action.payload);
        // console.log("delete Asset Data response", response)       

        if (response.status === 200) {
            // console.log("delete Asset Data response", response)
            const data = action.payload; 
            yield put({
                type: ASSET_GET_LIST,
                payload: [ data[0], data[1], data[2] ]                
            });           
            alert('Asset Deleted Successfully')
        }
        else {
            // console.log("ASSET_DELETE_FAILED Error response", response)
            yield put({
                type: ASSET_DELETE_FAILED,
            });
        }
    } catch (e) {
        // console.log("getAssetSaga Error response", e)
        yield put({
            type: ASSET_DELETE_FAILED,
        });        
    }


}

export function* addAssetSaga(action) {
    try {
        const response = yield call(assetService.addAssetData, action.payload);
        if (response.status === 201) {
            // console.log("respose", response);
            // console.log("action.payload", action.payload);
            yield put({
                type: ASSET_ADD_SUCCESS,
                payload: action.payload,
            });
            yield put({
                type: ASSET_GET_LIST,
            });
            alert("Asset Uploaded Successfully");
        }
        else {
            // console.log("ASSET_ADD_FAILED Error response", response)
            yield put({
                type: ASSET_ADD_FAILED,
            });
        }
    } catch (e) {
        // console.log("addAssetSaga Error response", e)
        if (e.response.data.message === 'Duplicate asset_id entered') {
            alert('Entered Asset ID already exists')
          }         
        yield put({
            type: ASSET_ADD_FAILED,
        });
    }


}

export function* bulkAddAssetSaga(action) {
    try {
        const response = yield call(assetService.addBulkAsset, action.payload);
        // console.log("addBulkAsset action.payload", response)
        if (response.status === 201) {
            // console.log("addBulkAsset response", response)
            alert('Data uploaded successfully');
            yield put({
                type: ASSET_GET_LIST,                
            });
            yield put({
                type: "DEVICE_GET_LIST",
            });
            yield put({
                type: "FACILITY_GET_LIST",
            });
        }
        else {
            // console.log("CUST_ADD_FAILED Error response", response)            
        }
    } catch (e) {
        
        if (e.response.data.message === 'Duplicate asset_id entered') {
            alert('Entered Asset ID already exists')
          }  
        else if (e.response.data.message === 'Duplicate facility_id entered') {
            alert('Entered Facility ID already exists')
          }  
        else if (e.response.data.message === 'Duplicate device_id entered') {
            alert('Entered Device ID already exists')
          }
        else{
            alert(e.response.data.message)
        }
    }
}

export function* assetUpdatesaga(action) {
    try {
        const response = yield call(assetService.updateAsset, action.payload);
        if (response.status === 200) {
            yield put({
                type: ASSET_UPDATE_SUCCESS,
                payload: action.payload,
            });
            yield put({
                type: ASSET_GET_LIST,
            })
            alert("Asset updated successfully")
        }
        else {
            yield put({
                type: ASSET_UPDATE_FAILED,
            });
        }
    } catch (e) {
        alert(e?.response?.data?.message)
        yield put({
            type: ASSET_UPDATE_FAILED,
        });
    }
}

export function* assetGetSinglesaga(action) {
    try {
        const response = yield call(assetService.getSingleAsset, action.payload);
        // console.log('response',response)
        if (response.status === 200) {
            yield put({
                type: ASSET_GET_SINGLE_SUCCESS,
                payload: response.data,
            });
        }
        else {
            yield put({
                type: ASSET_GET_SINGLE_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: ASSET_GET_SINGLE_FAILED,
        });
    }
}

export function* sopuploadsaga(action) {
    try {
        // console.log(action.payload);
        const response = yield call(assetService.SOPattachment, action.payload);
        // console.log('response',response)
        if (response.status === 200) {
            yield put({
                type: SOP_UPLOAD_SUCCESS,
                // payload: response.data,
            });
            alert('File uploaded Successfully')
        }
        else {
            yield put({
                type: SOP_UPLOAD_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: SOP_UPLOAD_FAILED,
        });
    }
}

