import {
    TICKET_STATUS,
    TICKET_STATUS_SUCCESS,
    TICKET_STATUS_FAILED,
    TICKET_REPORT,
    TICKET_REPORT_SUCCESS,
    TICKET_REPORT_FAILED
  
  } from "../actions/ticketAction";
  
  const initialTicketState = {
    ticketStatus: [],
    ticketReportData: null,
    isLoading:false,
  
  };
  
  export default function TicketReducer(state = initialTicketState, action) {
    switch (action.type) {
      case TICKET_STATUS:
        return {
          ...state,
          isLoading: true,
        };
      case TICKET_STATUS_SUCCESS:
        // console.log("insp Status",action.payload);
        return {
          ...state,
          ticketStatus: action.payload.data,
          isLoading: false,
          isSubmitted: false,
        };
      case TICKET_STATUS_FAILED:
        return {
          ...state,
          isLoading: false,
        };
      case TICKET_REPORT:
        return {
          ...state,
          isLoading: true,
        };
      case TICKET_REPORT_SUCCESS:
        return {
          ...state,
          ticketReportData: action.payload.data,
          isLoading: false,
        };
      case TICKET_REPORT_FAILED:
        return {
          ...state,
          ticketReportData: null,
          isLoading: false,
        };
      case "CLEAR_TICKET_REDUCER":
        return {
          ...state,
          ticketStatus: [],
          ticketReportData: null,
        };
      default:
        return state;
    }
  }
  