import { React, useEffect, useState } from 'react'
import TableComponent from './tableComponent';
import { auditService } from '../../services/service.index';
import BackButton from '../../components/BackButton/BackButton';
import { useSelector } from 'react-redux';

function AuditTrail() {
    const [isLoading, setIsLoading] = useState(false);
    const [auditList, setAuditList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchInput, setSearchInput] = useState("");
    const selectedSite = useSelector((state) => state.site.selectedSiteList);



    const auditData = async () => {
        let arr = selectedSite?.selectedSite.map((site) => site._id);
        let id =  Array.isArray(arr) ? arr.join(',') : arr;
        await auditService.getAuditData(`?page=${currentPage}&limit=${itemsPerPage}&key=${searchInput}&site=${id}`)
            .then(function (res) {
                // console.log('res.data', res.data);
                setAuditList(res.data);
                setIsLoading(false);
            })
            .catch(function (error) {
                // console.log(error.response);
                setIsLoading(false);
            });
    }

    const currentPageGet = (data) => {
        // console.log(data);
        setCurrentPage(data)
    }
    const itemsPerPageGet = (data) => {
        // console.log(data);
        setItemsPerPage(data)
    }
    const searchInputGet = (data) => {
        // console.log(data);
        setSearchInput(data)
    }

    useEffect(() => {
        if ((currentPage && currentPage > 0 || searchInput.length > 0)) {
            auditData();
        }
    }, [currentPage, itemsPerPage, searchInput,selectedSite])

    return (
        <div>
            <BackButton />
            <TableComponent
                bodydata={auditList}
                isloading={isLoading}
                currentpage={currentPageGet}
                searchinput={searchInputGet}
                itemsperpage={itemsPerPageGet}
            />
        </div>
    )
}

export default AuditTrail
